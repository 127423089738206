import React from 'react';
import { useStyles } from './DeskAvailability.styles';
import { ReactComponent as Station } from '../../assets/images/station-icon.svg'
import { ReactComponent as UnavStation } from '../../assets/images/unavailable-station-icon.svg';

interface IProps{
    isAdmin: boolean;
  }

const DeskAvailability = (props: IProps) => {

  const classes = useStyles();

  return(
    <div className={`${classes.mapInfoDiv} ${props.isAdmin ? "isAdmin" : null }`}>
    	{props.isAdmin ? (
				<>
					<div className={classes.MapInfo}>
						<Station className={`${classes.mapInfoIcon} ${classes.occupied}`}></Station>
						<h3 className={classes.mapInfoFont}>Ocupada</h3>
					</div>
					<div className={classes.MapInfo}>
						<Station className={`${classes.mapInfoIcon} ${classes.available}`}></Station>
						<h3 className={classes.mapInfoFont}>Disponible</h3>
					</div>
					<div className={classes.MapInfo}>
						<UnavStation className={`${classes.mapInfoIcon}`}></UnavStation>
						<h3 className={classes.mapInfoFont}>No disponible</h3>
					</div>
				</>
			) : (
				<>
					<div className={classes.MapInfo}>
						<Station className={`${classes.mapInfoIcon} ${classes.occupied}`}></Station>
						<h3 className={classes.mapInfoFont}>Ocupada</h3>
					</div>
					<div className={classes.MapInfo}>
						<Station className={`${classes.mapInfoIcon} ${classes.available}`}></Station>
						<h3 className={classes.mapInfoFont}>Disponible</h3>
					</div>
					<div className={classes.MapInfo}>
						<UnavStation className={`${classes.mapInfoIcon}`}></UnavStation>
						<h3 className={classes.mapInfoFont}>No disponible</h3>
					</div>
					<div className={classes.MapInfo}>
						<Station className={`${classes.mapInfoIcon} ${classes.selected}`}></Station>
						<h3 className={classes.mapInfoFont}>Seleccionada</h3>
					</div>
					<div className={classes.MapInfo}>
						<Station className={`${classes.mapInfoIcon} ${classes.reserved}`}></Station>
						<h3 className={classes.mapInfoFont}>Reservada</h3>
					</div>
				</>
			)}
    </div>
  );
};

export default DeskAvailability;
