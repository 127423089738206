import { ReactComponent as Calendar } from '../../assets/images/calendar.svg';
import { ReactComponent as Reservation } from '../../assets/images/reservation.svg';
import { ReactComponent as Home } from '../../assets/images/home_icon.svg';
import { ReactComponent as Workstations } from '../../assets/images/workstations_icon.svg';

export const BarData = [
  {
    text: 'Nueva reservación',
    path: '/',
    image: Reservation,
  },
  {
    text: 'Mis reservaciones',
    path: '/myreservations',
    image: Calendar,
  },
];

export const specialUserBarData = [
  {
    text: 'Reservación administrativos',
    path: '/additionalWorkspace',
    image: Reservation,
  },
];

export const adminBarData = [
  {
    text: 'Admin',
    path: '/admin',
    image: Home,
  },
  {
    text: 'Estaciones de trabajo',
    path: '/admin/workstations',
    image: Workstations,
  },
  {
    text: 'Reservaciones',
    path: '/admin/reservations',
    image: Calendar,
  },
];
