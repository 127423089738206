import React, { useState } from "react";
import { useStyles } from './SidebarOccupation.styles';
import CircularProgressComponent from "../CircularProgress/CircularProgress";
import SidebarCalendarComponent from "../SidebarCalendar/SidebarCalendar";

interface IProps {
  maxCapacity: number;
  percentageOcuppied: number;
  workMaxCapacity: number;
  workPercentageOcuppied: number;
  meetingMaxCapacity: number;
  meetingPercentageOccupied: number;
  availableFloors: number[];
  updateSelectedFloor: (id: number) => void;
  updateSelectedDate: (date: Date) => void;

}

const SidebarOccupationComponent = (props: IProps) => {
  const [floorState, setFloorState] = useState(2);
  const classes = useStyles();
  if(props.availableFloors.length!==0){
    if(!props.availableFloors.includes(2) && props.availableFloors.includes(3)){
      props.updateSelectedFloor(3);
    }else{
      props.updateSelectedFloor(2);
    }
  }
  return (
    <div className={classes.sidebarContainer}>
      <h1 className={classes.sidebarTitle}>Ocupación general</h1>
      <div className={classes.floorSelection}>
        { props.availableFloors.includes(2) && props.availableFloors.includes(3) && <div className={`${classes.floorOption} ${floorState === 2 ? "active" : ""}`} onClick={() => { props.updateSelectedFloor(2); setFloorState(2) }}>Segundo piso</div> }
        { props.availableFloors.includes(2) && props.availableFloors.includes(3) && <div className={`${classes.floorOption} right ${floorState === 3 ? "active" : ""}`} onClick={() => { props.updateSelectedFloor(3); setFloorState(3) }}>Tercer piso</div> }

        { props.availableFloors.includes(2) && !props.availableFloors.includes(3) && <div className={`${classes.floorOption} alone active ${floorState === 2 ? "active" : ""}`} onClick={() => { props.updateSelectedFloor(2); setFloorState(2) }}>Segundo piso</div> }
        { !props.availableFloors.includes(2) && props.availableFloors.includes(3) && <div className={`${classes.floorOption} alone active ${floorState === 3 ? "active" : ""}`} onClick={() => { props.updateSelectedFloor(3); setFloorState(3) }}>Tercer piso</div> }
      </div>
      <SidebarCalendarComponent updateSelectedDate={props.updateSelectedDate} />
      <p className={classes.CircularContainerTitle}>Ocupación de espacios</p>
      <div className={classes.CircularContainerFrame}>
        <CircularProgressComponent
          type={1}
          maxCapacity={props.workMaxCapacity}
          percentageOcuppied={props.workPercentageOcuppied}
          userType={'user'}
        />
      </div>
    </div>
  );
};
export default SidebarOccupationComponent;
