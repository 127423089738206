import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  header:{
    width: '100%',
    height: '80px',
    boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
    
    position: 'fixed',
    left: 0,
    top: 0,
    background: theme.palette.white,
    
    zIndex: 2,
    [theme.breakpoints.up('extraLarge')]: {
        height: '6.25vw',
        boxShadow: '0px 0.29vw 0.29vw rgba(33, 35, 34, 0.2)',
      },
      [theme.breakpoints.up('extraLarge')]: {
        height: '5.55vw',
      },      
  },
  logo_css: {
    position: 'absolute',
    width: '154px',
    height: '70px',
    left: '72px',
    top: '5px',
    [theme.breakpoints.up('extraLarge')]: {
        width: '12.03vw',
        height: '5.39vw',
        left: '0.5vw',
        top: '0.2vw',
    }, 
  },
  side_menu_bar: {
    position: 'absolute',
    padding: '12px',
    left: '24px',
    top: '16px',
    backgroundColor: theme.palette.white,
    border: 0,
    outline: 'none',
    
    [theme.breakpoints.up('extraLarge')]: {
        padding: '0.93vw',
        left: '1.87vw',
        top: '1.25vw',
    },
},

head_icon: {
    width: '24px',
    height: '24px',
    fill: 'white',
    [theme.breakpoints.up('extraLarge')]: {
        display: 'none'
    },
},

log_out_icon: {
    width: '24px',
    height: '24px',
    fill: 'white',
    [theme.breakpoints.up('extraLarge')]: {
        width: '1.87vw',
        height: '1.87vw',
    },
},

head_message:{
    display: 'none',
    fontSize: '18px',
        top: '28px',
        right: '100px',
        height: '22px',
    position: 'absolute',
    
    fontWeight: 300,
    fontFamily: 'WTGothic',
    color: theme.palette.blue1,
    
    width: 'fit-content',
    
    margin: 0,
    [theme.breakpoints.up('medium')]: {
        display: 'block',
    },
    [theme.breakpoints.up('large')]: {
        fontSize: '1.40vw',
        top: '2.18vw',
        right: '7vw',
        height: '1.71vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
        fontSize: '1.25vw',
        top: '2vw',
    },
},
head_button: {
    padding: '12px',
    right: '8px',
    top: '16px',
    position: 'absolute',
    display: 'inline-block',
    backgroundColor: theme.palette.white,
    border: 0,
    outline: 'none',

    '&:hover': {
        cursor: 'pointer'
    },

    [theme.breakpoints.up('large')]: {
        padding: '0.93vw',
        right: '1.87vw',
        top: '1.25vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
        top: '1vw',
    },
},

head_div: {
    borderRadius: '24px',
    width: '48px',
    height: '48px',
    right: '56px',
    top: '16px',
    position: 'absolute',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.white,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.pink,
    outline: 'none',

    [theme.breakpoints.up('medium')]: {
        borderRadius: '24px',
        width: '178px',
        height: '48px',
        right: '72px',
        top: '16px',
    },

    [theme.breakpoints.up('large')]: {
        borderRadius: '1.87vw',
        width: '13.90vw',
        height: '3.75vw',
        right: '5.62vw',
        top: '1.25vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
        borderRadius: '1.66vw',
        width: '12.36vw',
        height: '3.33vw',
        right: '5.65vw',
        top: '1.11vw',
    },
},

div_icon: {
    width: '24px',
    height: '24px',
    marginLeft: '6px',
    float: 'left',
    

    [theme.breakpoints.up('medium')]: {
        marginLeft: '25px',
    },

    [theme.breakpoints.up('large')]: {
        width: '1.87vw',
        height: '1.87vw',
        marginLeft: '2vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
        width: '1.66vw',
        height: '1.66vw',
        marginLeft: '1.9vw',
    },
},

div_text: {
    display: 'none',

    [theme.breakpoints.up('medium')]: {
        float: 'left',
        margin: 0,
        fontFamily: 'WTGothic',
        fontWeight: 300,
        color: theme.palette.pink,
        display: 'block',
        fontSize: '18px',
        width: '84px',
        height: '24px',
        lineHeight: '24px',
        marginLeft: '10px',
    },

    [theme.breakpoints.up('large')]: {
        fontSize: '1.40vw',
        width: '6.56vw',
        height: '1.87vw',
        lineHeight: '1.87vw',
        marginLeft: '0.78vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
        fontSize: '1.25vw',
        width: '5.83vw',
        height: '1.66vw',
        lineHeight: '1.66vw',
        marginLeft: '0.69vw',
    },
}

}));