import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { useStyles } from './MyReservationPageSkeleton.styles';

const MyReservationPageSkeleton = () => {
    const classes = useStyles();
    return(
        <>
            <div >
                <Skeleton className={classes.mainTitleDiv} variant="text"  />
                <div className={classes.containerDiv}>
                   <Skeleton variant="rect" className={classes.container}/>
                   <Skeleton variant="rect" height={500} className={classes.container}/>
                </div>
            </div>
        </>
    );
};

export default MyReservationPageSkeleton;