import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    circularContainer: {
        float: 'left',
        width: '29.248vw',
        height: '29.248vw',
        marginLeft: '0vw',
        [theme.breakpoints.up('medium')]: {
            width: '16.536vw',
            height: '16.536vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '11.699vw',
            height: '11.699vw',
            marginLeft: '3vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '14.60vw',
            height: '10.9375vw',
            marginLeft: '0vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            height: '9.72vw',
        },
    },

    circularContainerAdmin: {
        float: 'left',
        width: '29.248vw',
        height: '29.248vw',
        marginLeft: '0vw',
        [theme.breakpoints.up('medium')]: {
            width: '20.536vw',
            height: '20.536vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '21.250vw',
            height: '21.250vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '14.60vw',
            height: '10.9375vw',
            marginLeft: '0vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            height: '9.72vw',
        },
        [theme.breakpoints.up(2500)]: {
            height: '9.72vw',
            width: '7vw',
        },
    },

    circularProgressbar: {
        width: '10.24vw',
        marginLeft: 'calc(50% - 5.12vw)',
        '@media screen and (min-width: 1440px)': {
            width: '10vw',
        }
    },
    circularContainerPink: {
        float: 'right',
        width: '14.60vw',
        height: '10.9375vw',
        marginLeft: '1.87vw',
        '@media screen and (min-width: 1440px)': {
            height: '9.72vw',
        }
    },
    circularSubtitle: {
        display: 'block',
        margin: 0,
        width: '30vw',
        height: '3.733vw',
        fontFamily: 'WTGothic',
        fontWeight: 'normal',
        fontSize: '3.2vw',
        lineHeight: '3.840vw',
        textAlign: 'center',
        marginTop: '0.8vw',
        [theme.breakpoints.up('medium')]: {
            width: '18vw',
            height: '1.484375vw',
            fontSize: '1.563vw',
            marginLeft: '0vw',
            lineHeight: '1.875vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '14.60vw',
            fontSize: '1.250vw',
            marginLeft: '-1vw',
            lineHeight: '1.484375vw',
        },
        [theme.breakpoints.up('large')]: {
            fontSize: '1.25vw',
            marginLeft: 'unset',
        },
        [theme.breakpoints.up('extraLarge')]: {
            fontSize: '1.11vw',
        },
    },

    circularSubtitleAdmin: {
        display: 'block',
        margin: 0,
        width: '38vw',
        height: '3.733vw',
        fontFamily: 'WTGothic',
        fontWeight: 'normal',
        fontSize: '3.2vw',
        textAlign: 'center',
        marginTop: '1.5vw',
        marginLeft: '-3.5vw',

        [theme.breakpoints.up('medium')]: {
            width: '24vw',
            height: '1.484375vw',
            fontSize: '2.083vw',
            marginLeft: '-1vw',
            marginTop: '5.5vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '19.60vw',
            fontSize: '1.667vw',
            marginLeft: '1vw',
            marginTop: '2.5vw',
        },
        [theme.breakpoints.up('large')]: {
            fontSize: '1.25vw',
            marginLeft: '-2.5vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            fontSize: '1.11vw',
            marginLeft: '-2.1vw',
        },
        [theme.breakpoints.up(2500)]: {
            fontSize: '1vw',
            marginLeft: '-6vw',
            marginTop: '1vw',
        },
    },

    circularNumberStyle: {
        fontFamily: 'WTGothic',
        fontStyle: 'normal',
        size: '1.25vw',
        textAlign: 'center',
    },
    circularSkeletonContainer: {
        overflow: "hidden",
        paddingTop: "100%",
        position: "relative",
    },
    circularSkeleton: {
        position: "absolute",
        top: 0,
        left: 0,
    },
}));
