import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  daySelectionContainer: {
    display: 'inline-block',
    position: 'relative',
    margin: 'auto',
    marginTop: '1.66vw',
    marginLeft: '4.44vw',
    width: '82.22vw',
    height: '30.88vw',
    float: 'none',

    [theme.breakpoints.up('medium')]: {
      marginTop: '3.12vw',
      marginLeft: '21.83vw',
      width: '39.8vw',
      height: '15.83vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginTop: '3vw',
      marginLeft: '-41vw',
      float: 'left',
      width: '39.58vw',
      height: '15.83vw',
    },
    [theme.breakpoints.up('large')]: {
      float: 'none',
      marginTop: '1.87vw',
      marginLeft: '1.87vw',
      width: '28.35vw',
      height: '10.78vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '1.66vw',
      height: '9.58vw',
    },
  },

  monthContainer: {
    position: 'relative',
    display: 'block',
    margin: 'auto',
    width: '70.22vw',
    height: '13.33vw',

    [theme.breakpoints.up('medium')]: {
      width: '32.94vw',
      height: '6.25vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '26.35vw',
      height: '5vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '23.43vw',
      height: '3.75vw',
    },
    [theme.breakpoints.up('extraLarge')]: {},
  },

  arrowContainer: {
    position: 'absolute',
    backgroundColor: theme.palette.white,
    padding: '2.91vw 4.8vw 2.91vw 4.8vw',
    border: 0,
    top: 0,
    outline: 'none',

    '&:hover': {
      cursor: 'pointer',
    },

    '&.left': {
      float: 'left',
      left: 0,
    },

    '&.right': {
      float: 'right',
      right: 0,
    },

    [theme.breakpoints.up('medium')]: {
      padding: '1.36vw 2.25vw 1.36vw 2.25vw',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '1.04vw 1.8vw 1.04vw 1.8vw',
    },
    [theme.breakpoints.up('large')]: {
      padding: '0.82vw 1.34vw 0.82vw 1.34vw',
    },
    [theme.breakpoints.up('extraLarge')]: {},
  },

  arrowSrc: {
    height: '7.5vw',
    width: '3.75vw',

    [theme.breakpoints.up('medium')]: {
      height: '3.51vw',
      width: '1.75vw',
    },
    [theme.breakpoints.up('tablet')]: {
      height: '2.81vw',
      width: '1.40vw',
    },
    [theme.breakpoints.up('large')]: {
      height: '2.10vw',
      width: '1.05vw',
    },
    [theme.breakpoints.up('extraLarge')]: {},
  },

  monthTitleFormat: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    margin: 0,
    fontFamily: 'WTGothic',
    textAlign: 'center',
    letterSpacing: '0.01em',
    fontSize: '6.11vw',
    lineHeight: '12.72vw',
    fontWeight: 400,

    [theme.breakpoints.up('medium')]: {
      fontSize: '2.34vw',
      lineHeight: '6.25vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontWeight: 300,
      fontSize: '2.70vw',
      lineHeight: '5vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '2.03vw',
      lineHeight: '3.75vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.80vw',
    },
  },

  daysContainer: {
    width: 'fit-content',
    position: 'relative',
    display: 'block',
    margin: 'auto',
    height: '13.07vw',
    marginTop: '1.56vw',

    [theme.breakpoints.up('medium')]: {
      height: '8.07vw',
      marginTop: '1.56vw',
    },
    [theme.breakpoints.up('tablet')]: {
      height: '6.35vw',
      marginTop: '1.25vw',
    },
    [theme.breakpoints.up('large')]: {
      marginTop: '0.93vw',
      height: '5.15vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '0.83vw',
    },
  },

  daysArrow: {
    float: 'left',
    display: 'flex',
    width: '2.10vw',
    height: '3.10vw',

    '&:hover': {
      cursor: 'pointer',
    },

    [theme.breakpoints.up('medium')]: {
      width: '0.87vw',
      height: '1.75vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '0.70vw',
      height: '1.40vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '0.52vw',
      height: '1.05vw',
    },
    [theme.breakpoints.up('extraLarge')]: {},
  },

  mediumSection: {
    float: 'left',
    width: 'fit-content',
    height: '100%',
    overflow: 'hidden',
  },

  fitContainer: {
    padding: '3vw 0 3.19vw 2.23vw',
    float: 'left',

    [theme.breakpoints.up('medium')]: {
      padding: '1.50vw 0 3.19vw 2.23vw',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '1.50vw 0 3.19vw 2.23vw',
    },
    [theme.breakpoints.up('large')]: {
      padding: '1.50vw 0 2.59vw 2.23vw',
    },
    [theme.breakpoints.up('extraLarge')]: {},
  },

  fitContainerLeft: {
    padding: '3vw 2.23vw 3.19vw 0',
    float: 'left',

    [theme.breakpoints.up('medium')]: {
      padding: '1.50vw 2.23vw 3.19vw 0',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '1.50vw 2.23vw 3.19vw 0',
    },
    [theme.breakpoints.up('large')]: {
      padding: '1.50vw 2.23vw 2.59vw 0',
    },
    [theme.breakpoints.up('extraLarge')]: {},
  },

  days: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
    width: 'fit-content',
    height: '4.72vw',

    [theme.breakpoints.up('medium')]: {
      height: '2.21vw',
    },
    [theme.breakpoints.up('tablet')]: {
      height: '1.77vw',
    },
    [theme.breakpoints.up('large')]: {
      height: '1.32vw',
    },
    [theme.breakpoints.up('extraLarge')]: {},
  },

  datesText: {
    fontFamily: 'WTGothic',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textAlign: 'center',
    letterSpacing: '0.01em',
    fontSize: '3.33vw',
    lineHeight: '4.72vw',
    width: '7vw',
    marginLeft: '6.66vw',
    height: '2.21vw',

    '&:first-child': {
      marginLeft: 0,
    },

    [theme.breakpoints.up('medium')]: {
      fontSize: '1.82vw',
      lineHeight: '2.21vw',
      width: '4.12vw',
      marginLeft: '3.25vw',
      height: '2.21vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.45vw',
      lineHeight: '1.77vw',
      width: '3.18vw',
      marginLeft: '4.16vw',
      height: '1.77vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.09vw',
      lineHeight: '1.32vw',
      width: '3vw',
      marginLeft: '1.38vw',
      height: '1.32vw',
    },
    [theme.breakpoints.up('extraLarge')]: {},
  },

  datesContainer: {
    width: 'fit-content',
    height: 'fit-content',
    display: 'flex',
  },

  date: {
    fontFamily: 'WTGothic',
    textAlign: 'center',
    letterSpacing: '0.01em',
    fontSize: '3.33vw',
    lineHeight: '5vw',
    width: '5vw',
    height: '5vw',
    marginLeft: '9vw',
    marginTop: '3vw',

    '&:first-child': {
      marginLeft: 0,
    },

    '&:hover': {
      cursor: 'pointer',
    },

    '&.selected': {
      borderRadius: '50%',
      background: '#FF760C',
      display: 'block',
      textAlign: 'center',
      color: theme.palette.white,
    },

    [theme.breakpoints.up('medium')]: {
      width: '4.12vw',
      height: '4.12vw',
      marginLeft: '3.25vw',
      fontSize: '1.82vw',
      marginTop: '1.7vw',
      lineHeight: '4.12vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '2.5vw',
      height: '2.5vw',
      marginLeft: '5vw',
      fontSize: '1.45vw',
      marginTop: '1.5vw',
      lineHeight: '2.5vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '2.5vw',
      height: '2.5vw',
      fontSize: '1.09vw',
      marginTop: '1.30vw',
      lineHeight: '2.5vw',
      marginLeft: '2vw',
    },
    [theme.breakpoints.up('extraLarge')]: {},
  },
  dateDisable: {
    color: '#e0e0e0',
  },
}));
