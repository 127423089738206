import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import leftArrow from '../assets/images/arrow-left.svg';
import rightChevron from '../assets/images/chevron-right.svg';
import MapHeaderComponent from '../components/MapHeader/MapHeader';
import StationSelection from '../components/StationSelection/StationSelection';
import MapPageSkeleton from '../components/MapPageSkeleton/MapPageSkeleton';
import { WorkStationStatus } from '../interfaces/IWorkstation';
import { useStyles } from './Page.styles';
import { IMapPageData } from '../interfaces/IMapPage';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { IFloor } from '../interfaces/IFloor';
import dayjs from 'dayjs';
import { IReservationData } from '../interfaces/IReservationData';
import { IReservation } from '../interfaces/IReservation';
import { ITable } from '../interfaces/ITable';
import DialogBox from '../components/DialogBox/DialogBox';
import { getLabel } from '../helpers/labels';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useOktaAuth } from '@okta/okta-react';

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

interface IProps {
  selectedDate: Date;
  selectedFloor: number;
  availableFloors: number[];
}

const MapPage = (props: IProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();

  const [filterState, setFilterState] = useState(true);

  const [stations, setStations] = useState<ITable[]>([]);
  const [selectedDate, setSelectedDate] = useState(props.selectedDate);

  const [selectedFloor, setSelectedFloor] = useState<IFloor>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [reservationsUpdated, setReservationsUpdated] = useState(false);
  const [floorCapacity, setFloorCapacity] = useState(0);
  const [dialogMessage, setDialogMessage] = useState('');
  const [isFullCapacity, setIsFullCapacity] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userActualReservation, setUserActualReservation] =
    useState<IReservation>();
  const [DBIsSuccess, setDBIsSucces] = useState(false);

  const defaultFloor = props.selectedFloor;

  useEffect(() => {
    if (authState?.isAuthenticated) {
      oktaAuth.getUser().then(
        (result) => {
          if (result.email && result.name) {
            setUserEmail(result.email);
            setUserName(result.name);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [authState, oktaAuth]);

  const floorsQuery = gql`
    query {
      floors: getAllFloors {
        id
        floorName
        floorCapacity
        acceptedCapacity
        tables {
          id
          floorId
          workstations {
            id
            floorId
            tableId
            status
            areaId
          }
        }
      }
    }
  `;

  const reservationsQuery = gql`
    query ($dateParam: String!, $pFloorId: Float!, $pAreaId: Float!) {
      reservations: getReservationByDateAndFloorAndArea(
        dateParam: $dateParam
        pFloorId: $pFloorId
        pAreaId: $pAreaId
      ) {
        id
        userEmail
        date
        status
        workstationreservation {
          reservationId
          workstationId
          tableId
          floorId
          areaId
          status
        }
      }
    }
  `;

  const userReservationQuery = gql`
    query ($dateParam: String!, $email: String!) {
      reservations: getReservationByDateAndUser(
        dateParam: $dateParam
        email: $email
      ) {
        id
        userEmail
        date
        status
        workstationreservation {
          reservationId
          workstationId
          tableId
          floorId
          areaId
          status
        }
      }
    }
  `;

  const { loading, error, data } = useQuery<IMapPageData>(floorsQuery, {
    pollInterval: 3000,
  });

  const selectFloor = (floorId: number) => {
    if (data) {
      setSelectedFloor(data.floors.find((floor) => floor.id === floorId));
    }
  };

  const [getReservationsData, reservationsData] =
    useLazyQuery<IReservationData>(reservationsQuery, {
      variables: {
        dateParam:
          selectedDate.getFullYear().toString() +
          '/' +
          (selectedDate.getMonth() + 1).toString() +
          '/' +
          selectedDate.getDate().toString(),
        pFloorId: selectedFloor?.id,
        pAreaId: 1,
      },
      pollInterval: 1000,
    });

  const [getUserReservationData, userReservationsData] =
    useLazyQuery<IReservationData>(userReservationQuery, {
      variables: {
        dateParam:
          selectedDate.getFullYear().toString() +
          '/' +
          (selectedDate.getMonth() + 1).toString() +
          '/' +
          selectedDate.getDate().toString(),
        email: userEmail,
      },
      pollInterval: 1000,
    });

  useEffect(() => {
    getUserReservationData();
  }, [selectedDate, getUserReservationData]);

  useEffect(() => {
    if (userReservationsData.data) {
      setUserActualReservation(userReservationsData.data.reservations[0]);
    } else {
      setUserActualReservation(undefined);
    }
  }, [userReservationsData.data]);

  useEffect(() => {
    if (reservationsData.previousData) {
      setReservationsUpdated(true);
      if (
        reservationsData.data &&
        reservationsData.data.reservations.length === floorCapacity
      ) {
        setDialogMessage(getLabel('fullCapacity'));
        setShowErrorMessage(true);
        setIsFullCapacity(true);
      }
    }
  }, [reservationsData.previousData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsFullCapacity(false);
    if (reservationsData.data && floorCapacity >= 0) {
      if (reservationsData.data.reservations.length === floorCapacity) {
        setDialogMessage(getLabel('fullCapacity'));
        setShowErrorMessage(true);
        setIsFullCapacity(true);
      }
    }
  }, [reservationsData.data, floorCapacity]);

  useEffect(() => {
    data &&
      data.floors &&
      setSelectedFloor(data.floors.find((floor) => floor.id === defaultFloor));
  }, [data, defaultFloor]);

  const searchUserReservation = useCallback(
    (id: string, tableId: string) => {
      if (reservationsData.data !== undefined) {
        let result = '';
        reservationsData.data?.reservations.find((element) => {
          if (
            element.workstationreservation.workstationId === id &&
            element.workstationreservation.tableId === tableId &&
            element.userEmail === userEmail
          ) {
            result = 'actual';
            return element;
          } else if (
            element.workstationreservation.workstationId === id &&
            element.workstationreservation.tableId === tableId
          ) {
            result = 'occupied';
            return element;
          }
          return null;
        });
        // if (result === 'actual') {
        //   setUserActualReservation(reservation);
        // }
        return result;
      }
    },
    [reservationsData.data, userEmail]
  );

  const changeStationsStatus = () => {
    // setUserActualReservation(undefined);
    if (selectedFloor !== undefined && userEmail !== '') {
      const updatedTables = selectedFloor.tables.map((table) => {
        return {
          id: table.id,
          floorId: table.floorId,
          workstations: table.workstations.map((station) => {
            const userReservation = searchUserReservation(
              station.id,
              station.tableId
            );
            return {
              ...station,
              reservationStatus:
                !!station.status === false
                  ? WorkStationStatus.disabled
                  : userReservation === 'actual'
                  ? WorkStationStatus.actual
                  : userReservation === 'occupied'
                  ? WorkStationStatus.occupied
                  : WorkStationStatus.available,
            };
          }),
        };
      });
      setStations(updatedTables);
    }
  };

  useEffect(changeStationsStatus, [
    selectedFloor,
    reservationsData.data,
    userEmail,
    searchUserReservation,
  ]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedFloor !== undefined) {
      getReservationsData();
      let res = 0;
      selectedFloor?.tables.forEach((element) => {
        res += element.workstations.length;
      });
      setFloorCapacity(
        Math.trunc(res * (selectedFloor?.acceptedCapacity / 100))
      );
    }
  }, [selectedDate, selectedFloor, getReservationsData]);

  if (loading) return <MapPageSkeleton />;
  if (error) return <p>Error :(</p>;

  const selectStation = (id: string, tableId: string) => {
    const updatedTables = stations.map((table) => {
      table.workstations.map((station) => {
        const userReservation = searchUserReservation(
          station.id,
          station.tableId
        );
        if (userReservation === 'actual') {
          station.reservationStatus = WorkStationStatus.actual;
        } else if (station.reservationStatus === WorkStationStatus.selected) {
          station.reservationStatus = WorkStationStatus.available;
        } else if (station.id === id && station.tableId === tableId) {
          station.reservationStatus = WorkStationStatus.selected;
        }
        return station;
      });
      return table;
    });
    setStations(updatedTables);
  };

  const changeFilterState = () => {
    setFilterState(!filterState);
  };

  return (
    <>
      <div className="full">
        <div className={classes.mainTitleDiv}>
          <button className={classes.backButton} onClick={history.goBack}>
            <img
              src={leftArrow}
              className={classes.backIcon}
              alt="return button"
            ></img>
          </button>
          <h1 className={`${classes.titleFont} ${classes.sidedTitle}`}>
            Nueva Reservación
          </h1>
          <div className={classes.navigationContainer}>
            <h3 className={`${classes.navigationFont} actual`}>
              Elegir estación
            </h3>
            <img
              src={rightChevron}
              className={classes.navigationIcon}
              alt="first navigation arrow"
            ></img>
            <h3 className={classes.navigationFont}>Reservar estación</h3>
            <img
              src={rightChevron}
              className={classes.navigationIcon}
              alt="second navigation arrow"
            ></img>
            <h3 className={classes.navigationFont}>Confirmar reservación</h3>
          </div>
        </div>
        <DialogBox
          message={dialogMessage}
          isSuccess={DBIsSuccess}
          show={showErrorMessage}
          hide={setShowErrorMessage}
        ></DialogBox>
        <MapHeaderComponent
          selectFloor={selectFloor}
          date={selectedDate}
          floors={data?.floors}
          selectedFloor={selectedFloor}
          handleClick={setSelectedDate}
          filter={filterState}
          filterOnClick={changeFilterState}
          availableFloors={props.availableFloors}
        ></MapHeaderComponent>
        <StationSelection
          date={selectedDate}
          updateDialogBoxSucces={setDBIsSucces}
          actualReservation={userActualReservation}
          updateActualReservation={setUserActualReservation}
          stations={stations}
          filter={filterState}
          updateSelectedStation={selectStation}
          floorId={selectedFloor ? selectedFloor.id : defaultFloor}
          refetchReservations={getReservationsData}
          setShowErrorDailog={setShowErrorMessage}
          setDialogMessage={setDialogMessage}
          reservationsUpdated={reservationsUpdated}
          setReservationsUpdated={setReservationsUpdated}
          isFullCapacity={isFullCapacity}
          userEmail={userEmail}
          userName={userName}
          isAdmin={false}
        ></StationSelection>
      </div>
    </>
  );
};

export default MapPage;
