import React, { useEffect, useState } from 'react';
import { IReservation, ReservationStatus } from '../../interfaces/IReservation';
import { useStyles } from './AdminReservationHistory.styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import CustomRangeDatePicker from '../Calendar/RangeCalendar';
import { gql, useMutation } from '@apollo/client';
import { getLabel } from '../../helpers/labels';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

const timeZone = dayjs.tz.guess();

interface IProps {
  data: IReservation[];
  date: Date;
  updateDate: (date: Date) => void;
  endDate: Date;
  updateEndDate: (date: Date) => void;
  generateReport: () => void;
  floorId: number;
  dialogMessage: (message: string) => void;
  showMessage: (valor: boolean) => void;
  success: (valor: boolean) => void;
}

const ReservationHistoryComponent = (props: IProps) => {
  const classes = useStyles();

  const [openCancelReservationDialog, setOpenCancelReservationDialog] =
    useState(false);
  const [openCheckInReservationDialog, setOpenCheckInReservationDialog] =
    useState(false);
  const [openCheckOutReservationDialog, setOpenCheckOutReservationDialog] =
    useState(false);
  const [actualReservation, setActualReservation] = useState(0);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const reservationsQuery = gql`
    query ($dateParam: String!, $endDateParam: String!, $pFloorId: Float!) {
      reservations: getAllReservationByDateRangeAndFloor(
        dateParam: $dateParam
        endDateParam: $endDateParam
        pFloorId: $pFloorId
      ) {
        id
        userEmail
        date
        status
        fullName
        phoneNumber
        traveledTo
        tripReturnDate
        workstationreservation {
          tableId
          workstationId
          floorId
          areaId
        }
      }
    }
  `;

  const deleteReservationQuery = gql`
    mutation ($reservationID: Float!) {
      result: deleteWorkstationReservation(reservationID: $reservationID)
    }
  `;

  const checkInReservationQuery = gql`
    mutation ($id: Float!) {
      result: checkInReservation(id: $id)
    }
  `;

  const checkOutReservationQuery = gql`
    mutation ($id: Float!) {
      result: checkOutReservation(id: $id)
    }
  `;

  const disableWorkstationReservationQuery = gql`
    mutation ($id: Float!) {
      result: disableWorkstationReservation(id: $id)
    }
  `;

  const [deleteReservation, deleteReservationResult] = useMutation(
    deleteReservationQuery,
    {
      variables: { reservationID: actualReservation },
      refetchQueries: [
        {
          query: reservationsQuery,
          fetchPolicy: 'network-only',
          variables: {
            dateParam: dayjs(props.date).format('YYYY/MM/DD'),
            endDateParam: dayjs(props.endDate).format('YYYY/MM/DD'),
            pFloorId: props.floorId,
          },
        },
      ],
    }
  );

  const [checkInReservation, checkInReservationResult] = useMutation(
    checkInReservationQuery,
    {
      variables: { id: actualReservation },
      refetchQueries: [
        {
          query: reservationsQuery,
          variables: {
            dateParam: dayjs(props.date).format('YYYY/MM/DD'),
            endDateParam: dayjs(props.endDate).format('YYYY/MM/DD'),
            pFloorId: props.floorId,
          },
        },
      ],
    }
  );

  const [checkOutReservation, checkOutReservationResult] = useMutation(
    checkOutReservationQuery,
    {
      variables: {
        id: actualReservation,
      },
      refetchQueries: [
        {
          query: reservationsQuery,
          variables: {
            dateParam: dayjs(props.date).format('YYYY/MM/DD'),
            endDateParam: dayjs(props.endDate).format('YYYY/MM/DD'),
            pFloorId: props.floorId,
          },
        },
      ],
    }
  );

  const [disableWorkstationReservation, disableWorkstationReservationResult] =
    useMutation(disableWorkstationReservationQuery, {
      variables: {
        id: actualReservation,
      },
      refetchQueries: [
        {
          query: reservationsQuery,
          variables: {
            dateParam: dayjs(props.date).format('YYYY/MM/DD'),
            endDateParam: dayjs(props.endDate).format('YYYY/MM/DD'),
            pFloorId: props.floorId,
          },
        },
      ],
    });

  const handleCloseCancelReservation = () => {
    setOpenCancelReservationDialog(false);
  };

  const handleDeleteReservation = () => {
    deleteReservation();
    setOpenCancelReservationDialog(false);
  };

  const handleCloseCheckIn = () => {
    setOpenCheckInReservationDialog(false);
  };

  const handleCheckIn = () => {
    checkInReservation();
    setOpenCheckInReservationDialog(false);
  };

  const handleCloseCheckOut = () => {
    setOpenCheckOutReservationDialog(false);
  };

  const handleCheckOut = () => {
    checkOutReservation();
    setOpenCheckOutReservationDialog(false);
  };

  const getItemDate = (itemDate: Date) => {
    let strDate = dayjs.tz(itemDate, timeZone).format('MM/DD/YYYY');
    return new Date(strDate);
  };

  const filteredReservations = props.data.filter(
    (reservation) => reservation.status !== 0
  );

  const sortedArray = [...props.data].sort((a, b) => b.status - a.status);

  useEffect(() => {
    props.date.setHours(0, 0, 0, 0);
  }, [props.date]);

  useEffect(() => {
    if (deleteReservationResult.data) {
      setActualReservation(0);
      props.showMessage(true);
      if (deleteReservationResult.data.result) {
        props.success(true);
        props.dialogMessage(getLabel('cancelReservationMessageSuccess'));
      } else {
        props.success(false);
        props.dialogMessage(getLabel('cancelReservationMessageFailed'));
      }
    }
  }, [deleteReservationResult.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (checkInReservationResult.data) {
      setActualReservation(0);
      props.showMessage(true);
      if (checkInReservationResult.data.result) {
        props.success(true);
        props.dialogMessage(getLabel('checkInMessageSuccess'));
      } else {
        props.success(false);
        props.dialogMessage(getLabel('checkInMessageFailed'));
      }
    }
  }, [checkInReservationResult.data]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (checkOutReservationResult.data) {
      if (checkOutReservationResult.data.result) {
        disableWorkstationReservation();
      } else {
        props.success(false);
        props.dialogMessage(getLabel('checkOutMessageFailed'));
        props.showMessage(true);
      }
    }
  }, [checkOutReservationResult.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (disableWorkstationReservationResult.data) {
      props.showMessage(true);
      setActualReservation(0);
      if (disableWorkstationReservationResult.data.result) {
        props.success(true);
        props.dialogMessage(getLabel('checkOutMessageSuccess'));
      } else {
        props.success(false);
        props.dialogMessage(getLabel('checkOutMessageFailed'));
      }
    }
  }, [disableWorkstationReservationResult.data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.container}>
      <Dialog
        open={openCancelReservationDialog}
        onClose={handleCloseCancelReservation}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        className={classes.cancelReservationDialogBox}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          classes={{ root: classes.dialogTitle }}
        >
          Cancelar reservación
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText classes={{ root: classes.dialogSubtitle }}>
            ¿Estás seguro de que deseas cancelar la reservación?
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogButtonsContainer }}>
          <Button
            className={`${classes.dialogButtons} ${classes.cancelarButton}`}
            onClick={handleCloseCancelReservation}
            color="primary"
          >
            No
          </Button>
          <Button
            className={`${classes.dialogButtons} ${classes.confirmButton}`}
            onClick={handleDeleteReservation}
            color="primary"
          >
            Sí
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openCheckInReservationDialog}
        onClose={handleCloseCheckIn}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        className={classes.cancelReservationDialogBox}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          classes={{ root: classes.dialogTitle }}
        >
          Check-in
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText classes={{ root: classes.dialogSubtitle }}>
            ¿Estás seguro de que deseas realizar el check-in para la
            reservación?
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogButtonsContainer }}>
          <Button
            className={`${classes.dialogButtons} ${classes.cancelarButton}`}
            onClick={handleCloseCheckIn}
            color="primary"
          >
            No
          </Button>
          <Button
            className={`${classes.dialogButtons} ${classes.confirmButton}`}
            onClick={handleCheckIn}
            color="primary"
          >
            Sí
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCheckOutReservationDialog}
        onClose={handleCloseCheckOut}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        className={classes.cancelReservationDialogBox}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          classes={{ root: classes.dialogTitle }}
        >
          Check-out
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText classes={{ root: classes.dialogSubtitle }}>
            ¿Estás seguro de que deseas realizar el check-out para la
            reservación?
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogButtonsContainer }}>
          <Button
            className={`${classes.dialogButtons} ${classes.cancelarButton}}`}
            onClick={handleCloseCheckOut}
            color="primary"
          >
            No
          </Button>
          <Button
            className={`${classes.dialogButtons} ${classes.confirmButton}`}
            onClick={handleCheckOut}
            color="primary"
          >
            Sí
          </Button>
        </DialogActions>
      </Dialog>
      <h1 className={classes.containerTitle}>Registro de reservaciones</h1>
      <div className={classes.monthContainer}>
        <div className={classes.containerDate}>
          <CustomRangeDatePicker
            date={props.date}
            endDate={props.endDate}
            updateDate={props.updateDate}
            updateEndDate={props.updateEndDate}
            small={true}
          />
          <button
            className={classes.reportButton}
            onClick={props.generateReport}
          >
            Generar reporte
          </button>
        </div>
        <div className={classes.reservationCountFont}>
          {filteredReservations.length}{' '}
          {props.data.length === 1 ? 'Reservación' : 'Reservaciones'}
        </div>
      </div>
      <div className={classes.separator}></div>
      <div className={classes.subtitlesDiv}>
        <div className={`${classes.subtitleFont} first`}>Estación</div>
        <div className={`${classes.subtitleFont} floor`}>Piso</div>
        <div className={`${classes.subtitleFont}`}>Fecha</div>
        <div className={`${classes.subtitleFont}`}>Parqueo</div>
        <div className={`${classes.subtitleFont}`}>Detalle</div>
      </div>
      {sortedArray.map((item, index) => {
        return (
          <div key={index} className={classes.reservationInfoDiv}>
            <div className={classes.cornerDiv}></div>
            <div className={`${classes.reservationInfoFont} first`}>
              Estacion{' '}
              {item.workstationreservation.tableId +
                item.workstationreservation.workstationId}
            </div>
            <div className={`${classes.reservationInfoFont}`}>
              {item.workstationreservation.floorId === 2
                ? 'Segundo Piso'
                : 'Tercer Piso'}
            </div>
            <div className={`${classes.reservationInfoFont} date`}>
              {dayjs.tz(item.date, timeZone).format('DD/MM/YYYY')}
            </div>
            <div className={`${classes.reservationInfoFont} parking`}>
              {item.needsParking ? "Si" : "No"}
            </div>
            <div className={`${classes.reservationInfoFont} fourth`}>
              Reservación hecha por {item.fullName}
            </div>
            {/* <div className={classes.break}></div> */}
            {getItemDate(item.date).valueOf() >= today.valueOf() ? (
              <div className={`${classes.reservationButtonsWrapper}`}>
                {item.status === ReservationStatus.disabled ? (
                  <p>Cancelada</p>
                ) : null}
                {item.status === ReservationStatus.enable &&
                getItemDate(item.date).valueOf() !== today.valueOf() ? (
                  <>
                    <p>Reservada</p>
                    <p
                      className={`${classes.cancelButton}`}
                      role="button"
                      onClick={() => {
                        setOpenCancelReservationDialog(true);
                        setActualReservation(item.id);
                      }}
                    >
                      Cancelar
                    </p>
                  </>
                ) : null}
                {item.status === ReservationStatus.enable &&
                getItemDate(item.date).valueOf() === today.valueOf() ? (
                  <>
                    <p
                      className={`${classes.checkinButton}`}
                      role="button"
                      onClick={() => {
                        setOpenCheckInReservationDialog(true);
                        setActualReservation(item.id);
                      }}
                    >
                      Check-in
                    </p>
                    <p
                      className={`${classes.cancelButton}`}
                      role="button"
                      onClick={() => {
                        setOpenCancelReservationDialog(true);
                        setActualReservation(item.id);
                      }}
                    >
                      Cancelar
                    </p>
                  </>
                ) : null}
                {item.status === ReservationStatus.checked &&
                getItemDate(item.date).valueOf() === today.valueOf() &&
                item?.workstationreservation?.areaId === 1 ? (
                  <p
                    className={`${classes.checkinButton}`}
                    role="button"
                    onClick={() => {
                      setOpenCheckOutReservationDialog(true);
                      setActualReservation(item.id);
                    }}
                  >
                    Check-out
                  </p>
                ) : null}
                {item.status === ReservationStatus.checked &&
                getItemDate(item.date).valueOf() === today.valueOf() &&
                item?.workstationreservation?.areaId > 1 ? (
                  <p>Checked</p>
                ) : null}
                {item.status === ReservationStatus.checkedOut ? (
                  <p>Checked-out</p>
                ) : null}
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
export default ReservationHistoryComponent;
