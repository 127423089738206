import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Helmet } from 'react-helmet';

const client = new ApolloClient({
  uri: process.env.REACT_APP_ENDPOINT_URL,
  cache: new InMemoryCache({
    typePolicies: {
      Table: {
        keyFields: ['id', 'floorId'],
      },
      Workstation: {
        keyFields: ['id', 'tableId', 'floorId'],
      },
      Reservation: {
        fields: {
          workstationreservation: {
            merge: false,
          },
        },
      },
      Query: {
        fields: {
          getAllReservations: {
            merge: false,
          },
          getReservationByDateAndFloor: {
            merge: false,
          },
        },
      },
    },
  }),
});

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router history={history}>
        <Helmet>
          <title>Deskbooking - Wunderman Thompson</title>
          <meta name="author" content="Wunderman Thompson" />
          <meta
            name="description"
            content="Deskbooking is an app for the employees of Wunderman Thompson to book workstations at the San Jose company building"
          />
        </Helmet>
        <App />
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
