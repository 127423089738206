import React from 'react';
import linesIcon from '../../assets/images/lines.svg';
import FilterSelect, { ISelectOption } from '../FilterSelect/FilterSelect';
import { useStyles } from './MapHeader.styles';
import CustomDatePicker from '../Calendar/Calendar';
import { IFloor } from '../../interfaces/IFloor';
import DeskAvailability from '../DeskAvailability/DeskAvailability';

interface IProps {
  filterOnClick: () => void;
  filter: boolean;
  date: Date;
  handleClick: (date: Date) => void;
  floors?: IFloor[];
  selectedFloor?: IFloor;
  selectFloor: (value: number) => void;
  availableFloors:number[];
}

const MapHeader = (props: IProps) => {

  const classes = useStyles();
  var result: IFloor[]=[];
  if(props.floors){
    result=props.floors.filter(floor => props.availableFloors.includes(floor.id));
  }

  const handleFloorSelect = (value: any) => {
    props.selectFloor(parseInt(value));
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <>
      <div className={classes.MapHeader}>
        <h2 className={classes.MapHeaderTitle}>Elegir estación de trabajo</h2>
        <button
          className={classes.MapHeaderButton}
          onClick={props.filterOnClick}
        >
          <img
            src={linesIcon}
            className={classes.mapHeaderBtnIcon}
            alt="filter icon"
          ></img>
          <p className={classes.mapHeaderBtnFont}>Filtros</p>
        </button>

        <DeskAvailability isAdmin={false} />

        <div
          className={`${classes.mapFilterDiv} ${props.filter ? 'active' : ''}`}
        >
          <div className={classes.filterDiv}>
            <h2 className={classes.filterFont}>Piso</h2>
            <FilterSelect
              handleSelect={handleFloorSelect}
              value={props.selectedFloor ? `${props.selectedFloor.id}` : ''}
              options={
                result &&
                result.map((floor: IFloor) => {
                  const floorOption: ISelectOption = {
                    text: floor.floorName,
                    value: `${floor.id}`,
                  };
                  return floorOption;
                })
              }
            ></FilterSelect>
          </div>
          {/* <div className={classes.filterDiv}>
                <h2 className={classes.filterFont}>Area</h2>
                <FilterSelect disabled={true} handleSelect={()=>{}} ></FilterSelect>
              </div> */}
          <div className={classes.filterDiv}>
            <h2 className={classes.filterFont}>Fecha</h2>
            <div className={classes.dateInput}>
              <CustomDatePicker
                date={props.date}
                today={today}
                handleClick={props.handleClick}
                small={true}
                isAdmin={false}
              />
            </div>
          </div>
          {/* <div className={classes.filterDiv}>
                <h2 className={classes.filterFont}>Hora</h2>
                <FilterSelect disabled={true} handleSelect={()=>{}}></FilterSelect>
              </div> */}
        </div>
      </div>
      <hr className={classes.mapHeaderDivider}></hr>
    </>
  );
};

export default MapHeader;
