import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  NavMenu: {
    position: 'fixed',
    backgroundColor: theme.palette.oceanBlue,
    transition: '300ms',
    zIndex: 2,
    width: '235px',
    minHeight: '100vh',
    left: '-235px',
    top: '80px',
    overflow: 'auto',
    height: '100vh',

    '&.active': {
      left: 0,
      transition: '200ms',
    },

    [theme.breakpoints.up('medium')]: {},
    [theme.breakpoints.up('tablet')]: {
      width: '235px',
      minHeight: '100vh',
      left: '-235px',
      top: '80px',
    },
    [theme.breakpoints.up('large')]: {
      width: '18.35vw',
      minHeight: '100vh',
      left: '-18.35vw',
      top: '6.25vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      float: 'left',
      marginLeft: 0,
      left: 0,
      width: '16.21vw',
      top: '5.55vw',
    },
  },

  hideScreen: {
    position: 'fixed',
    width: '100vw',
    minHeight: '100vh',
    left: 0,
    top: '80px',
    backgroundColor: theme.palette.black,
    opacity: 0.5,
    zIndex: 1,
    animation: 'fadeInOpacity 0.5s',

    '&.not': {
      display: 'none',
    },

    [theme.breakpoints.up('medium')]: {},
    [theme.breakpoints.up('tablet')]: {
      top: '80px',
    },
    [theme.breakpoints.up('large')]: {
      top: '5.88vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      display: 'none',
    },
  },

  navItemContainer: {
    position: 'relative',
    overflow: 'hidden',
    right: 0,
    width: '100%',
  },

  navButton: {
    display: 'flex',
    float: 'right',
    background: '#0a47ed',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '224px',
    height: '80px',
    borderRadius: '14px 0px 0px 14px',

    '&:first-child': {
      marginTop: '24px',
      [theme.breakpoints.up('large')]: {
        marginTop: '1.87vw',
      },
    },
    '&:hover': {
      cursor: 'pointer',
    },

    '&.active': {
      background: '#FFFFFF',
    },

    [theme.breakpoints.up('medium')]: {},
    [theme.breakpoints.up('tablet')]: {},
    [theme.breakpoints.up('large')]: {
      width: '17.5vw',
      height: '6.25vw',
      borderRadius: '1vw 0px 0px 1vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '15.55vw',
    },
  },

  buttonIcon: {
    fill: theme.palette.oceanBlue,
    color: theme.palette.white,
    stroke: theme.palette.white,
    width: '24px',
    height: '24px',

    '&.active': {
      fill: theme.palette.white,
      color: theme.palette.oceanBlue,
      stroke: theme.palette.oceanBlue,
    },

    [theme.breakpoints.up('medium')]: {},
    [theme.breakpoints.up('tablet')]: {},
    [theme.breakpoints.up('large')]: {
      width: '1.87vw',
      height: '1.87vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '1.66vw',
      height: '1.66vw',
    },
  },

  buttonText: {
    margin: 0,
    fontFamily: 'WTGothic',
    fontWeight: 400,
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.01em',
    color: theme.palette.white,
    marginLeft: '18px',
    width: '141px',
    height: '39px',
    fontSize: '14px',

    '&.active': {
      color: theme.palette.oceanBlue,
    },

    [theme.breakpoints.up('medium')]: {},
    [theme.breakpoints.up('tablet')]: {},
    [theme.breakpoints.up('large')]: {
      marginLeft: '1.40vw',
      width: '11.01vw',
      height: '3.04vw',
      fontSize: '1.09vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginLeft: '1.25vw',
      width: '9.72vw',
      fontSize: '0.97vw',
    },
  },
}));
