import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  stationInfoContainer: {
    display: 'flex',
    filter: 'drop-shadow(0px 0.31vw 0.31vw rgba(33, 35, 34, 0.2))',
    border: '1px solid #d6d6d6',
    boxSizing: 'border-box',
    background: '#ffffff',
    width: '91.11%',
    marginLeft: '4.26%',
    borderRadius: '3.33vw',
    paddingBottom: '6.400%',
    padding: '3%',
    flexWrap: 'wrap',
    rowGap: '1.5rem',
    marginBottom: '3vw',

    [theme.breakpoints.up('medium')]: {
      width: '95%',
      marginLeft: '2.15%',
      borderRadius: '0.87vw',
      paddingBottom: '3.125%',
      padding: '3.125% 4.167%',
      flexWrap: 'nowrap',
      marginBottom: '1.5vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '93%',
      marginLeft: '2.15%',
      padding: '2.500% 3.333%',
    },
    [theme.breakpoints.up('large')]: {
      width: '85%',
      marginLeft: '10.078%',
      padding: '1.875% 2.500%',
      marginBottom: '1vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '79.236%',
      marginLeft: '18.5%',
      padding: '1.667% 2.222%',
    },
  },

  infoFont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: 'WTGothic',
    letterSpacing: '0.01em',
    fontSize: '1rem',
    borderRadius: '1.600vw',

    [theme.breakpoints.up('medium')]: {
      borderRadius: '0.781vw',
    },
    [theme.breakpoints.up('tablet')]: {
      borderRadius: '0.625vw',
    },
    [theme.breakpoints.up('large')]: {
      borderRadius: '0.469vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      borderRadius: '0.417vw',
    },
  },

  firstSubtitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: '0.3rem',
    width: '8rem',

    '&.stationId': {
      width: '9rem',
      alignItems: 'center',
      [theme.breakpoints.up('medium')]: {
        justifyContent: 'center',
      },
    },
  },

  secondSubtitle: {
    display: 'flex',
    marginLeft: '0rem',
    width: '9rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&.third': {
      width: '10rem',
    },

    [theme.breakpoints.up('medium')]: {
      marginLeft: '1rem',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '2.5rem',
    },
    [theme.breakpoints.up('large')]: {
      marginLeft: '5rem',
    },
    [theme.breakpoints.up('extraLarge')]: {},
  },

  switch_track: {
    backgroundColor: theme.palette.lightGrey,
    opacity: 1,
    height: '1rem',
  },
  switch_base: {
    color: theme.palette.white,
  },
  switch_primary: {
    '&.Mui-checked': {
      color: theme.palette.white,
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.oceanBlue,
      opacity: 1,
    },
  },

  thumb: {
    width: '0.8rem',
    height: '0.8rem',
    marginTop: '0.25rem',
    marginLeft: '0.1rem',
  },
}));
