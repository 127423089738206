import React, { useState, useEffect } from "react";
import { useStyles } from './NextReservations.styles';
import { IReservation } from '../../interfaces/IReservation';
import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getLabel } from '../../helpers/labels';
dayjs.extend(utc);
dayjs.extend(timezone);

const timeZone = dayjs.tz.guess();

interface IProps {
  data: IReservation[];
  email: string;
  date: Date;

  dialogMessage: (message: string) => void;

  showMessage: (valor: boolean) => void;

  success: (valor: boolean) => void;

}

const NextReservationsComponent = (props: IProps) => {
  const classes = useStyles();
  const [openCancelReservationDialog, setOpenCancelReservationDialog] =
    useState(false);
  const [actualReservation, setActualReservation] = useState(0);


  const deleteReservationQuery = gql`
    mutation ($reservationID: Float!) {
      result: deleteWorkstationReservation(reservationID: $reservationID)
    }
  `;

  const nextReservationsQuery = gql`
    query($email: String!){
        reservations: getNextReservationsByUser(email: $email){
              date,
          workstationreservation{
            tableId,
            workstationId,
            floorId
          }
        }
    }
    `;

  const reservationsQuery = gql`
    query($email: String!, $firstDay: DateTime!, $lastDay: DateTime!){
        reservations: getReservationsHistoryByUserAndDate(email: $email, firstDay: $firstDay, lastDay: $lastDay){
          date,
          workstationreservation{
            tableId,
            workstationId,
            floorId
          }
        }
      }
    `;

  const handleCloseCancelReservation = () => {
    setOpenCancelReservationDialog(false);
  };

  const handleDeleteReservation = () => {
    deleteReservation();
    setOpenCancelReservationDialog(false);
  };

  const [deleteReservation, deleteReservationResult] = useMutation(
    deleteReservationQuery,
    {
      variables: { reservationID: actualReservation },
      refetchQueries: [
        {
          query: reservationsQuery,
          variables: {
            email: props.email,
            firstDay: dayjs(props.date).startOf('month').format('YYYY/MM/DD'),
            lastDay: dayjs(props.date).endOf('month').format('YYYY/MM/DD')
          },
        },
        {
          query: nextReservationsQuery,
          variables: {
            email: props.email,
          },
        },
      ],
    }
  );

  useEffect(() => {
    if (deleteReservationResult.data) {
      setActualReservation(0);
      props.showMessage(true);
      if (deleteReservationResult.data.result) {
        props.success(true);
        props.dialogMessage(getLabel('cancelReservationMessageSuccess'));
      } else {
        props.success(false);
        props.dialogMessage(getLabel('cancelReservationMessageFailed'));
      }
    }
  }, [deleteReservationResult.data]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className={classes.container}>
      <Dialog
        open={openCancelReservationDialog}
        onClose={handleCloseCancelReservation}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        className={classes.cancelReservationDialogBox}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          classes={{ root: classes.dialogTitle }}
        >
          Cancelar reservación
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText classes={{ root: classes.dialogSubtitle }}>
            ¿Estás seguro de que deseas cancelar la reservación?
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogButtonsContainer }}>
          <Button
            className={`${classes.dialogButtons} ${classes.cancelarButton}`}
            onClick={handleCloseCancelReservation}
            color="primary"
          >
            No
          </Button>
          <Button
            className={`${classes.dialogButtons} ${classes.confirmButton}`}
            onClick={handleDeleteReservation}
            color="primary"
          >
            Sí
          </Button>
        </DialogActions>
      </Dialog>
      <h1 className={classes.containerTitle}>Próximas reservaciones</h1>
      <div className={classes.subtitlesDiv}>
        <div className={`${classes.subtitleFont} first`}>Estación</div>
        <div className={`${classes.subtitleFont} second`}>Fecha</div>
        <div className={`${classes.subtitleFont} third`}>Piso</div>
      </div>
      {props.data.map((item, index) => {
        return (
          <div key={index} className={classes.reservationInfoDiv}>
            <div className={classes.firstDiv}>
              <div className={classes.cornerDiv}></div>
            </div>
            <div className={classes.secondDiv}>
              <div className={`${classes.reservationInfoFont} first`}>Estacion {item.workstationreservation.tableId + item.workstationreservation.workstationId}</div>
              <div className={`${classes.reservationInfoFont}`}>{dayjs.tz(item.date, timeZone).format('DD/MM/YYYY')}</div>
              <div className={`${classes.reservationInfoFont}`}>{item.workstationreservation.floorId === 2 ? "Segundo Piso" : "Tercer Piso"}</div>
              <div className={classes.break}></div>
              <div className={`${classes.cancelButton}`}
                onClick={() => { setOpenCancelReservationDialog(true); setActualReservation(item.id) }}>Cancelar</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default NextReservationsComponent;