import React from "react";
import { useStyles } from './OccupationInformation.styles';
import { getLabel } from '../../helpers/labels';

interface IProps {
    maxCapacity:number;
    percentageOcuppiedExpected:number;
    percentageOcuppied:number;
    occupation:string;
  }

const OccupationInformationComponent = (props: IProps) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.container}>
              <div className={`${classes.infoFont} ${props.occupation === 'light'? 'lightOccupationMessage': props.occupation === 'medium'? 'warningMessage': 'maxOccupationMessage'}`}>
                  {getLabel(props.occupation === 'light'? 'lightOccupation': props.occupation === 'medium'? 'mediumOccupation': 'maxOccupation')}
              </div>
              <div className={classes.infoDiv}>
                <div className={`${classes.infoFont}`}>
                    Ocupación actual:&nbsp;&nbsp;{props.percentageOcuppied}/{props.maxCapacity}
                </div>
                <div className={`${classes.infoFont}`}>
                    Ocupación máxima esperada:&nbsp;&nbsp;{props.maxCapacity}/{props.percentageOcuppiedExpected}
                </div>
              </div>
            </div>
        </>
      );

};

export default OccupationInformationComponent;