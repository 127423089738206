import React from 'react';
import { IReservation } from '../../interfaces/IReservation';
import { useStyles } from './ReservationHistory.styles';
import leftArrow from '../../assets/images/leftArrowSidebarCalendar.svg';
import rightArrow from '../../assets/images/rightArrowSidebarCalendar.svg';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const timeZone = dayjs.tz.guess();

interface IProps {
  data: IReservation[];
  date: Date;
  updateDate: (option: number) => void;
}

const ReservationHistoryComponent = (props: IProps) => {
  const classes = useStyles();

  const MonthHandler = (option: number) => {
    props.updateDate(option);
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.containerTitle}>Historial de reservaciones</h1>
      <div className={classes.monthContainer}>
        <div
          className={`${classes.arrowContainer} left`}
          onClick={() => {
            MonthHandler(2);
          }}
        >
          <img
            className={classes.arrowSrc}
            src={leftArrow}
            alt="left arrow month"
          />
        </div>
        <h2 className={classes.monthTitleFormat}>
          {dayjs(props.date).format('MMMM, YYYY')}
        </h2>
        <div
          className={`${classes.arrowContainer} right`}
          onClick={() => {
            MonthHandler(1);
          }}
        >
          <img
            className={classes.arrowSrc}
            src={rightArrow}
            alt="right arrow month"
          />
        </div>
      </div>
      <div className={classes.separator}></div>
      <div className={classes.subtitlesDiv}>
        <div className={`${classes.subtitleFont} first`}>Estación</div>
        <div className={`${classes.subtitleFont} second`}>Fecha</div>
        <div className={`${classes.subtitleFont} third`}>Piso</div>
      </div>
      {props.data.map((item, index) => {
        return (
          <div key={index} className={classes.reservationInfoDiv}>
            <div className={classes.cornerDiv}></div>
            <div className={`${classes.reservationInfoFont} first`}>
              Estacion{' '}
              {item.workstationreservation?.tableId +
                item.workstationreservation?.workstationId}
            </div>
            <div className={`${classes.reservationInfoFont}`}>
              {dayjs.tz(item.date, timeZone).format('DD/MM/YYYY')}
            </div>
            <div className={`${classes.reservationInfoFont}`}>
              {item.workstationreservation?.floorId === 2
                ? 'Segundo Piso'
                : 'Tercer Piso'}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default ReservationHistoryComponent;
