import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

    mainTitleDiv: {
        display: 'block',
        boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
        height: '23.73vw',
        width: '100vw',
        marginLeft: 0,
        marginTop: '30px',

        [theme.breakpoints.up('medium')]: {
            marginTop: '80px',
            height: '12.10vw',
            width: '100vw',
        },
        [theme.breakpoints.up('tablet')]: {
            display: 'flex',
            flexDirection: 'row',
            marginTop:'80px',
            width: '100vw',
            height: '7.08vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '100vw',
            height: '5.23vw',
            marginTop: '6.25vw',
            marginLeft: 'unset',
        },
        [theme.breakpoints.up('extraLarge')]: {
            marginTop: '5.55vw',
            width: '83.68vw',
            height: '4.65vw',
            marginLeft: '16.30vw',
        },
    },

    MapHeaderButton: {
        display: 'none',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 0,
        margin: 0,
        marginLeft: '4.26vw',
        width: '32vw',
        height: '11.73vw',
        borderRadius: '3.2vw',
        float: 'left',

        [theme.breakpoints.up('tablet')]: {
            display: 'flex',
            marginLeft: '1.66vw',
            width: '12.5vw',
            height:'5vw',
            borderRadius: '0.83vw',
            marginTop: '2vw',
        },
        [theme.breakpoints.up('large')]: {
            margin: '1.11vw 0 0 2.11vw',
            width: '9.37vw',
            height: '4.37vw',
            borderRadius: '0.93vw',
        
        },
        [theme.breakpoints.up('extraLarge')]: {
            margin: '1.11vw 0 0 1.11vw',
            width: '8.33vw',
            height: '3.88vw',
            borderRadius: '0.83vw',
        },
    },

    containerDiv:{
        marginLeft:'4.26vw',

        [theme.breakpoints.up('medium')]: {
            marginLeft: '8.46vw', 
        },
        [theme.breakpoints.up('tablet')]: {
            marginLeft:'6.17vw',
        },
        [theme.breakpoints.up('large')]: {
            marginLeft: '10.15vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            marginLeft: '18.61vw',
        },

    },

    MapHeader: {
        height: '4vw',
        width: '91.46vw',
        marginTop:'4.26vw',

        [theme.breakpoints.up('medium')]: {
            width: '83.07vw',
            marginTop: '2.08vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '46.45vw',
            marginTop:'1.66vw',
            height: '2vw',
        },
        [theme.breakpoints.up('large')]: {
            marginTop: '1.25vw',
            width: '39.68vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '30.23vw',
            marginTop:'1.11vw',
        },

    },
    mapInfoDiv: { 
        justifyContent: 'center',
        boxSizing: 'border-box',
        display: 'block',
        width:  '91.46vw',
        height: '30vw',
        marginTop:'4.26vw',
        borderRadius: '3.2vw',
        float: 'left',
        paddingBottom: '3.26vw',

        [theme.breakpoints.up('medium')]: {
            display: 'flex',
            flexDirection: 'row',
            width:  '83.07vw',
            height: '7.29vw',
            marginTop: '2.08vw',
            borderRadius: '1.56vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width:  '75.45vw',
            height: '5.83vw',
            marginTop: '1.66vw',
            borderRadius: '0.83vw',   
        },
        [theme.breakpoints.up('large')]: {
            width: '69.06vw',
            height: '4.37vw',
            marginTop: '1.25vw',
            borderRadius: '0.93vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '69.79vw',
            height: '3.88vw',
            marginTop: '1.11vw',
            borderRadius: '0.83vw',
        },
    },
    mapFilterDiv: {
        display: 'block',
        flexDirection: 'row',
        justifyContent: 'center',
        boxSizing: 'border-box',
        float: 'left',
        width: '91.46vw',
        height: '20.53vw',
        paddingBottom: '15px',
        marginTop: '4.26vw',
        borderRadius: '3.2vw',

        [theme.breakpoints.up('medium')]: {
            width: '83.07vw',
            marginTop: '2.08vw',
            borderRadius: '1.56vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '86.45vw',
            height: '10.41vw',
            marginTop:' 1.66vw',
            borderRadius:' 0.83vw',
            display: 'flex',
        },
        [theme.breakpoints.up('large')]: {
            width: '79.68vw',
            height:'7.81vw',
            marginTop:'1.25vw',
            borderRadius:' 0.93vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '79.23vw',
            height:'6.94vw',
            marginTop:'1.11vw',
            borderRadius:'0.83vw',
        },
    },

    mapDiv: {
        position: 'relative',
        display: 'inline-block',
        height: '360px',
        boxSizing: 'border-box',
        width: '91.46vw',
        marginTop: '4.26vw',
        marginBottom: '3.2vw',
        borderRadius: '3.2vw',
        overflow: 'hidden',

        [theme.breakpoints.up('medium')]: {
            width: '83.30vw',
            marginTop: '2.08vw',
            marginBottom: '1.56vw',
            borderRadius: '1.56vw',
            overflow: 'unset'
        },
        [theme.breakpoints.up('tablet')]: {
            width: '86.45vw',
            marginTop: '1.66vw',
            marginBottom: '1.56vw',
            borderRadius: '1.25vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '59.37vw',
            marginTop: '1.25vw',
            marginBottom: '1.56vw',
            borderRadius: '0.93vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '60.55vw',
            marginTop: '1.11vw',
            marginBottom: '1.56vw',
            borderRadius: '0.83vw',
        },
    },
    stationInfoDiv: {
        right: '10.16vw',
        boxSizing: 'border-box',
        position: 'fixed',
        top: 'unset',
        width: '100vw',
        borderRadius: 0,
        paddingBottom: '0vw',
        height: '25.4vw',
        bottom: 0,
        left: 0,

        [theme.breakpoints.up('medium')]: {
            height: '13.02vw',
        },
        [theme.breakpoints.up('tablet')]: {
            height: '10.532vw',
        },
        [theme.breakpoints.up('large')]: {
            position: 'absolute',
            top: '27.34vw',
            right: '10.16vw',
            width: '18.51vw',
            borderRadius: '0.93vw',
            paddingBottom: '0.8vw',
            bottom: 'unset',
            left: 'unset',
        },
        [theme.breakpoints.up('extraLarge')]: {
            top: '23.72vw',
            right: '2.15vw',
            width: '16.45vw',
            borderRadius: '0.83vw',
        },
    },
}))
