import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    marginTop: "1.25vw",
    filter: "drop-shadow(0px 0.31vw 0.31vw rgba(33, 35, 34, 0.2))",
    border: "1px solid #d6d6d6",
    boxSizing: "border-box",
    background: "#ffffff",
    width: "91.11vw",
    marginLeft: "4.26vw",
    borderRadius: "3.33vw",
    padding: "1.5rem 0.5rem",
    flexDirection: "column",

    [theme.breakpoints.up("medium")]: {
      width: "95vw",
      marginLeft: "2.15vw",
      borderRadius: "0.87vw",
      paddingBottom: "3.125vw",
    },
    [theme.breakpoints.up("tablet")]: {
      width: "95vw",
      marginLeft: "2.15vw",
      paddingBottom: "2.500vw",
    },
    [theme.breakpoints.up("large")]: {
      width: "79.766vw",
      marginLeft: "10.078vw",
      paddingBottom: "1.875vw",
      flexDirection: "row",
    },
    [theme.breakpoints.up("extraLarge")]: {
      marginTop: "1.45vw",
      width: "79.236vw",
      marginLeft: "17.87vw",
      paddingBottom: "1.667vw",
    },
  },

  infoFont: {
    fontFamily: "WTGothic",
    letterSpacing: "0.01em",
    fontSize: "1rem",
    width: "88vw",
    padding: "1rem",
    borderRadius: "0.375rem",

    "&.warningMessage": {
      backgroundColor: theme.palette.peach,
      color: theme.palette.white,
      width: "max-content",
    },

    "&.lightOccupationMessage": {
      backgroundColor: theme.palette.green,
      color: theme.palette.white,
      width: "max-content",
    },

    "&.maxOccupationMessage": {
      backgroundColor: theme.palette.redRGB,
      color: theme.palette.white,
      width: "max-content",
    },

    [theme.breakpoints.up('medium')]: {
      width: "max-content",        
    },
  },

  infoDiv: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("medium")]: {
      flexDirection: "row",
    },
  },
}));
