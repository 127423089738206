import { toRelativeUrl } from '@okta/okta-auth-js';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { adminBarData, specialUserBarData, BarData } from './BarData';
import './NavBar.css';
import { useStyles } from './NavBar.styles';
import { useOktaAuth } from '@okta/okta-react';
import { gql, useLazyQuery } from '@apollo/client';
import { IUser } from '../../interfaces/IUser';

interface IProps {
  active: boolean;
  setSideMenu: () => void;
  updateIsAdmin: (value: boolean) => void;
  updateIsBlocked: (value: boolean) => void;
  updateUser: (value: IUser) => void;
}

const NavBar = (props: IProps) => {
  const { active, updateIsAdmin, updateIsBlocked, updateUser } = props;
  const [press, setPress] = useState(0);
  const [userEmail, setUserEmail] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isSpecial, setIsSpecial] = useState(false);
  const { authState, oktaAuth } = useOktaAuth();
  const classes = useStyles();
  const history = useHistory();
  const changeURL = useCallback(
    (path: string, text: string) => {
      history.replace(toRelativeUrl(path, window.location.origin));
    },
    [history]
  );

  useEffect(() => {
    const path = window.location.pathname;
    const index = BarData.concat(specialUserBarData)
      .concat(adminBarData)
      .findIndex((element, index) => {
        if (element.path === path) {
          return true;
        }
        return false;
      });
    setPress(index);
  }, []);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      oktaAuth.getUser().then(
        (result) => {
          if (result.email) {
            setUserEmail(result.email);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [authState, oktaAuth]);

  const userQuery = gql`
    query ($email: String!) {
      users: getUserByEmail(email: $email) {
        email
        name
        role
        areaId
        phone
        blocked
      }
    }
  `;

  const [getUserData, userData] = useLazyQuery(userQuery, {
    variables: { email: userEmail },
  });

  useEffect(() => {
    getUserData();
  }, [userEmail, getUserData]);

  useEffect(() => {
    if (userData.data) {
      if (userData.data.users.length > 0) {
        updateUser(userData.data.users[0]);
        if (userData.data.users[0].role === 'Admin') {
          setIsAdmin(true);
          updateIsAdmin(true);
        }
        if (userData.data.users[0].areaId !== 1) {
          setIsSpecial(true);
        }
        if (userData.data.users[0].blocked === 1) {
          setIsBlocked(true);
          updateIsBlocked(true);
        }
      }
    }
  }, [userData.data, updateIsAdmin, updateIsBlocked, updateUser]);

  return (
    <>
      <nav className={`${classes.NavMenu} ${active ? '' : 'active'}`}>
        <ul className={classes.navItemContainer}>
          {(isSpecial
            ? isAdmin
              ? BarData.concat(specialUserBarData).concat(adminBarData)
              : BarData.concat(specialUserBarData)
            : isAdmin
            ? BarData.concat(adminBarData)
            : BarData
          ).map((item, index) => {
            const Icon = item.image;
            return (
              <div
                key={index}
                className={`${classes.navButton} ${
                  press === index ? 'active' : ''
                }`}
                onClick={() => {
                  changeURL(item.path, item.text);
                  setPress(index);
                  props.setSideMenu();
                }}
              >
                <Icon
                  className={`${classes.buttonIcon} ${
                    press === index ? 'active' : ''
                  }`}
                ></Icon>
                <h1
                  className={`${classes.buttonText} ${
                    press === index ? 'active' : ''
                  }`}
                >
                  {item.text}
                </h1>
              </div>
            );
          })}
        </ul>
      </nav>
      <div
        className={`${classes.hideScreen} ${props.active ? 'not' : ''}`}
        onClick={props.setSideMenu}
      ></div>
    </>
  );
};

export default NavBar;
