export interface IWorkstation {
  id: string;
  floorId: number;
  status: boolean;
  tableId: string;
  reservationStatus: WorkStationStatus;
  areaId?: number;
}

export enum WorkStationStatus {
  available,
  occupied,
  disabled,
  selected,
  actual,
}

export interface IWorkstationlist {
  workstations: IWorkstation[];
}
