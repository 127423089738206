import { IWorkstationReservation } from './IWorkstationReservation';
import { IMeetingRoomReservation } from './IMeetingRoomReservation';

export interface IReservation {
  id: number;
  userEmail: string;
  userName: string;
  date: Date;
  status: number;
  fullName: string;
  phoneNumber: string;
  traveledTo: string;
  tripReturnDate: string;
  reservationId?: number;
  needsParking: boolean;
  workstationreservation: IWorkstationReservation;
  meetingRoomReservation: IMeetingRoomReservation;
}
export enum ReservationStatus {
  disabled,
  enable,
  checked,
  checkedOut,
}
