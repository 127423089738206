import React from 'react';
import { useStyles } from './Page.styles';

interface IProps {
}

const IsBlockedPage = (props: IProps) => {
  const classes = useStyles();
  
  return (
    <div className={`${classes.blockedContainer} fromMain`}>
      <h1 className={classes.blockedH1}>
        Su usuario se encuentra bloqueado temporalmente
      </h1>
      <h2 className={classes.blockedH2}>Pongase en contacto con el administrador ¡Gracias!</h2>
      
    </div>
  )
}

export default IsBlockedPage;