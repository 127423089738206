import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

    dateInput2: {
        position: 'relative',
        width: '41.06vw',
        height: '9.6vw',
        marginTop: '3.2vw',

        [theme.breakpoints.up('medium')]: {
            width: '39.19vw',
            height: '4.68vw',
            marginTop: '1.56vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '17.5vw',
            height: '3.75vw',
            marginTop: '1.25vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '168px',
            height: '36px',
            marginTop: '12px',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '11.66vw',
            height: '2.08vw',
            marginTop: '0.83vw',
        },
    },

    inputText2: {
        fontFamily: 'WTGothic',
        fontWeight: 400,
        cursor: 'pointer',
        webkitBoxSizing: 'border-box',
        mozBoxSizing: 'border-box',
        boxSizing: 'border-box',
        width: '41.06vw',
        height: '9.6vw',
        border: '0.26vw solid #D6D6D6',
        paddingLeft: '3.2vw',
        borderRadius: '3.2vw',
        fontSize: '3.73vw',

        [theme.breakpoints.up('medium')]: {
            width: '39.19vw',
            height: '4.68vw',
            border: '1px solid #D6D6D6',
            paddingLeft: '1.56vw',
            borderRadius: '1.56vw',
            fontSize: '1.82vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '17.5vw',
            height: '3.75vw',
            paddingLeft: '1.28vw',
            borderRadius: '1.25vw',
            fontSize: '1.45vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '168px',
            height: '36px',
            paddingLeft: '15px',
            borderRadius: '0.87vw',
            fontSize: '15px',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '11.66vw',
            height: '2.5vw',
            paddingLeft: '1.28vw',
            borderRadius: '0.83vw',
            fontSize: '0.97vw',
        },
    },

    placeHolder2: {
        display: 'none !important',
    },

    floorItem2: {
        
        fontFamily: 'WTGothic',
        fontWeight: 400,
        color: theme.palette.black,
        marginLeft: '3.2vw',
        marginRight: '3.2vw',
        borderRadius: '2.7vw',
        height: '9.6vw',
        fontSize: '3.73vw',

        [theme.breakpoints.up('medium')]: {
            marginLeft: '1.56vw',
            marginLight: '1.56vw',
            borderRadius: '1.30vw',
            height: '4.68vw',
            fontSize: '1.82vw',
        },
        [theme.breakpoints.up('tablet')]: {
            marginLeft: '1.28vw',
            marginRight: '1.28vw',
            borderRadius: '0.88vw',
            height: '3.75vw',
            fontSize: '1.45vw',
        },
        [theme.breakpoints.up('large')]: {
            marginLeft: '15px',
            marginRight: '15px',
            height: '30px',
            borderRadius: '10px',
            fontSize: '15px',
        },
        [theme.breakpoints.up('extraLarge')]: {
            marginLeft: '0.97vw',
            marginRight: '0.97vw',
            borderRadius: '0.69vw',
            height: '2.08vw',
            fontSize: '0.97vw',
        },
    },

    inputIcon2: {
        position: 'absolute',
        pointerEvents: 'none',
        width:'6.4vw',
        height:'6.4vw',
        top:'1.6vw',
        right: '2.93vw',

        [theme.breakpoints.up('medium')]: {
            width: '3.12vw',
            height: '3.12vw',
            top: '0.78vw',
            right: '1.56vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width:'2.5vw',
            height:'2.5vw',
            top: '0.62vw',
            right: '1.25vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '24px',
            height: '24px',
            top: '6px',
            right: '12px',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '1.66vw',
            height: '1.66vw',
            top: '0.41vw',
            right: '0.83vw',
        },

    },


}))