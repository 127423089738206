import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { useStyles } from './ReservationPageSkeleton.styles';

const ReservationPageSkeleton = () => {
    const classes = useStyles();
    return(
        <>
            <div >
                <Skeleton className={classes.mainTitleDiv} variant="text"  />
                <div className={classes.containerDiv}> 
                   <Skeleton className={classes.floorOption} variant="rect"/>
                   <Skeleton className={classes.container} variant="rect"/>
                </div>
            </div>
        </>
    );
};

export default ReservationPageSkeleton;