import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

    mainTitleDiv: {
        display: 'block',
        boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
        height: '23.73vw',
        width: '100vw',
        marginLeft: 0,
        marginTop: '30px',

        [theme.breakpoints.up('medium')]: {
            marginTop: '80px',
            height: '12.10vw',
            width: '100vw',
        },
        [theme.breakpoints.up('tablet')]: {
            display: 'flex',
            flexDirection: 'row',
            marginTop:'80px',
            width: '100vw',
            height: '7.08vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '100vw',
            height: '5.23vw',
            marginTop: '6.25vw',
            marginLeft: 'unset',
        },
        [theme.breakpoints.up('extraLarge')]: {
            marginTop: '5.55vw',
            width: '83.68vw',
            height: '4.65vw',
            marginLeft: '16.30vw',
        },
    },
    containerDiv:{
        marginLeft:'4.26vw',

        [theme.breakpoints.up('medium')]: {
            marginLeft: '8.46vw', 
        },
        [theme.breakpoints.up('tablet')]: {
            marginLeft:'6.17vw',
        },
        [theme.breakpoints.up('large')]: {
            marginLeft: '10.15vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            marginLeft: '18.61vw',
        },

    },


    floorOption: {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '6vw',
        alignItems: 'center',
        width: '81.371vw',
        height: '8.533vw',
        marginBottom: '1vw',

        [theme.breakpoints.up('medium')]: {
            width: '25.680vw',
            height: '5.167vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '35.730vw',
            height: '3.933vw',
            marginTop: '1.9vw'
        },
        [theme.breakpoints.up('large')]: {
            width: '25.175vw',
            height: '2.5vw',
        },
    },
    stationInfoContainer: {
        display: 'flex',
        width: '91.11%',
        borderRadius: '3.33vw',
        flexWrap: 'wrap',
        rowGap: '1.5rem',
        marginBottom: '0.75vw',
        height: '230px',
        [theme.breakpoints.up('medium')]: {
            width: '95%',
            borderRadius: '0.87vw',
            height: '150px',
            marginBottom: '0.20vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '93%', 
            height: '135px'
        },
        [theme.breakpoints.up('large')]: {
            width: '85%',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '79.236%',
            height: '125px'
        },
    },
}))
