import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    
    MapHeader: {
        
        height: 'auto',
        backgroundColor: theme.palette.wheat,
        width: '91.46vw',
        marginLeft:'4.26vw',
        marginTop:'4.26vw',

        [theme.breakpoints.up('medium')]: {
            width: '83.07vw',
            marginLeft: '8.46vw',
            marginTop: '2.08vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '86.45vw',
            marginLeft:'6.17vw',
            marginTop:'1.66vw',
        },
        [theme.breakpoints.up('large')]: {
            marginLeft: '10.15vw',
            marginTop: '1.25vw',
            width: '79.68vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '79.23vw',
            marginLeft: '18.61vw',
            marginTop:'1.11vw',
        },
    
    },

    MapHeaderTitle: {
        
        fontFamily: 'WTGothic',
        margin: 0,
        float: 'left',
        fontWeight: 300,
        width: '55.2vw',
        height: '11.73vw',
        fontSize:'4.8vw',
        lineHeight: '5.93vw',

        [theme.breakpoints.up('medium')]: {
            width: '65.36vw',
            height: '4.03vw',
            fontSize: '3.38vw',
            lineHeight: '4.03vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '72.29vw',
            height: '3.33vw',
            fontSize: '2.70vw',
            lineHeight: '3.33vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '68.43vw',
            height: '3.12vw',
            fontSize: '2.03vw',
            lineHeight: '3.12vw',
            
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '79.23vw',
            height:'2.15vw',
            fontSize:'1.80vw',
            lineHeight:'2.15vw',
        },
    },

    MapHeaderButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: theme.palette.white,
        boxSizing: 'border-box',
        padding: 0,
        outline: 'none',
        margin: 0,
        marginLeft: '4.26vw',
        width: '32vw',
        height: '11.73vw',
        border: '0.26vw solid #D6D6D6',
        borderRadius: '3.2vw',
        float: 'left',

        [theme.breakpoints.up('medium')]: {
            marginLeft:'2.08vw',
            width: '15.62vw',
            height: '6.25vw',
            border: '0.13vw solid #D6D6D6',
            borderRadius: '1.56vw',
        },
        [theme.breakpoints.up('tablet')]: {
            marginLeft: '1.66vw',
            width: '12.5vw',
            height:'5vw',
            border: '0.06vw solid #D6D6D6',
            borderRadius: '0.83vw',
        },
        [theme.breakpoints.up('large')]: {
            margin: '4.35vw 0 0 1.25vw',
            width: '9.37vw',
            height: '4.37vw',
            border: '0.07vw solid #D6D6D6',
            borderRadius: '0.93vw',
            float: 'right',
        },
        [theme.breakpoints.up('extraLarge')]: {
            margin: '1.11vw 0 0 1.11vw',
            width: '8.33vw',
            height: '3.88vw',
            border: '0.06vw solid #D6D6D6',
            borderRadius: '0.83vw',
        },
    },

    mapHeaderBtnIcon: {
        
        width: '6.4vw',
        height: '6.4vw',
        marginLeft: '3.2vw',

        [theme.breakpoints.up('medium')]: {
            width: '3.12vw',
            height: '3.12vw',
            marginLeft: '1.56vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width:'2.5vw',
            height:'2.5vw',
            marginLeft: '1.25vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '1.87vw',
            height: '1.87vw',
            marginLeft: '0.93vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '1.66vw',
            height: '1.66vw',
            marginLeft: '0.83vw',
        },
    },

    mapHeaderBtnFont: {
        
        fontFamily: 'WTGothic',
        margin: 0,
        fontWeight: 400,
        width: '16.26vw',
        height: '5.86vw',
        marginLeft:'3.2vw',
        fontSize:'4.8vw',
        lineHeight: '5.86vw',

        [theme.breakpoints.up('medium')]: {
            width: '6.45vw',
            height: '2.86vw',
            marginLeft: '1.56vw',
            fontSize: '2.34vw',
            lineHeight: '2.86vw',
        },
        [theme.breakpoints.up('tablet')]: {
            height: '2.29vw',
            marginLeft: '1.25vw',
            fontSize: '1.87vw',
            lineHeight: '2.29vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '4.68vw',
            height: '1.87vw',
            marginLeft: '0.93vw',
            fontSize: '1.40vw',
            lineHeight: '1.87vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '4.23vw',
            height: '1.52vw',
            marginLeft: '0.83vw',
            fontSize: '1.25vw',
            lineHeight: '1.52vw',
        },
    },

    

    dateInput: {
        
        marginTop: '3.2vw',
        position: 'relative',
        width: '10vw',
        height: '3.75vw',

        [theme.breakpoints.up('medium')]: {
            marginTop: '1.6vw',
        },
        [theme.breakpoints.up('tablet')]: {
            marginTop: '1.35vw',
        },
        [theme.breakpoints.up('large')]: {
            marginTop: '0.95vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            marginTop: '0.9vw',
        },
    },
    
    mapFilterDiv: {
        display: 'none',
        flexDirection: 'row',
        justifyContent: 'center',
        boxSizing: 'border-box',
        float: 'left',
        width: '91.46vw',
        maxHeight: '48.53vw',
        paddingBottom: '15px',
        marginTop: '4.26vw',
        border: '0.26vw solid #D6D6D6',
        borderRadius: '3.2vw',

        '&.active': {
            display: 'block',
        },

        [theme.breakpoints.up('medium')]: {
            width: '83.07vw',
            marginTop: '2.08vw',
            border: '0.13vw solid #D6D6D6',
            borderRadius: '1.56vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '86.45vw',
            height: '10.41vw',
            marginTop:' 1.66vw',
            border: '0.06vw solid #D6D6D6',
            borderRadius:' 0.83vw',
            '&.active': {
                display: 'flex',
            },
        },
        [theme.breakpoints.up('large')]: {
            width: '79.68vw',
            height:'7.81vw',
            marginTop:'1.25vw',
            border: '0.07vw solid #D6D6D6',
            borderRadius:' 0.93vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '79.23vw',
            height:'6.94vw',
            marginTop:'1.11vw',
            border: '0.06vw solid #D6D6D6',
            borderRadius:'0.83vw',
        },

    },

    filterFont: {
        fontFamily: 'WTGothic',
        fontWeight: 400,
        margin: 0,
        fontSize: '4.26vw',
        height: '5.33vw',
        lineHeight: '4.26vw',

        [theme.breakpoints.up('medium')]: {
            fontSize: '2.08vw',
            height: '2.60vw',
            lineHeight: '2.60vw',
        },
        [theme.breakpoints.up('tablet')]: {
            fontSize: '1.66vw',
            height: '1.56vw',
            lineHeight: '1.56vw',
        },
        [theme.breakpoints.up('large')]: {
            fontSize: '1.25vw',
            height: '1.56vw',
            lineHeight: '1.56vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            fontSize: '1.11vw',
            height: '1.38vw',
            lineHeight: '1.38vw',
        },

    },

    filterDiv: {
        width: '41.06vw',
        marginTop: '4.26vw',
        marginLeft: '2.66vw',
        float: 'left',

        '&:first-child': {
            marginLeft: '2.66vw',

            [theme.breakpoints.up('medium')]: {
                marginLeft: '1.30vw',
            },
            [theme.breakpoints.up('tablet')]: {
                marginLeft: 0,
            },
        },

        [theme.breakpoints.up('medium')]: {
            width: '39.19vw',
            marginTop: '2.08vw',
            marginLeft: '1.30vw',
            float: 'left',
        },

        [theme.breakpoints.up('tablet')]: {
            width: '17.5vw',
            marginTop: '1.66vw',
            marginLeft: '1.56vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '13.12vw',
            marginTop:'1.25vw',
            marginLeft:'1.56vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '11.66vw',
            marginTop:'1.11vw',
            marginLeft:'1.38vw',
        },
    },

    mapHeaderDivider: {
        width: '100vw',
        boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
        float: 'left',
        borderTop: 0,
        borderColor: theme.palette.darkGreyRGB,
        marginTop: '4.26vw',

        [theme.breakpoints.up('medium')]: {
            marginTop: '2.08vw',
        },
        [theme.breakpoints.up('tablet')]: {
            marginTop: '1.66vw',
        },
        [theme.breakpoints.up('large')]: {
            marginTop: '1.25vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            marginTop: '1.11vw',
        },

    }

}));