import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    midSection: {
        position: 'relative',
        backgroundColor: 'transparent',
        display: 'block',
        float: 'right',
        marginTop: '4.44vw',
        marginRight: '4.44vw',
        width: '91.11vw',

        [theme.breakpoints.up('medium')]: {
            marginTop: '3.12vw',
            marginRight: '8.2vw',
            width: '83.46vw',
        },
        [theme.breakpoints.up('tablet')]: {
            marginTop: '1.66vw',
            marginRight: '6.77vw',
            width: '86.45vw',
        },
        [theme.breakpoints.up('large')]: {
            marginRight: '5.87vw',
            marginTop: '1.25vw',
            width: '45.78vw'
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '44.86vw',
            marginTop: '1.45vw',
            marginRight: '1.87vw',
        },
    },

    subtitleFont: {
        marginTop: '1.75vw',
        fontFamily: 'WTGothic',
        fontWeight: 300,
        color: theme.palette.black,
        letterSpacing: '0.01em',
        fontSize: '5vw',
        height: '6.11vw',
        width: 'fit-content',

        [theme.breakpoints.up('medium')]: {
            fontSize: '3.38vw',
            height: '4.55vw',
            width: 'fit-content',
        },
        [theme.breakpoints.up('tablet')]: {
            fontSize: '2.7vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '42.82vw',
            fontSize: '2.03vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            marginTop: '1.66vw',
            fontSize: '1.8vw',
        },
    },

    typeButton: {
        position: 'relative',
        width: '43.33vw',
        height: '33.33vw',
        background: '#ffffff',
        border: '1px solid #d6d6d6',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 4px rgba(33, 35, 34, 0.2)',
        borderRadius: '3.33vw',
        float: 'left',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '&.active': {
            border: '4px solid #0a47ed',
            boxShadow: '0px 4px 4px rgba(10, 71, 237, 0.2)',
        },

        [theme.breakpoints.up('medium')]: {
            height: '15.49vw',
            width: '40.62vw',
            borderRadius: '1.56vw',
        },
        [theme.breakpoints.up('tablet')]: {
            height: '15.62vw',
            textAlign: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '41.97vw',
            borderRadius: '1.25vw',
        },
        [theme.breakpoints.up('large')]: {
            height: '23.51vw',
            width: '21.95vw',
            borderRadius: '0.93vw',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('extraLarge')]: {
            height: '20.9vw',
            width: '21.31vw',
        },

    },

    typeIcon: {
        margin: 0,
        width: '20vw',
        height: '20vw',


        [theme.breakpoints.up('medium')]: {
            width: '9.37vw',
            height: '9.37vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '7.5vw',
            height: '7.5vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '10vw',
            height: '10vw',
        },
        [theme.breakpoints.up('extraLarge')]: {

        },
    },

    typeText: {
        width: 'fit-content',
        textAlign: 'center',
        fontFamily: 'WTGothic',
        letterSpacing: '0.01em',
        margin: 0,
        marginTop: '0.55vw',
        fontWeight: 400,
        fontSize: '3.33vw',
        marginLeft: '0vw',

        '&.active': {
            color: theme.palette.oceanBlue,
        },

        '&.unavailable': {
            color: '#ABABAB',
        },

        [theme.breakpoints.up('medium')]: {
            margin: 0,
            fontSize: '2.34vw',
            marginLeft: '2.08vw',
        },
        [theme.breakpoints.up('tablet')]: {
            fontSize: '1.87vw',
            fontWeight: 300,
            marginLeft: '2.5vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '100%',
            marginTop: '3.12vw',
            fontSize: '1.4vw',
            marginLeft: '0vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            //Revisar
        },
    },

    filterSection: {
        display: 'block',
        position: 'relative',
        width: '91.11vw',
        borderRadius: '3.33vw',
        height: '23.88vw',
        marginTop: '8.88vw',
        backgroundColor: theme.palette.white,
        float: 'left',

        [theme.breakpoints.up('medium')]: {
            width: '40.62vw',
            borderRadius: '1.56vw',
            height: '13.02vw',
            marginTop: '4.16vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '41.97vw',
            borderRadius: '1.25vw',
            height: '11.14vw',
            marginTop: '5vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '21.95vw',
            height: 'fit-content',
            marginTop: '3.75vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            marginTop: '3.33vw',
            width: '21.31vw',
        },
    },

    second: {

        marginLeft: 0,

        [theme.breakpoints.up('medium')]: {
            marginLeft: '2.08vw',
        },
        [theme.breakpoints.up('tablet')]: {
            marginLeft: '2.5vw',
        },
        [theme.breakpoints.up('large')]: {
            marginLeft: '1.87vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            marginLeft: '2.22vw',
        },
    },

    filterText: {

        margin: 0,
        width: 'fit-content',
        height: '6.11vw',
        fontFamily: 'WTGothic',
        fontSize: '5vw',
        fontWeight: 300,
        color: theme.palette.black,
        letterSpacing: '0.01em',

        [theme.breakpoints.up('medium')]: {
            fontSize: '3.38vw',
            height: '4.55vw',
            width: 'fit-content',
        },
        [theme.breakpoints.up('tablet')]: {
            fontSize: '2.7vw',
            width: '100%',
            height: '2.56vw',
        },
        [theme.breakpoints.up('large')]: {
            fontSize: '2.03vw',
        },
        [theme.breakpoints.up('extraLarge')]: {

        },
    },

    dateInput: {

        position: 'relative',
        width: '91.11vw',
        height: '13.33vw',
        marginTop: '2.08vw',
        borderRadius: '3.33vw',

        [theme.breakpoints.up('medium')]: {
            width: '40.62vw',
            borderRadius: '1.56vw',
            height: '6.25vw',
            marginTop: '2.08vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '41.97vw',
            borderRadius: '1.25vw',
            height: '5vw',
            marginTop: '2.5vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '21.95vw',
            height: '3.75vw',
            marginTop: '1.87vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '21.31vw',
            height: '3.33vw',
        },

    },

    continueButton: {

        width: '91.11vw',
        height: '13.33vw',
        border: 0,
        backgroundColor: theme.palette.lightOrange,
        boxShadow: '0px 4px 4px rgba(33, 35, 34, 0.2)',
        fontFamily: 'WTGothic',
        fontSeight: 300,
        color: theme.palette.white,
        outline: 'none',
        marginTop: '8.88vw',
        marginBottom: '3vw',
        marginLeft: 0,
        fontSize: '5vw',
        borderRadius: '3.33vw',

        [theme.breakpoints.up('medium')]: {
            height: '6.25vw',
            width: '83.46vw',
            marginTop: '4.16vw',
            marginBottom: 0,
            marginLeft: 0,
            fontSize: '2.34vw',
            borderRadius: '1.25vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '100%',
            borderRadius: '1.25vw',
            height: '5vw',
            display: 'block',
            position: 'relative',
            marginTop: '3vw',
            marginLeft: '0vw',
            marginBottom: '2vw',
            fontSize: '1.87vw',
            float: 'left',
        },
        [theme.breakpoints.up('large')]: {
            width: '100%',
            height: '3.67vw',
            marginTop: '2.8vw',
            marginBottom: '17.73vw',
            fontSize: '1.4vw',
            borderRadius: '0.87vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            marginTop: '4vw',
            marginLeft: '0vw',
            marginBottom: '15.76vw',
            width: '100%',
            height: '3.33vw',
        },

    },

    continueButtonInactive: {
        opacity: 0.4,
    },

    pressable: {
        '&:hover': {
            cursor: 'pointer',
        }
    },

    last: {
        marginLeft: '4.44vw',

        [theme.breakpoints.up('medium')]: {
            marginLeft: '2.08vw',
        },
        [theme.breakpoints.up('tablet')]: {
            marginLeft: '2.5vw',
        },
        [theme.breakpoints.up('large')]: {
            marginLeft: '1.87vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            marginLeft: '2.22vw',
        },
    },

    optionContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    }

}))