import React, { useState, useCallback } from 'react';
import { ReactComponent as MeetingRoom } from '../../assets/images/MeetingRoom_icon.svg';
import { ReactComponent as MeetingRoomUnavailable } from '../../assets/images/MeetingRoom_Icon_Unavailable.svg';
import { ReactComponent as Chair } from '../../assets/images/chair_icon.svg';
import { useHistory } from 'react-router-dom';
import { useStyles } from './ReservationFilter.styles';

interface IProps {
  selectedDate: Date;
}

const ReservationFilter = (props: IProps) => {
  const classes = useStyles();

  const history = useHistory();
  const [type, setType] = useState(1);
  const enterMap = useCallback(() => history.push('/reservation'), [history]);
  const enterMeetReservation = useCallback(
    () => history.push('/meetingReservation'),
    [history]
  );
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <>
      <div className={classes.midSection}>
        <h2 className={classes.subtitleFont}>
          Seleccionar {type === 1 ? 'estación de trabajo' : 'sala de reuniones'}
        </h2>
        <div
          className={`${classes.typeButton} ${classes.pressable} ${
            type === 1 ? 'active' : ''
          }`}
          onClick={() => setType(1)}
        >
          <Chair className={classes.typeIcon} />
          <div className={`${classes.typeText} ${type === 1 ? 'active' : ''}`}>
            Estación de trabajo
          </div>
        </div>
        {/* <div
          className={`${classes.typeButton} ${classes.last} ${
            classes.pressable
          } ${type === 2 ? 'active' : ''}`}
          onClick={() => setType(2)}
        >
          <MeetingRoom className={classes.typeIcon} />
          <div className={`${classes.typeText} ${type === 2 ? 'active' : ''}`}>
            Sala de reuniones
          </div>
        </div> */}
        <button
          className={
            type !== 0 && props.selectedDate.valueOf() >= today.valueOf()
              ? `${classes.continueButton} ${classes.pressable}`
              : `${classes.continueButton} ${classes.continueButtonInactive}`
          }
          onClick={
            type == 1 ? enterMap : type == 2 ? enterMeetReservation : undefined
          }
          disabled={props.selectedDate.valueOf() < today.valueOf()}
        >
          Continuar
        </button>
      </div>
    </>
  );
};

export default ReservationFilter;
