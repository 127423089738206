import React, { useEffect, useState } from 'react';
import { useStyles } from './Page.styles';
import ActualReservation from '../components/ActualReservation/ActualReservation';
import NextReservationsComponent from '../components/NextReservations/NextReservations';
import ReservationHistoryComponent from '../components/ReservationHistory/ReservationHistory';
import MyReservationPageSkeleton from '../components/MyReservationPageSkeleton/MyReservationPageSkeleton';
import { gql, useLazyQuery } from '@apollo/client';
import { IReservation } from '../interfaces/IReservation';
import { IReservationData } from '../interfaces/IReservationData';
import { useOktaAuth } from '@okta/okta-react';
import dayjs from 'dayjs';
import DialogBox from '../components/DialogBox/DialogBox';

require('dayjs/locale/es');
dayjs.locale('es');

const MyReservationsPage = () => {
  const classes = useStyles();
  const [userEmail, setUserEmail] = useState('');
  const [nextReservations, setNextReservations] = useState<IReservation[]>([]);
  const [reservationsHistory, setReservationsHistory] = useState<
    IReservation[]
  >([]);
  const [todayReservation, setTodayReservation] = useState<IReservation>();
  const { authState, oktaAuth } = useOktaAuth();
  const [date, setDate] = useState(dayjs().toDate());
  const [dialogMessage, setDialogMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [DBIsSuccess, setDBIsSucces] = useState(false);

  const MonthHandler = (option: number) => {
    if (option === 1) {
      setDate(dayjs(date).add(1, 'month').toDate());
    } else {
      setDate(dayjs(date).subtract(1, 'month').toDate());
    }
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      oktaAuth.getUser().then(
        (result) => {
          if (result.email) {
            setUserEmail(result.email);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [authState, oktaAuth]);

  const nextReservationsQuery = gql`
    query ($email: String!) {
      reservations: getNextReservationsByUser(email: $email) {
        id
        date
        workstationreservation {
          tableId
          workstationId
          floorId
        }
      }
    }
  `;

  const reservationsQuery = gql`
    query ($email: String!, $firstDay: DateTime!, $lastDay: DateTime!) {
      reservations: getReservationsHistoryByUserAndDate(
        email: $email
        firstDay: $firstDay
        lastDay: $lastDay
      ) {
        date
        workstationreservation {
          tableId
          workstationId
          floorId
        }
      }
    }
  `;

  const userReservationQuery = gql`
    query ($dateParam: String!, $email: String!) {
      reservations: getReservationByDateAndUser(
        dateParam: $dateParam
        email: $email
      ) {
        id
        date
        status
        workstationreservation {
          workstationId
          tableId
          floorId
        }
      }
    }
  `;

  const [getReservationsHistoryData, reservationsHistoryData] =
    useLazyQuery<IReservationData>(reservationsQuery, {
      variables: {
        email: userEmail,
        firstDay: dayjs(date).startOf('month').format('YYYY/MM/DD'),
        lastDay: dayjs(date).endOf('month').format('YYYY/MM/DD'),
      },
    });

  const [getNextReservationsData, nextReservationsData] =
    useLazyQuery<IReservationData>(nextReservationsQuery, {
      variables: { email: userEmail },
    });

  const [getUserReservationData, userReservationsData] =
    useLazyQuery<IReservationData>(userReservationQuery, {
      fetchPolicy: 'network-only',
      variables: {
        dateParam: dayjs(date).format('YYYY/MM/DD'),
        email: userEmail,
      },
    });

  useEffect(() => {
    if (reservationsHistoryData.data) {
      setReservationsHistory(reservationsHistoryData.data.reservations);
    }
  }, [reservationsHistoryData]);

  useEffect(() => {
    if (userEmail !== '') {
      getNextReservationsData();
      getUserReservationData();
    }
  }, [userEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userReservationsData.data) {
      setTodayReservation(userReservationsData.data.reservations[0]);
    }
  }, [userReservationsData]);
  useEffect(() => {
    getReservationsHistoryData();
  }, [date, userEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (nextReservationsData.data) {
      setNextReservations(nextReservationsData.data.reservations);
    }
  }, [nextReservationsData]);

  if (reservationsHistoryData.loading) {
    return <MyReservationPageSkeleton />;
  }

  return (
    <>
      <div className="full">
        <div className={classes.mainTitleDivSlim}>
          <h1 className={classes.myReservationsTitleFont}>Mis Reservaciones</h1>
        </div>
        <div className={classes.myReservationCenter}>
          <DialogBox
            message={dialogMessage}
            isSuccess={DBIsSuccess}
            show={showErrorMessage}
            hide={setShowErrorMessage}
          ></DialogBox>
          <ActualReservation
            data={todayReservation}
            email={userEmail}
            date={date}
            dialogMessage={setDialogMessage}
            showMessage={setShowErrorMessage}
            success={setDBIsSucces}
          />
          <NextReservationsComponent
            data={nextReservations}
            email={userEmail}
            date={date}
            dialogMessage={setDialogMessage}
            showMessage={setShowErrorMessage}
            success={setDBIsSucces}
          />
          <ReservationHistoryComponent
            date={date}
            updateDate={MonthHandler}
            data={reservationsHistory}
          />
        </div>
      </div>
    </>
  );
};

export default MyReservationsPage;
