import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

    mainTitleDiv: {
        display: 'block',
        boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
        height: '23.73vw',
        width: '100vw',
        marginLeft: 0,
        marginTop: '30px',

        [theme.breakpoints.up('medium')]: {
            marginTop: '80px',
            height: '12.10vw',
            width: '100vw',
        },
        [theme.breakpoints.up('tablet')]: {
            display: 'flex',
            flexDirection: 'row',
            marginTop:'80px',
            width: '100vw',
            height: '7.08vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '100vw',
            height: '5.23vw',
            marginTop: '6.25vw',
            marginLeft: 'unset',
        },
        [theme.breakpoints.up('extraLarge')]: {
            marginTop: '5.55vw',
            width: '83.68vw',
            height: '4.65vw',
            marginLeft: '16.30vw',
        },
    },

    container: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1.25vw',
        width: '91.11vw',
        height: '35vw',
        borderRadius: '3.33vw',
        paddingBottom: "6.400vw",

        [theme.breakpoints.up('medium')]: {
            width: '83.46vw',
            borderRadius: '0.87vw',
            paddingBottom: "3.125vw",
            height: '22.5vw',

        },
        [theme.breakpoints.up('tablet')]: {
            width: '86.45vw',
            paddingBottom: "2.500vw",
            height: '25vw',

        },
        [theme.breakpoints.up('large')]: {
            width: '89.141vw',
            paddingBottom: "1.875vw",  
            height: '15vw',

        },
        [theme.breakpoints.up('extraLarge')]: {
            marginTop:'1.45vw',
            width: '79.236vw',
            paddingBottom: "1.667vw",
        },
    },

   
    containerDiv:{
        marginLeft:'4.26vw',

        [theme.breakpoints.up('medium')]: {
            marginLeft: '8.46vw', 
        },
        [theme.breakpoints.up('tablet')]: {
            marginLeft:'6.17vw',
        },
        [theme.breakpoints.up('large')]: {
            marginLeft: '10.15vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            marginLeft: '18.61vw',
        },

    },

    MapHeader: {
        height: '4vw',
        width: '91.46vw',
        marginTop:'4.26vw',

        [theme.breakpoints.up('medium')]: {
            width: '83.07vw',
            marginTop: '2.08vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '46.45vw',
            marginTop:'1.66vw',
            height: '2vw',
        },
        [theme.breakpoints.up('large')]: {
            marginTop: '1.25vw',
            width: '39.68vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '30.23vw',
            marginTop:'1.11vw',
        },

    },
}))
