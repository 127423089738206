import React from 'react';
// Import react-circular-progressbar module and styles
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useStyles } from './CircularProgress.styles'
import 'react-circular-progressbar/dist/styles.css';
import { Skeleton } from '@material-ui/lab';

interface IProps {
    type: number;
    percentageOcuppied: number;
    maxCapacity: number;
    userType: string;
}


const CircularProgressComponent = (props: IProps) => {
    const classes = useStyles();

    if (props.type === 1) {
        if (props.percentageOcuppied !== -1) {
            return (
                <div className={props.userType === 'admin' ? classes.circularContainerAdmin : classes.circularContainer}>
                    <CircularProgressbar
                        className={classes.circularNumberStyle}
                        value={props.percentageOcuppied}
                        maxValue={props.maxCapacity}
                        text={`${props.percentageOcuppied}/${props.maxCapacity}`}
                        strokeWidth={9}
                        styles={buildStyles({
                            textColor: "black",
                            pathColor: "#00D1D9",
                            trailColor: "#c9f2e6",
                            textSize: '16px',
                        })}
                    />
                    <p className={props.userType === 'admin' ? classes.circularSubtitleAdmin : classes.circularSubtitle}>Estaciones de trabajo</p>

                </div>
            )
        } else {
            return (
                <div className={props.userType === 'admin' ? classes.circularContainerAdmin : classes.circularContainer}>
                    <div className={classes.circularSkeletonContainer}>
                        <Skeleton className={classes.circularSkeleton} variant="circle" width="100%" height="100%" />
                    </div>
                    <p className={props.userType === 'admin' ? classes.circularSubtitleAdmin : classes.circularSubtitle}>Estaciones de trabajo</p>
                </div>
            )
        }
    } else {
        if (props.percentageOcuppied !== -1) {
            return (
                <div className={classes.circularContainer}>
                    <CircularProgressbar
                        className={classes.circularNumberStyle}
                        value={props.percentageOcuppied}
                        maxValue={props.maxCapacity}
                        text={`${props.percentageOcuppied}/${props.maxCapacity}`}
                        strokeWidth={9}
                        styles={buildStyles({
                            textColor: "black",
                            pathColor: "#E661B2",
                            trailColor: "#f5d5e0",
                            textSize: '16px'
                        })}
                    />
                    <p className={classes.circularSubtitle}>Salas de reunión</p>
                </div>
            )
        } else {
            return (
                <div className={classes.circularContainer}>
                    <div className={classes.circularSkeletonContainer}>
                        <Skeleton className={classes.circularSkeleton} variant="circle" width="100%" height="100%" />
                    </div>
                    <p className={props.userType === 'admin' ? classes.circularSubtitleAdmin : classes.circularSubtitle}>Estaciones de trabajo</p>
                </div>
            )
        }
    }
}




export default CircularProgressComponent;