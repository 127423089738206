import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

    progressBarContainer: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #D6D6D6',
        boxSizing: 'border-box',
        boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
        width: '91.11vw',
        height: '52.133vw',
        marginTop: '3.533vw',
        borderRadius: '3.200vw',
        padding: '3vw',

        [theme.breakpoints.up('medium')]: {
            width: '88.46vw',
            height: '50vw',
            marginTop: '4.167vw',
            borderRadius: '1.563vw',
            padding: '3.125vw 2vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '41.979vw',
            height: '40.521vw',
            marginTop: '3.333vw',
            borderRadius: '1.250vw',
            padding: '2.500vw 3.333vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '21.914vw',
            height: '30.109vw',
            marginTop: '1.875vw',
            borderRadius: '0.938vw',
            padding: '1.875vw 2.500vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '21.632vw',
            height: '28.542vw',
            marginTop: '1.667vw',
            borderRadius: '0.833vw',
            padding: '1.667vw 2.222vw',
        },
        [theme.breakpoints.up(2500)]: {
            width: '21.632vw',
            height: '19vw',
        },
    },
    subtitleFont: {
        fontFamily: 'WTGothic',
        letterSpacing: '0.01em',
        fontSize: '4.800vw',

        [theme.breakpoints.up('medium')]: {
            fontSize: '2.344vw',
            marginTop: '1vw',
        },
        [theme.breakpoints.up('tablet')]: {
            fontSize: '1.875vw',
        },
        [theme.breakpoints.up('large')]: {
            fontSize: '1.406vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            fontSize: '1.250vw',
        },
        [theme.breakpoints.up(2500)]: {
            fontSize: '1.05vw',
        },
    },

    CircularContainerFrame: {
        display: 'flex',
        marginTop: '4.5vw',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('medium')]: {
            width: '100%',
            marginTop: '5vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '100%',
            height: '30.12vw',
            marginTop: '-1vw',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('large')]: {
            width: '100%',
            height: '14.12vw',
            marginRight: '0vw',
            marginTop: '1.5vw',
            display: 'flex',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('extraLarge')]: {
            height: '11.87vw', 
        },
    },
}))