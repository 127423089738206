import React from 'react';
import Switch from '@material-ui/core/Switch';
import availableDot from '../../assets/images/available_dot.svg';
import unavailableDot from '../../assets/images/unavailable_dot.svg';
import occupiedDot from '../../assets/images/occupied_dot.svg';
import { IWorkstation } from '../../interfaces/IWorkstation';
import { IReservation } from '../../interfaces/IReservation';
import { IFloor } from '../../interfaces/IFloor';
import { useStyles } from './WorkstationsList.styles';

interface IProps {
  workstations: IWorkstation[];
  reservations: IReservation[];
  updateWorkstation: (
    workstationId: string,
    tableId: string,
    floorId: number,
    status: boolean
  ) => void;
  selectedFloor: IFloor | undefined;
}

const WorkstationsListComponent = (props: IProps) => {
  const classes = useStyles();

  const searchReservations = (
    workstationId: string,
    tableId: string,
    floorId: number
  ) => {
    const totalWorkstations = props.reservations.reduce(
      (total, reservation) => {
        if (
          reservation.workstationreservation.floorId === floorId &&
          reservation.workstationreservation.tableId === tableId &&
          reservation.workstationreservation.workstationId === workstationId
        ) {
          return total + 1;
        } else {
          return total;
        }
      },
      0
    );
    return totalWorkstations;
  };

  const changeChecked = (
    workstationId: string,
    tableId: string,
    floorId: number,
    status: boolean
  ) => {
    props.updateWorkstation(workstationId, tableId, floorId, !status);
  };

  return (
    <>
      {props.workstations.map((item, index) => {
        const stationId = item.floorId + item.tableId + item.id;
        const totalReservations = searchReservations(
          item.id,
          item.tableId,
          item.floorId
        );
        return item.tableId.length === 1 ? (
          <div className={classes.stationInfoContainer} key={stationId}>
            <div
              className={`${classes.infoFont} ${classes.firstSubtitle} stationId`}
            >
              Estación {stationId}
            </div>
            <div className={`${classes.infoFont} ${classes.secondSubtitle}`}>
              <img
                src={
                  totalReservations > 0 && !item.status
                    ? unavailableDot
                    : totalReservations > 0
                    ? occupiedDot
                    : item.status
                    ? availableDot
                    : unavailableDot
                }
                alt="available"
              ></img>
              <div className={classes.firstSubtitle}>
                {totalReservations > 0 && !item.status
                  ? 'No disponible'
                  : totalReservations > 0
                  ? 'Ocupada'
                  : item.status
                  ? 'Disponible'
                  : 'No disponible'}
              </div>
            </div>
            <div className={`${classes.infoFont} ${classes.secondSubtitle}`}>
              {props.selectedFloor?.floorName}
            </div>
            <div
              className={`${classes.infoFont} ${classes.secondSubtitle} third`}
            >
              {totalReservations}{' '}
              {totalReservations === 1 ? 'Reservación' : 'Reservaciones'}
            </div>
            <div
              className={`${classes.infoFont} ${classes.secondSubtitle} third`}
            >
              Habilitada
              <Switch
                classes={{
                  track: classes.switch_track,
                  switchBase: classes.switch_base,
                  colorSecondary: classes.switch_primary,
                  thumb: classes.thumb,
                }}
                checked={item.status}
                onChange={(e) => {
                  changeChecked(
                    item.id,
                    item.tableId,
                    item.floorId,
                    item.status
                  );
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        );
      })}
    </>
  );
};

export default WorkstationsListComponent;
