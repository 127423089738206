import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1.25vw',
    filter: 'drop-shadow(0px 0.31vw 0.31vw rgba(33, 35, 34, 0.2))',
    border: '1px solid #d6d6d6',
    boxSizing: 'border-box',
    background: '#ffffff',
    width: '91.11vw',
    borderRadius: '3.33vw',
    paddingBottom: '6.400vw',

    [theme.breakpoints.up('medium')]: {
      width: '90vw',
      borderRadius: '0.87vw',
      paddingBottom: '3.125vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '86.45vw',
      paddingBottom: '2.500vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '89.141vw',
      paddingBottom: '1.875vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '1.45vw',
      width: '79.236vw',
      paddingBottom: '1.667vw',
    },
  },

  containerTitle: {
    display: 'flex',
    fontFamily: 'WTGothic',
    margin: 0,
    fontWeight: 300,
    letterSpacing: '0.01em',
    marginLeft: '4.44vw',
    marginTop: '4.44vw',
    width: '81.94vw',
    fontSize: '4.800vw',

    [theme.breakpoints.up('medium')]: {
      marginLeft: '2.6vw',
      marginTop: '2.08vw',
      width: '37.89vw',
      fontSize: '2.344vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '2.5vw',
      marginTop: '2.5vw',
      width: '39.58vw',
      fontSize: '1.875vw',
    },
    [theme.breakpoints.up('large')]: {
      marginTop: '1.75vw',
      marginLeft: '1.87vw',
      fontSize: '1.406vw',
      width: '90%',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '1.66vw',
      fontSize: '1.250vw',
    },
  },

  subtitlesDiv: {
    display: 'flex',
    width: 'max-content',
    marginLeft: '1vw',
    height: '4.5vw',
    marginTop: '6.400vw',

    [theme.breakpoints.up('medium')]: {
      marginLeft: '6.250vw',
      height: '1.823vw',
      marginTop: '3.125vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '5vw',
      height: '1.458vw',
      marginTop: '2.500vw',
    },
    [theme.breakpoints.up('large')]: {
      marginLeft: '3.750vw',
      height: '1.094vw',
      marginTop: '1.875vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginLeft: '3.333vw',
      height: '0.972vw',
      marginTop: '1.667vw',
    },
  },

  subtitleFont: {
    fontFamily: 'WTGothic',
    fontWeight: 300,
    letterSpacing: '0.01em',
    color: theme.palette.grey2,
    fontSize: '3.200vw',
    textAlign: 'center',

    '&.first': {
      width: '29vw',

      [theme.breakpoints.up('medium')]: {
        width: '18.490vw',
      },
      [theme.breakpoints.up('tablet')]: {
        width: '14.792vw',
      },
      [theme.breakpoints.up('large')]: {
        width: '11.094vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        width: '9.861vw',
      },
    },
    '&.second': {
      width: '28vw',

      [theme.breakpoints.up('medium')]: {
        width: '15.365vw',
      },
      [theme.breakpoints.up('tablet')]: {
        width: '12.292vw',
      },
      [theme.breakpoints.up('large')]: {
        width: '9.219vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        width: '8.194vw',
      },
    },
    '&.third': {
      width: '28.5vw',

      [theme.breakpoints.up('medium')]: {
        width: '18.620vw',
      },
      [theme.breakpoints.up('tablet')]: {
        width: '14.896vw',
      },
      [theme.breakpoints.up('large')]: {
        width: '11.172vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        width: '9.931vw',
      },
    },

    [theme.breakpoints.up('medium')]: {
      fontSize: '1.563vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.250vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '0.938vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '0.833vw',
    },
  },

  reservationInfoDiv: {
    display: 'flex',
    boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
    border: '1px solid #d6d6d6',
    boxSizing: 'border-box',
    alignItems: 'center',
    width: '88vw',
    borderRadius: '3.200vw',
    marginLeft: '1.5vw',
    marginTop: '2.5vw',

    '&:corner': {
      padding: '0rem',
    },

    [theme.breakpoints.up('medium')]: {
      columnGap: '0rem',
      width: '83vw',
      borderRadius: '1.563vw',
      marginLeft: '3.125vw',
      marginTop: '2.083vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '82vw',
      borderRadius: '1.250vw',
      marginLeft: '2.500vw',
      marginTop: '1.667vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '85.391vw',
      borderRadius: '0.938vw',
      marginLeft: '1.875vw',
      marginTop: '1.250vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '75.903vw',
      borderRadius: '0.833vw',
      marginLeft: '1.667vw',
      marginTop: '1.111vw',
    },
  },

  firstDiv: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'stretch',
  },

  secondDiv: {
    display: 'flex',
    flexGrow: 20,
    padding: '1.5rem 2rem',
    paddingLeft: '0rem',
    flexWrap: 'wrap',
    rowGap: '1rem',
    columnGap: '1rem',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.up('medium')]: {
      flewWrap: 'nowrap',
      columnGap: '0rem',
    },
    [theme.breakpoints.up('large')]: {
      marginLeft: '-2.5rem',
    },
  },

  break: {
    flexBasis: '100%',
    height: 0,

    [theme.breakpoints.up('medium')]: {
      display: 'none',
    },
  },

  cornerDiv: {
    width: '0.7rem',
    height: '11rem',
    backgroundColor: theme.palette.oceanBlue,
    borderRadius: '3.200vw 0vw 0vw 3.200vw',
    alignSelf: 'stretch',

    [theme.breakpoints.up('medium')]: {
      height: '4.4rem',
      borderRadius: '1.563vw 0vw 0vw 1.563vw',
    },
    [theme.breakpoints.up('tablet')]: {
      borderRadius: '1.250vw 0vw 0vw 1.250vw',
    },
    [theme.breakpoints.up('large')]: {
      borderRadius: '0.938vw 0vw 0vw 0.938vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      borderRadius: '0.833vw 0vw 0vw 0.833vw',
    },
  },

  reservationInfoFont: {
    display: 'flex',
    flexGrow: 1,
    fontFamily: 'WTGothic',
    fontWeight: 300,
    letterSpacing: '0.01em',
    textAlign: 'center',
    fontSize: '1rem',
    width: '1rem',
    justifyContent: 'center',

    '&.first': {
      color: theme.palette.grey2,
    },
    [theme.breakpoints.up('medium')]: {
      justifyContent: 'center',
    },
  },

  cancelButton: {
    display: 'flex',
    flexGrow: 5,
    color: theme.palette.oceanBlue,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontFamily: 'WTGothic',
    fontWeight: 300,
    letterSpacing: '0.01em',
    fontSize: '1rem',
    justifyContent: 'center',

    [theme.breakpoints.up('medium')]: {
      marginLeft: '0rem',
      justifyContent: 'flex-end',
      flexGrow: 1,
    },
    [theme.breakpoints.up('tablet')]: {
      flexGrow: 2,
    },
    [theme.breakpoints.up('large')]: {
      flexGrow: 4,
    },
    [theme.breakpoints.up('extraLarge')]: {
      flexGrow: 5,
    },
  },

  backDrop: {
    backdropFilter: 'blur(2px)',
    backgroundColor: theme.palette.whiteRGB,
    width: '100vw',
    height: '80%',
    marginTop: '38vw',

    [theme.breakpoints.up('medium')]: {
      height: '90%',
      marginTop: '23vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginTop: '15vw',
    },
    [theme.breakpoints.up('large')]: {
      height: '90%',
      marginTop: '6.5vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '11vw',
      width: '85.5vw',
      marginLeft: '16.3vw',
    },
  },

  cancelReservationDialogBox: {
    '& .MuiPaper-root': {
      width: '87.467vw',
      height: '35%',
      marginLeft: '4vw',

      [theme.breakpoints.up('medium')]: {
        width: '83.333vw',
        marginLeft: '4vw',
        height: '25%',
      },
      [theme.breakpoints.up('tablet')]: {
        width: '56.250vw',
        marginLeft: '3vw',
      },
      [theme.breakpoints.up('large')]: {
        width: '39.375vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        width: '35vw',
        minHeight: '16vw',
      },
    },
  },

  dialogContent: {
    maxHeight: '83vw',
  },

  dialogTitle: {
    '& .MuiTypography-h6': {
      fontFamily: 'WTGothic',
      fontSize: '4.800vw',
      fontWeight: 400,

      [theme.breakpoints.up('medium')]: {
        fontSize: '2.344vw',
      },
      [theme.breakpoints.up('tablet')]: {
        fontSize: '1.875vw',
      },
      [theme.breakpoints.up('large')]: {
        fontSize: '1.406vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        fontSize: '1.250vw',
      },
    },
  },

  dialogSubtitle: {
    fontFamily: 'WTGothic',
    fontSize: '4.800vw',
    fontWeight: 300,

    [theme.breakpoints.up('medium')]: {
      fontSize: '2.344vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.406vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.250vw',
    },
  },

  dialogButtons: {
    fontFamily: 'WTGothic',
    fontSize: '4.800vw',
    height: '12.800vw',
    width: '37.333vw',
    borderRadius: '12px',
    padding: '2vw',
    textTransform: 'capitalize',

    [theme.breakpoints.up('medium')]: {
      height: '6.250vw',
      width: '27.604vw',
      fontSize: '2.344vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875vw',
      height: '5vw',
      width: '22.083vw',
    },
    [theme.breakpoints.up('large')]: {
      height: '3.750vw',
      width: '16.563vw',
      fontSize: '1.406vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.250vw',
      height: '3.333vw',
      width: '14.722vw',
    },
  },

  confirmButton: {
    backgroundColor: theme.palette.lightOrange,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.lightOrange,
      color: theme.palette.white,
    },
  },

  cancelarButton: {
    backgroundColor: theme.palette.white,
    color: theme.palette.grey2,
    border: '1px solid #D6D6D6',
    boxSizing: 'border-box',
    borderRadius: '12px',
  },

  dialogButtonsContainer: {
    justifyContent: 'center',
    paddingBottom: '2vw',
  },
}));
