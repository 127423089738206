import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import leftArrow from '../assets/images/arrow-left.svg';
import rightChevron from '../assets/images/chevron-right.svg';
import MeetingReservationHeader from '../components/MeetingReservationHeader/MeetingReservationHeader';
import MapPageSkeleton from '../components/MapPageSkeleton/MapPageSkeleton';
import { useStyles } from './Page.styles';
import { IMapPageData } from '../interfaces/IMapPage';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { IFloor } from '../interfaces/IFloor';
import dayjs from 'dayjs';
import { IReservationData } from '../interfaces/IReservationData';
import { IReservation } from '../interfaces/IReservation';
import { ITable } from '../interfaces/ITable';
import DialogBox from '../components/DialogBox/DialogBox';
import { getLabel } from '../helpers/labels';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useOktaAuth } from '@okta/okta-react';

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

interface IProps {
  selectedDate: Date;
  selectedFloor: number;
  availableFloors: number[];
}

const MeetingReservationPage = (props: IProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();

  const [stations, setStations] = useState<ITable[]>([]);
  const [selectedDate, setSelectedDate] = useState(props.selectedDate);
  const [selectedFloor, setSelectedFloor] = useState<IFloor>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [reservationsUpdated, setReservationsUpdated] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [DBIsSuccess, setDBIsSucces] = useState(false);

  const defaultFloor = props.selectedFloor;

  useEffect(() => {
    if (authState?.isAuthenticated) {
      oktaAuth.getUser().then(
        (result) => {
          if (result.email && result.name) {
            setUserEmail(result.email);
            setUserName(result.name);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [authState, oktaAuth]);

  const floorsQuery = gql`
    query {
      floors: getAllFloors {
        id
        floorName
        floorCapacity
        acceptedCapacity
        tables {
          id
          floorId
          workstations {
            id
            floorId
            tableId
            status
            areaId
          }
        }
      }
    }
  `;

  const reservationsQuery = gql`
    query ($dateParam: String!, $pFloorId: Float!, $pAreaId: Float!) {
      reservations: getReservationByDateAndFloorAndArea(
        dateParam: $dateParam
        pFloorId: $pFloorId
        pAreaId: $pAreaId
      ) {
        id
        userEmail
        date
        status
        workstationreservation {
          reservationId
          workstationId
          tableId
          floorId
          areaId
          status
        }
      }
    }
  `;

  const userReservationQuery = gql`
    query ($dateParam: String!, $email: String!) {
      reservations: getReservationByDateAndUser(
        dateParam: $dateParam
        email: $email
      ) {
        id
        userEmail
        date
        status
        workstationreservation {
          reservationId
          workstationId
          tableId
          floorId
          areaId
          status
        }
      }
    }
  `;

  const { loading, error, data } = useQuery<IMapPageData>(floorsQuery, {
    pollInterval: 3000,
  });

  const selectFloor = (floorId: number) => {
    if (data) {
      setSelectedFloor(data.floors.find((floor) => floor.id === floorId));
    }
  };

  const [getReservationsData, reservationsData] =
    useLazyQuery<IReservationData>(reservationsQuery, {
      variables: {
        dateParam:
          selectedDate.getFullYear().toString() +
          '/' +
          (selectedDate.getMonth() + 1).toString() +
          '/' +
          selectedDate.getDate().toString(),
        pFloorId: selectedFloor?.id,
        pAreaId: 1,
      },
      pollInterval: 1000,
    });

  const [getUserReservationData, userReservationsData] =
    useLazyQuery<IReservationData>(userReservationQuery, {
      variables: {
        dateParam:
          selectedDate.getFullYear().toString() +
          '/' +
          (selectedDate.getMonth() + 1).toString() +
          '/' +
          selectedDate.getDate().toString(),
        email: userEmail,
      },
      pollInterval: 1000,
    });

  useEffect(() => {
    getUserReservationData();
  }, [selectedDate, getUserReservationData]);

  useEffect(() => {
    if (reservationsData.previousData) {
      setReservationsUpdated(true);
    }
  }, [reservationsData.previousData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    data &&
      data.floors &&
      setSelectedFloor(data.floors.find((floor) => floor.id === defaultFloor));
  }, [data, defaultFloor]);

  const changeRoomsStatus = () => {

  };

  useEffect(changeRoomsStatus, [
    selectedFloor,
    reservationsData.data,
    userEmail
  ]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedFloor !== undefined) {
      getReservationsData();
    }
  }, [selectedDate, selectedFloor, getReservationsData]);

  if (loading) return <MapPageSkeleton />;
  if (error) return <p>Error :(</p>;

  return (
    <>
      <div className="full">
        <div className={classes.mainTitleDiv}>
          <button className={classes.backButton} onClick={history.goBack}>
            <img
              src={leftArrow}
              className={classes.backIcon}
              alt="return button"
            ></img>
          </button>
          <h1 className={`${classes.titleFont} ${classes.sidedTitle}`}>
            Nueva Reservación
          </h1>
          <div className={classes.navigationContainer}>
            <h3 className={`${classes.navigationFont} actual`}>
              Elegir sala de reuniones
            </h3>
          </div>
        </div>
        <DialogBox
          message={dialogMessage}
          isSuccess={DBIsSuccess}
          show={showErrorMessage}
          hide={setShowErrorMessage}
        ></DialogBox>
        <MeetingReservationHeader
          selectFloor={selectFloor}
          date={selectedDate}
          floors={data?.floors}
          selectedFloor={selectedFloor}
          handleClick={setSelectedDate}
          availableFloors={props.availableFloors}
        ></MeetingReservationHeader>
      </div>
    </>
  );
};

export default MeetingReservationPage;
