import React, { useEffect, useState } from 'react';
import leftArrow from '../../assets/images/leftArrowSidebarCalendar.svg';
import rightArrow from '../../assets/images/rightArrowSidebarCalendar.svg';
import dayjs from 'dayjs';
import { useStyles } from './SidebarCalendar.styles';
const weekday = require('dayjs/plugin/weekday');
const weekOfYear = require('dayjs/plugin/weekOfYear');

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

interface IProps {
  updateSelectedDate: (date: Date) => void;
}

const SidebarCalendarComponent = (props: IProps) => {
  const classes = useStyles();
  const { updateSelectedDate } = props;
  const MONTHS = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  const [currentYearState,setCurrentYearState] =  useState(
    dayjs().day() === 5
      ? dayjs().add(3, 'day').format('YYYY')
      : dayjs().day() === 6
      ? dayjs().add(2, 'day').format('YYYY')
      : dayjs().add(1, 'day').format('YYYY')
    );
  const [currentMonthState, setCurrentMonthState] = useState(
    dayjs().day() === 5
      ? parseInt(dayjs().add(3, 'day').format('M'))
      : dayjs().day() === 6
      ? parseInt(dayjs().add(2, 'day').format('M'))
      : parseInt(dayjs().add(1, 'day').format('M'))
  );
  const [selectedDayState, setSelectedDayState] = useState(
    dayjs().day() === 5
      ? parseInt(dayjs().add(3, 'day').format('D'))
      : dayjs().day() === 6
      ? parseInt(dayjs().add(2, 'day').format('D'))
      : parseInt(dayjs().add(1, 'day').format('D'))
  );
  const [currentWeeksMonthState, setCurrenMonthWeeksState] = useState(() =>
    monthCalc(currentMonthState)
  );
  const generateDate = (day: number, month: number, year: string) => {
    let new_day: string = day.toString();
    let new_month: string = month.toString();
    if (day < 10) {
      new_day = '0' + day.toString();
    }
    if (month < 10) {
      new_month = '0' + month.toString();
    }
    return (
      year.toString() + '-' + new_month + '-' + new_day + 'T00:00:00.000-06:00'
    );
  };
  const getCurrentWeekNumber = (currentDay: number) => {
    for (let numberOfWeek = 0; numberOfWeek < currentWeeksMonthState.length; numberOfWeek++) {
      for (let dayOfWeek = 0; dayOfWeek < currentWeeksMonthState[numberOfWeek].length; dayOfWeek++) {
        const dayOfMonth = currentWeeksMonthState[numberOfWeek][dayOfWeek].day;
        if (
          (currentDay === dayOfMonth && currentWeeksMonthState[numberOfWeek][dayOfWeek].isCurrent) ||
          (dayOfWeek === 4 && dayOfMonth < currentDay && dayOfMonth + 2 >= currentDay)
        ) {
          return numberOfWeek;
        }
      }
    }
    return 0;
  };
  const [currentWeekState, setCurrentWeekState] = useState(
    getCurrentWeekNumber(selectedDayState)
  );
  const [dateState, setDateState] = useState(
    generateDate(selectedDayState, currentMonthState, currentYearState)
  );

  const PREV_MONTH = 0;
  const NEXT_WEEK = 1;

  const MonthHandler = (option: number) => {
    if (option === PREV_MONTH) {
      if (currentMonthState > 1) {
        setCurrentMonthState(currentMonthState - 1);
        setCurrenMonthWeeksState(monthCalc(currentMonthState - 1));
        setCurrentWeekState(0);
      } else {
        setCurrentYearState(+currentYearState - 1 + "");
        setCurrentMonthState(12);
      }
    } else {
      if (currentMonthState < 12) {
        setCurrentMonthState(currentMonthState + 1);
        setCurrenMonthWeeksState(monthCalc(currentMonthState + 1));
        setCurrentWeekState(0);
      } else {
        setCurrentYearState(+currentYearState + 1 + "");
        setCurrentMonthState(1);
      }
    }
  };

  const currentWeekHandler = (option: number) => {
    if (option === NEXT_WEEK) {
      let lenWeeks = currentWeeksMonthState.length;
      if (currentWeekState + 1 >= lenWeeks) {
        MonthHandler(1);
        setCurrenMonthWeeksState(monthCalc(currentMonthState + 1));
        setCurrentWeekState(0);
      } else {
        setCurrentWeekState(currentWeekState + 1);
      }
    } else {
      if (currentWeekState - 1 < 0) {
        MonthHandler(0);
        let weeks = monthCalc(currentMonthState - 1);
        setCurrenMonthWeeksState(weeks);
        let lenWeeks = weeks.length - 1;
        setCurrentWeekState(lenWeeks);
      } else {
        setCurrentWeekState(currentWeekState - 1);
      }
    }
  };

  useEffect(() => {
    updateSelectedDate(new Date(dateState));
  }, [dateState, updateSelectedDate]);

  const selectDate = (day: number) => {
    setSelectedDayState(day);
    setDateState(generateDate(day, currentMonthState, currentYearState));
  };

  const monthWeekDays = currentWeeksMonthState[currentWeekState];
  return (
    <div className={classes.daySelectionContainer}>
      <div className={classes.monthContainer}>
        <h2 className={classes.monthTitleFormat}>
          {MONTHS[currentMonthState - 1]}
        </h2>
        <div
          className={`${classes.arrowContainer} left`}
          onClick={() => MonthHandler(0)}
        >
          <img
            className={classes.arrowSrc}
            src={leftArrow}
            alt="left arrow month"
          />
        </div>
        <div
          className={`${classes.arrowContainer} right`}
          onClick={() => MonthHandler(1)}
        >
          <img
            className={classes.arrowSrc}
            src={rightArrow}
            alt="right arrow month"
          />
        </div>
      </div>
      <div className={classes.daysContainer}>
        <div
          className={classes.fitContainerLeft}
          onClick={() => currentWeekHandler(0)}
        >
          <img
            className={classes.daysArrow}
            src={leftArrow}
            alt="left arrow week"
          />
        </div>
        <div className={classes.mediumSection}>
          <div className={classes.days}>
            <p className={`${classes.datesText} slot1`}>lun</p>
            <p className={`${classes.datesText} slot2`}>mar</p>
            <p className={`${classes.datesText} slot3`}>mier</p>
            <p className={`${classes.datesText} slot4`}>jue</p>
            <p className={`${classes.datesText} slot5`}>vie</p>
          </div>
          <div className={classes.datesContainer}>
            <p
              className={`${classes.date} 
              ${
                currentWeeksMonthState[currentWeekState][0].isCurrent
                  ? ''
                  : classes.dateDisable
              } slot1 
              ${
                dateState ===
                generateDate(
                  monthWeekDays[0].day,
                  currentMonthState,
                  currentYearState
                ) && currentWeeksMonthState[currentWeekState][0].isCurrent
                  ? 'selected'
                  : ''
              }`}
              onClick={
                currentWeeksMonthState[currentWeekState][0].isCurrent
                  ? () => selectDate(monthWeekDays[0].day)
                  : () => {}
              }
            >
              {currentWeeksMonthState[currentWeekState][0].day}
            </p>
            <p
              className={`${classes.date}
              ${
                currentWeeksMonthState[currentWeekState][1].isCurrent
                  ? ''
                  : classes.dateDisable
              } slot2 
              ${
                dateState ===
                generateDate(
                  monthWeekDays[1].day,
                  currentMonthState,
                  currentYearState
                ) && currentWeeksMonthState[currentWeekState][1].isCurrent
                  ? 'selected'
                  : ''
              }`}
              onClick={
                currentWeeksMonthState[currentWeekState][1].isCurrent
                  ? () => selectDate(monthWeekDays[1].day)
                  : () => {}
              }
            >
              {currentWeeksMonthState[currentWeekState][1].day}
            </p>
            <p
              className={`${classes.date}
              ${
                currentWeeksMonthState[currentWeekState][2].isCurrent
                  ? ''
                  : classes.dateDisable
              } slot3 
              ${
                dateState ===
                generateDate(
                  monthWeekDays[2].day,
                  currentMonthState,
                  currentYearState
                ) && currentWeeksMonthState[currentWeekState][2].isCurrent
                  ? 'selected'
                  : ''
              }`}
              onClick={
                currentWeeksMonthState[currentWeekState][2].isCurrent
                  ? () => selectDate(monthWeekDays[2].day)
                  : () => {}
              }
            >
              {currentWeeksMonthState[currentWeekState][2].day}
            </p>
            <p
              className={`${classes.date}
              ${
                currentWeeksMonthState[currentWeekState][3].isCurrent
                  ? ''
                  : classes.dateDisable
              } slot4 
              ${
                dateState ===
                generateDate(
                  monthWeekDays[3].day,
                  currentMonthState,
                  currentYearState
                ) && currentWeeksMonthState[currentWeekState][3].isCurrent
                  ? 'selected'
                  : ''
              }`}
              onClick={
                currentWeeksMonthState[currentWeekState][3].isCurrent
                  ? () => selectDate(monthWeekDays[3].day)
                  : () => {}
              }
            >
              {currentWeeksMonthState[currentWeekState][3].day}
            </p>
            <p
              className={`${classes.date}
              ${
                currentWeeksMonthState[currentWeekState][4].isCurrent
                  ? ''
                  : classes.dateDisable
              } slot5
              ${
                dateState ===
                generateDate(
                  monthWeekDays[4].day,
                  currentMonthState,
                  currentYearState
                ) && currentWeeksMonthState[currentWeekState][4].isCurrent
                  ? 'selected'
                  : ''
              }`}
              onClick={
                currentWeeksMonthState[currentWeekState][4].isCurrent
                  ? () => selectDate(monthWeekDays[4].day)
                  : () => {}
              }
            >
              {currentWeeksMonthState[currentWeekState][4].day}
            </p>
          </div>
        </div>
        <div
          className={classes.fitContainer}
          onClick={() => currentWeekHandler(1)}
        >
          <img
            className={classes.daysArrow}
            src={rightArrow}
            alt="right arrow week"
          />{' '}
        </div>
      </div>
    </div>
  );
};

const DATE_YEAR = new Date();
const FULL_YEAR = DATE_YEAR.getFullYear();
function monthCalc(mesAct: number) {
  let allweeks = [];
  let diasOld = getDaysInMonth(mesAct - 1, FULL_YEAR);
  let first = new Date(FULL_YEAR, mesAct - 1, 1);
  let weekday = first.getDay() - 1 === -1 ? 6 : first.getDay() - 1;
  let starter = weekday > 4 ? 1 + (7 - weekday) : 1;
  weekday = weekday * -1;
  let firstweek = [];
  for (var i = 0; i < 5; i++) {
    if (starter === 1) {
      let val = weekday < 0 ? diasOld + weekday + 1 : 1 + weekday;
      let current = val > 9 ? false : true;
      firstweek.push({ day: val, isCurrent: current });
      weekday += 1;
    } else {
      let val = starter + i;
      firstweek.push({ day: val, isCurrent: true });
    }
  }
  allweeks.push(firstweek);
  let dias = getDaysInMonth(mesAct, FULL_YEAR);
  return monthRest(dias, firstweek[4].day + 1, allweeks);
}

function monthRest(max: number, last: number, allweeks: any) {
  let ended = true;
  while (ended) {
    var week = [];
    last += 2;
    for (var i = 0; i < 5; i++) {
      var val = last > max ? last - max : last;
      var current = last > max ? false : true;
      week.push({ day: val, isCurrent: current });
      last += 1;
    }
    allweeks.push(week);
    if (last >= max) {
      ended = false;
    }
  }

  return allweeks;
}

function getDaysInMonth(month: number, year: number) {
  return new Date(year, month, 0).getDate();
}

export default SidebarCalendarComponent;
