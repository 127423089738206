import React, { useState, useEffect, useCallback } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useStyles } from '../components/ActualReservation/ActualReservation.styles';
import { useParams, useHistory } from 'react-router-dom';
import { ReservationStatus } from '../interfaces/IReservation';
import { CircularProgress } from '@material-ui/core/';
import { UserContext } from '../App';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';

const CheckInShortcut = () => {
  const classes = useStyles();
  const history = useHistory();
  const PATH = '/reservation';
  const user = React.useContext(UserContext);
  const userEmail = user.email;

  const [openCheckInReservationDialog, setOpenCheckInReservationDialog] =
    useState(true);
  const [isValidReservation, setIsValidReservation] = useState(false);
  const [reservationError, setReservationError] = useState('');

  const getCurrentDate = (separator = '-') => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${date < 10 ? `0${date}` : `${date}`}`;
  };

  const { reservationID }: { reservationID: string } = useParams();

  const reservationQuery = gql`
    query ($id: Float!) {
      reservation: getReservationById(id: $id) {
        id
        userEmail
        date
        status
      }
    }
  `;

  const checkInReservationQuery = gql`
    mutation ($id: Float!) {
      result: checkInReservation(id: $id)
    }
  `;

  const { data } = useQuery<any>(reservationQuery, {
    variables: { id: Number(reservationID) },
  });

  const [checkInReservation, checkInReservationResult] = useMutation(
    checkInReservationQuery,
    {
      variables: {
        id: Number(reservationID),
      },
    }
  );

  const handleCloseCheckIn = () => {
    setOpenCheckInReservationDialog(false);
    history.push(PATH);
  };

  const handleCheckIn = () => {
    checkInReservation();
    setOpenCheckInReservationDialog(false);
  };

  const ValidateReservation = useCallback(
    (userEmail: String, date: String) => {
      if (data !== undefined) {
        if (userEmail !== data?.reservation.userEmail) {
          setReservationError('Este usuario no presenta dicha reservación');
          return false;
        }
        if (!data?.reservation.date.includes(date)) {
          setReservationError(
            'Aún no se encuentra en la fecha disponible para su check-in'
          );
          return false;
        }
        if (ReservationStatus.enable !== data?.reservation.status) {
          setReservationError('Ya se realizó el check-in a dicha reservación');
          return false;
        }
        return true;
      }
      setReservationError('No existe dicha reservación');
      return false;
    },
    [data]
  );

  useEffect(() => {
    setIsValidReservation(ValidateReservation(userEmail, getCurrentDate()));
  }, [data, ValidateReservation, userEmail]);

  const returnReservationsPage = () => {
    history.push(PATH);
  };

  useEffect(() => {
    if (checkInReservationResult.called) {
      history.push(PATH);
    }
  }, [checkInReservationResult.data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!userEmail) {
    return (
      <div className={classes.container}>
        <Dialog
          open={openCheckInReservationDialog}
          onClose={returnReservationsPage}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          className={classes.cancelReservationDialogBox}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            classes={{ root: classes.dialogTitle }}
          >
            Check-in
          </DialogTitle>
          <DialogContent classes={{ root: classes.dialogContent }}>
            <DialogContentText classes={{ root: classes.dialogSubtitle }}>
              <CircularProgress
                classes={{ root: classes.circularProgress }}
                color="primary"
                size="120px"
              />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }

  if (!isValidReservation) {
    return (
      <div className={classes.container}>
        <Dialog
          open={openCheckInReservationDialog}
          onClose={returnReservationsPage}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          className={classes.cancelReservationDialogBox}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            classes={{ root: classes.dialogTitle }}
          >
            Información
          </DialogTitle>
          <DialogContent classes={{ root: classes.dialogContent }}>
            <DialogContentText classes={{ root: classes.dialogSubtitle }}>
              {reservationError}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            classes={{ root: classes.dialogButtonsContainerCancel }}
          >
            <Button
              className={`${classes.dialogButtons} ${classes.confirmButton}`}
              onClick={returnReservationsPage}
              color="primary"
            >
              Salir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Dialog
        open={openCheckInReservationDialog}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        className={classes.cancelReservationDialogBox}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          classes={{ root: classes.dialogTitle }}
        >
          Check-in
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText classes={{ root: classes.dialogSubtitle }}>
            ¿Estás seguro de que deseas realizar el check-in para la reservación
            en la fecha?
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogButtonsContainerCancel }}>
          <Button
            className={`${classes.dialogButtons} ${classes.cancelButton}`}
            onClick={handleCloseCheckIn}
            color="primary"
          >
            No
          </Button>
          <Button
            className={`${classes.dialogButtons} ${classes.confirmButton}`}
            onClick={handleCheckIn}
            color="primary"
          >
            Sí
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CheckInShortcut;
