import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    sidebarContainer: {
        position: 'relative',
        display: 'block',
        float: 'left',
        marginTop: '1.25vw',
        filter: 'drop-shadow(0px 0.31vw 0.31vw rgba(33, 35, 34, 0.2))',
        border: '1px solid #d6d6d6',
        boxSizing: 'border-box',
        background: '#ffffff',
        width: '91.11vw',
        height: '106vw',
        marginLeft: '4.44vw',
        borderRadius: '3.33vw',

        [theme.breakpoints.up('medium')]: {
            width: '83.46vw',
            height: '61vw',
            marginLeft: '8.2vw',
            borderRadius: '0.87vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '86.45vw',
            height: '28.85vw',
            marginLeft:'6.77vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '32.03vw',
            height: '50vw',
            marginLeft: '10vw',
            
        },
        [theme.breakpoints.up('extraLarge')]: {
            marginTop:'1.45vw',
            width: '32.15vw',
            height: '47vw',
            marginLeft: '17.87vw',
        },
    },

    sidebarTitle: {
        fontFamily: "WTGothic",
        margin: 0,
        fontWeight: 300,
        letterSpacing: '0.01em',
        marginLeft: '4.44vw',
        marginTop: '4.44vw',
        width: '81.94vw',
        fontSize: '5vw',
        float: 'left',

        [theme.breakpoints.up('medium')]: {
            marginLeft: '2.6vw',
            marginTop: '2.08vw',
            width: '37.89vw',
            fontSize: '3.9vw',
        },
        [theme.breakpoints.up('tablet')]: {
            marginLeft: '2.5vw',
            marginTop: '2.5vw',
            width: '39.58vw',
            fontSize: '3.12vw',
        },
        [theme.breakpoints.up('large')]: {
            marginTop: '1.75vw',
            marginLeft: '1.87vw',
            fontSize: '2.03vw',
            width: '100%',
            float: 'none'
        },
        [theme.breakpoints.up('extraLarge')]: {
            marginTop: '1.66vw',
            fontSize: '1.8vw',
        },
    },

    CircularContainerTitle: {
        display: 'block',
        width: '60vw',
        height: '5.067vw',
        fontFamily: "WTGothic",
        fontWeight: 300,
        fontSize: '4.267vw',
        alignItems: 'center',
        marginLeft: '4.44vw',
        marginTop: '2vw',

        [theme.breakpoints.up('medium')]: {
            width: '42.5vw',
            height: '2.474vw',
            marginLeft: '29vw',
            fontSize: '2.083vw',
        },
        [theme.breakpoints.up('tablet')]: {
            display: 'block',
            float:'right',
            width: '42.5vw',
            height: '2.42vw',
            fontSize: '1.875vw',
            marginTop: '2vw'
        },
        [theme.breakpoints.up('large')]: {
            margin: 0,
            alignItems: 'center',
            float: 'unset',
            width: '26.31vw',
            height: '2.42vw',
            marginLeft: '1.87vw',
            marginBottom: '0vw',
            fontSize: '2.03vw',
            lineHeight: '2.42vw',
            marginTop: '1vw'
        },
        [theme.breakpoints.up('extraLarge')]: {
            fontSize: '1.8vw',
        },
    },

    CircularContainerFrame: {
        display: 'flex',
        marginTop: '2.5vw',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('medium')]: {
            
        },
        [theme.breakpoints.up('tablet')]: {
            width: '100%',
            height: '14.12vw',
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '17vw',
            marginTop: '-1vw',
            float: 'right'
        },
        [theme.breakpoints.up('large')]: {
            width: '100%',
            height: '14.12vw',
            marginRight: '0vw',
            marginTop: '2.5vw',
            display: 'flex',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('extraLarge')]: {
            height: '11.87vw',
            
        },
    },

    floorSelection: {
        display:'flex',
        width: '100%',
        justifyContent: 'center',
        paddingTop: '1.2vw',
        [theme.breakpoints.up('medium')]: {
            
        },
        [theme.breakpoints.up('tablet')]: {
            width: 'unset',
        },
        [theme.breakpoints.up('large')]: {
            
        },
        [theme.breakpoints.up('extraLarge')]: {
            
        },

    },

    floorOption: {
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.oceanBlue,
        fontFamily: 'WTGothic',
        fontSize: '3.200vw',
        fontStyle: 'normal',
        border: '1px solid #0A47ED',
        boxSizing: 'border-box',
        borderRadius: '7.595vw 0vw 0vw 7.595vw',
        alignItems: 'center',
        textAlign: 'center',
        width: '39.371vw',
        height: '8.533vw',
        '&.right': {
            borderRadius: '0vw 7.595vw 7.595vw 0vw',
        },

        '&.active': {
            backgroundColor: theme.palette.oceanBlue,
            color: theme.palette.white,
        },

        '&:hover':{
            cursor: 'pointer'
        },

        '&.alone':{
        borderRadius: '7.595vw 7.595vw 7.595vw 7.595vw',
        },

        [theme.breakpoints.up('medium')]: {
            fontSize: '1.563vw',
            width: '29.680vw',
            height: '4.167vw',
        },
        [theme.breakpoints.up('tablet')]: {
            fontSize: '1.250vw',
            width: '20.730vw',
            height: '3.333vw',
            marginTop: '1.9vw'
        },
        [theme.breakpoints.up('large')]: {
            fontSize: '0.938vw',
            width: '14.175vw',
            height: '2.5vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            
        },

    }
}))