import React, { useEffect, useState } from 'react';
import { useStyles } from './Page.styles';
import ReservationHistoryComponent from '../components/AdminReservationHistory/AdminReservationHistory';
import { gql, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { IReservation, ReservationStatus } from '../interfaces/IReservation';
import { IReservationData } from '../interfaces/IReservationData';
import DialogBox from '../components/DialogBox/DialogBox';
import ReservationPageSkeleton from '../components/ReservationPageSkeleton/ReservationPageSkeleton';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const timeZone = dayjs.tz.guess();

const ReservationsPage = () => {
  const classes = useStyles();
  const [date, setDate] = useState(dayjs().toDate());
  const [endDate, setEndDate] = useState(dayjs().toDate());
  const [floorId, setFloorId] = useState(2);
  const [reservationsHistory, setReservationsHistory] = useState<
    IReservation[]
  >([]);
  const [dialogMessage, setDialogMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [DBIsSuccess, setDBIsSucces] = useState(false);

  const reservationsQuery = gql`
    query ($dateParam: String!, $endDateParam: String!, $pFloorId: Float!) {
      reservations: getAllReservationByDateRangeAndFloor(
        dateParam: $dateParam
        endDateParam: $endDateParam
        pFloorId: $pFloorId
      ) {
        id
        userEmail
        date
        status
        fullName
        phoneNumber
        traveledTo
        tripReturnDate
        needsParking
        workstationreservation {
          tableId
          workstationId
          floorId
          areaId
        }
      }
    }
  `;

  const [getReservationsHistoryData, reservationsHistoryData] =
    useLazyQuery<IReservationData>(reservationsQuery, {
      variables: {
        dateParam: dayjs(date).format('YYYY/MM/DD'),
        endDateParam: dayjs(endDate).format('YYYY/MM/DD'),
        pFloorId: floorId,
      },
      pollInterval: 3000,
    });

  useEffect(() => {
    if (reservationsHistoryData.data) {
      setReservationsHistory(reservationsHistoryData.data.reservations);
    }
  }, [reservationsHistoryData.data]);

  useEffect(() => {
    getReservationsHistoryData();
  }, [endDate, getReservationsHistoryData]);

  useEffect(() => {
    if (date.valueOf() > endDate.valueOf()) {
      setEndDate(date);
    }
    getReservationsHistoryData();
  }, [date, getReservationsHistoryData, endDate]);

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = () => {
    const headers = {
      name: 'Nombre Completo del colaborador',
      number: 'Número de Teléfono',
      date: 'Fecha de visita de la oficina',
      parking: '¿Requiere espacio de parqueo?',
      checkin: '¿Realizó check-in para la reservación?',
    };

    const report = [headers];

    reservationsHistory.map((item, index) => {
      let reservationInfo = {
        name: item.fullName,
        number: item.phoneNumber,
        date: dayjs.tz(item.date, timeZone).format('DD/MM/YYYY'),
        parking: item.needsParking ? 'Si' : 'No',
        checkin:
          item.status === ReservationStatus.checked
            ? 'Si'
            : item.status === ReservationStatus.checkedOut
            ? 'Si'
            : 'No',
      };
      if (item.status === ReservationStatus.disabled) {
        return '';
      }
      report.push(reservationInfo);

      return '';
    });

    const fileName = `Formulario de Visita a la Oficina Colaboradores ${dayjs(
      date
    ).format('DD-MM-YYYY')} al ${dayjs(endDate).format('DD-MM-YYYY')}`;
    const ws = XLSX.utils.json_to_sheet(report, { skipHeader: true });
    const wb = { Sheets: { Reporte: ws }, SheetNames: ['Reporte'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  if (reservationsHistoryData.loading) {
    return <ReservationPageSkeleton />;
  }

  return (
    <>
      <div className="full">
        <div className={classes.mainTitleDivSlim}>
          <h1 className={classes.titleFont}>Reservaciones</h1>
        </div>
        <div className={classes.divFloorSelection}>
          <div className={classes.floorSelection}>
            <div
              className={`${classes.floorOption} ${
                floorId === 2 ? 'active' : ''
              }`}
              onClick={() => {
                setFloorId(2);
              }}
            >
              Segundo piso
            </div>
            <div
              className={`${classes.floorOption} right ${
                floorId === 3 ? 'active' : ''
              }`}
              onClick={() => {
                setFloorId(3);
              }}
            >
              Tercer piso
            </div>
          </div>
        </div>
        <DialogBox
          message={dialogMessage}
          isSuccess={DBIsSuccess}
          show={showErrorMessage}
          hide={setShowErrorMessage}
        ></DialogBox>
        <ReservationHistoryComponent
          date={date}
          updateDate={setDate}
          endDate={endDate}
          updateEndDate={setEndDate}
          data={reservationsHistory}
          generateReport={exportToCSV}
          floorId={floorId}
          dialogMessage={setDialogMessage}
          showMessage={setShowErrorMessage}
          success={setDBIsSucces}
        />
      </div>
    </>
  );
};

export default ReservationsPage;
