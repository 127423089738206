import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import "./Calendar.css";
import { ReactComponent as Calendar } from "../../assets/images/bold_calendar.svg";

registerLocale("es", es);
const RangeCalendarComponent = (props) => {
  //Filters and blocks the day if it is saturday or sunday
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  return (
    <>
      <div className="container-date">
        <DatePicker
          className={`input-text date-cal ${props.small ? "small" : ""}`}
          onFocus={(e) => e.target.blur()}
          selected={props.date}
          onChange={(date) => props.updateDate(date)}
          selectsStart
          startDate={props.date}
          endDate={props.endDate}
          popperPlacement="bottom"
          locale="es"
          popperModifiers={{
            flip: {
              behavior: ["bottom"],
            },
            preventOverflow: {
              enabled: true,
            },
            hide: {
              enabled: false,
            },
          }}
          filterDate={isWeekday}
        />
        <Calendar
          className={`input-icon cal-icon ${props.small ? "small" : ""}`}
        />
      </div>
      <div className="container-date">
        <DatePicker
          className={`input-text date-cal ${props.small ? "small" : ""}`}
          onFocus={(e) => e.target.blur()}
          selected={props.endDate}
          onChange={(endDate) => props.updateEndDate(endDate)}
          selectsEnd
          startDate={props.date}
          endDate={props.endDate}
          minDate={props.date}
          popperPlacement="bottom"
          locale="es"
          popperModifiers={{
            flip: {
              behavior: ["bottom"],
            },
            preventOverflow: {
              enabled: true,
            },
            hide: {
              enabled: false,
            },
          }}
          filterDate={isWeekday}
        />
        <Calendar
          className={`input-icon cal-icon ${props.small ? "small" : ""}`}
        />
      </div>
    </>
  );
};

export default RangeCalendarComponent;
