import React, { useState, useCallback, useEffect } from 'react';
import mapSecondFloor from '../../assets/images/office-map.png';
import mapThirdFloor from '../../assets/images/office-map-third.png';
import { ReactComponent as Station } from '../../assets/images/station-icon.svg';
import { ReactComponent as StationUnavailable } from '../../assets/images/unavailable-station-icon.svg';
import mapBtnLeft from '../../assets/images/map-left.svg';
import mapBtnRight from '../../assets/images/map-right.svg';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { ITable } from '../../interfaces/ITable';
import { IUserslist } from '../../interfaces/IUser';
import { WorkStationStatus } from '../../interfaces/IWorkstation';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { getLabel } from '../../helpers/labels';
import dayjs from 'dayjs';
import { IReservation, ReservationStatus } from '../../interfaces/IReservation';
import { useStyles } from './StationSelection.styles';
import Skeleton from '@material-ui/lab/Skeleton';
import parkingPolicy from '../../assets/docs/PoliticaParqueosWT-CostaRica.pdf';

interface IProps {
  filter: boolean;
  stations: ITable[];
  date: Date;
  floorId: number;
  refetchReservations: () => void;
  updateSelectedStation: (id: string, tableId: string) => void;
  setShowErrorDailog: (option: boolean) => void;
  setDialogMessage: (message: string) => void;
  reservationsUpdated: boolean;
  setReservationsUpdated: (option: boolean) => void;
  isFullCapacity: boolean;
  userEmail: string;
  userName: string;
  actualReservation: IReservation | undefined;
  updateActualReservation: (value: IReservation | undefined) => void;
  updateDialogBoxSucces: (value: boolean) => void;
  isAdmin: boolean;
}

const StationSelection = (props: IProps) => {
  const classes = useStyles();
  const {
    filter,
    stations,
    date,
    floorId,
    updateSelectedStation,
    setShowErrorDailog,
    reservationsUpdated,
    setReservationsUpdated,
    isFullCapacity,
    setDialogMessage,
    userEmail,
    userName,
    actualReservation,
    updateActualReservation,
    updateDialogBoxSucces,
  } = props;
  const successMessage = getLabel('reservationSuccessMessage');
  const ErrorMessage = getLabel('reservationErrorMessage');

  const history = useHistory();
  const confirmReservation = useCallback(
    (message: string, success: boolean) =>
      history.push({
        pathname: '/',
        state: { message: message, isSuccess: success },
      }),
    [history]
  );

  const [mapState, setMapState] = useState(1);
  const [actualStation, setActualStation] = useState('');
  const [actualStationId, setActualStationId] = useState('');
  const [stationTableId, setStationTableId] = useState('');
  const [openDialog, setOpen] = useState(false);
  const [openCancelReservationDialog, setOpenCancelReservationDialog] =
    useState(false);
  const [openCheckInReservationDialog, setOpenCheckInReservationDialog] =
    useState(false);
  const [openCheckOutReservationDialog, setOpenCheckOutReservationDialog] =
    useState(false);
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [hasTraveledCountry, setHastraveledCountry] = useState(false);
  const [traveledTo, setTraveledTo] = useState('');
  const [tripReturnDate, setTripReturnDate] = useState('');
  const [contactWithASuspect, setContactWithASuspect] = useState(false);
  const [anySymptom, setAnySymptom] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [vaccinationSchemeComplete, setVaccinationSchemeComplete] =
    useState(true);
  const [filteredStations, setFilteredStations] = useState(stations || []);
  const [parking, setParking] = useState('No');
  const [needsParking, setNeedsParking] = useState(false);
  const [isReserving, setIsReserving] = useState(false);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const saveReservationsQuery = gql`
    mutation (
      $workstationId: String!
      $tableId: String!
      $floorId: Int!
      $areaId: Int!
      $userEmail: String!
      $date: DateTime!
      $fullName: String!
      $phoneNumber: String!
      $traveledTo: String!
      $tripReturnDate: String!
      $status: Boolean!
      $statusReservation: Int!
      $needsParking: Boolean!
    ) {
      reservation: createWorkstationReservation(
        workstationReservation: {
          workstationId: $workstationId
          tableId: $tableId
          floorId: $floorId
          areaId: $areaId
          status: $status
        }
        reservation: {
          userEmail: $userEmail
          date: $date
          fullName: $fullName
          phoneNumber: $phoneNumber
          traveledTo: $traveledTo
          tripReturnDate: $tripReturnDate
          status: $statusReservation
          needsParking: $needsParking
        }
      ) {
        reservationId
      }
    }
  `;

  const reservationsQuery = gql`
    query ($dateParam: String!, $pFloorId: Float!, $pAreaId: Float!) {
      reservations: getReservationByDateAndFloorAndArea(
        dateParam: $dateParam
        pFloorId: $pFloorId
        pAreaId: $pAreaId
      ) {
        id
        userEmail
        date
        status
        workstationreservation {
          reservationId
          workstationId
          tableId
          floorId
          areaId
          status
        }
      }
    }
  `;

  const deleteReservationQuery = gql`
    mutation ($reservationID: Float!) {
      result: deleteWorkstationReservation(reservationID: $reservationID)
    }
  `;

  const nextReservationsQuery = gql`
    query ($email: String!) {
      reservations: getNextReservationsByUser(email: $email) {
        date
        workstationreservation {
          tableId
          workstationId
          floorId
          areaId
          status
        }
      }
    }
  `;

  const pastReservationsQuery = gql`
    query ($email: String!, $firstDay: DateTime!, $lastDay: DateTime!) {
      reservations: getReservationsHistoryByUserAndDate(
        email: $email
        firstDay: $firstDay
        lastDay: $lastDay
      ) {
        date
        workstationreservation {
          tableId
          workstationId
          floorId
          areaId
          status
        }
      }
    }
  `;

  const checkInReservationQuery = gql`
    mutation ($id: Float!) {
      result: checkInReservation(id: $id)
    }
  `;

  const checkOutReservationQuery = gql`
    mutation ($id: Float!) {
      result: checkOutReservation(id: $id)
    }
  `;

  const disableWorkstationReservationQuery = gql`
    mutation ($id: Float!) {
      result: disableWorkstationReservation(id: $id)
    }
  `;

  const userQuery = gql`
    query ($email: String!) {
      users: getUserByEmail(email: $email) {
        email
        name
        role
        phone
        areaId
      }
    }
  `;

  const [getUserData, userData] = useLazyQuery<IUserslist>(userQuery, {
    fetchPolicy: 'network-only',
    variables: { email: userEmail },
  });

  const [addReservation, reservationResult] = useMutation(
    saveReservationsQuery,
    {
      awaitRefetchQueries: true,
      variables: {
        workstationId: actualStationId,
        tableId: stationTableId,
        floorId: floorId,
        areaId: 1,
        userEmail: userEmail, //TODO: use just the email entered in the logIn
        date: dayjs(date).format('YYYY/MM/DD'),
        fullName: fullName,
        phoneNumber: phoneNumber,
        traveledTo: traveledTo,
        tripReturnDate: tripReturnDate,
        status: true,
        statusReservation: 1,
        needsParking: needsParking,
      },
      refetchQueries: [
        {
          query: reservationsQuery,
          variables: {
            dateParam: dayjs(date).format('YYYY/MM/DD'),
            pFloorId: floorId,
            pAreaId: 1,
          },
        },
        {
          query: nextReservationsQuery,
          variables: {
            email: userEmail,
          },
        },
        {
          query: pastReservationsQuery,
          variables: {
            email: userEmail,
            firstDay: dayjs(date).startOf('month').format('YYYY/MM/DD'),
            lastDay: dayjs(date).endOf('month').format('YYYY/MM/DD'),
          },
        },
      ],
    }
  );

  const [deleteReservation, deleteReservationResult] = useMutation(
    deleteReservationQuery,
    {
      variables: {
        reservationID: actualReservation?.id,
      },
      refetchQueries: [
        {
          query: reservationsQuery,
          variables: {
            dateParam: dayjs(date).format('YYYY/MM/DD'),
            pFloorId: floorId,
            pAreaId: 1,
          },
        },
        {
          query: nextReservationsQuery,
          variables: {
            email: userEmail,
          },
        },
        {
          query: pastReservationsQuery,
          variables: {
            email: userEmail,
            firstDay: dayjs(date).startOf('month').format('YYYY/MM/DD'),
            lastDay: dayjs(date).endOf('month').format('YYYY/MM/DD'),
          },
        },
      ],
    }
  );

  const [checkInReservation, checkInReservationResult] = useMutation(
    checkInReservationQuery,
    {
      variables: {
        id: actualReservation?.id,
      },
      refetchQueries: [
        {
          query: reservationsQuery,
          variables: {
            dateParam: dayjs(date).format('YYYY/MM/DD'),
            pFloorId: floorId,
            pAreaId: 1,
          },
        },
      ],
    }
  );

  const [checkOutReservation, checkOutReservationResult] = useMutation(
    checkOutReservationQuery,
    {
      variables: {
        id: actualReservation?.id,
      },
      refetchQueries: [
        {
          query: reservationsQuery,
          variables: {
            dateParam: dayjs(date).format('YYYY/MM/DD'),
            pFloorId: floorId,
            pAreaId: 1,
          },
        },
      ],
    }
  );

  const [disableWorkstationReservation, disableWorkstationReservationResult] =
    useMutation(disableWorkstationReservationQuery, {
      variables: {
        id: actualReservation?.id,
      },
      refetchQueries: [
        {
          query: reservationsQuery,
          variables: {
            dateParam: dayjs(date).format('YYYY/MM/DD'),
            pFloorId: floorId,
            pAreaId: 1,
          },
        },
      ],
    });

  const pushRight = () => {
    setMapState(2);
  };

  const pushLeft = () => {
    setMapState(1);
  };

  const handleClose = () => {
    setOpen(false);
    setHastraveledCountry(false);
    setTraveledTo('');
    setTripReturnDate('');
    setContactWithASuspect(false);
    setAnySymptom(false);
    setAgreement(false);
    setVaccinationSchemeComplete(true);
  };

  const handleCloseCancelReservation = () => {
    setOpenCancelReservationDialog(false);
  };

  const stationOccupied = (tableID: string, id: string) => {
    let table = filteredStations.find((e) => e.id === tableID);
    let workstation = table?.workstations.find((e) => e.id === id);
    if (
      workstation &&
      workstation.reservationStatus === WorkStationStatus.occupied
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChangeParking = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParking((event.target as HTMLInputElement).value);
    const value =
      (event.target as HTMLInputElement).value === 'Si' ? true : false;
    setNeedsParking(value);
  };

  useEffect(() => {
    if (actualReservation && actualReservation.workstationreservation) {
      setActualStation(
        `${actualReservation.workstationreservation.floorId}` +
          actualReservation.workstationreservation.tableId +
          actualReservation.workstationreservation.workstationId
      );
      setActualStationId(
        actualReservation.workstationreservation.workstationId
      );
      setStationTableId(actualReservation.workstationreservation.tableId);
    }
  }, [actualReservation]);

  useEffect(() => {
    if (userEmail) {
      getUserData();
    }
  }, [userEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userName) {
      setFullName(userName);
    }
  }, [userName]);

  useEffect(() => {
    if (userData.data) {
      setPhoneNumber(userData?.data?.users[0]?.phone);
    }
  }, [userData.data]);

  useEffect(() => {
    if (!actualReservation) {
      setActualStation('');
      setActualStationId('');
      setStationTableId('');
    }
  }, [date, floorId, actualReservation]);

  useEffect(() => {
    if (
      reservationsUpdated &&
      stationTableId !== '' &&
      actualStationId !== ''
    ) {
      if (!stationOccupied(stationTableId, actualStationId)) {
        updateSelectedStation(actualStationId, stationTableId);
      } else {
        setActualStation('');
        setShowErrorDailog(true);
        updateDialogBoxSucces(false);
        setDialogMessage(getLabel('alreadyReserved'));
        setOpen(false);
      }
    }
    setReservationsUpdated(false);
  }, [reservationsUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleStationClick = (id: string, tableId: string, floorId: number) => {
    if (`${floorId}` + tableId + id === actualStation) {
      setActualStation('');
      setActualStationId('');
      setStationTableId('');
      updateSelectedStation('', '');
    } else {
      setActualStation(`${floorId}` + tableId + id);
      setActualStationId(id);
      setStationTableId(tableId);
      updateSelectedStation(id, tableId);
    }
  };

  const saveReservation = () => {
    if (!isReserving) {
      setIsReserving(true);
      addReservation();
    }
  };

  useEffect(() => {
    if (reservationResult.data) {
      if (reservationResult.data.reservation.reservationId === -1) {
        setShowErrorDailog(true);
        updateDialogBoxSucces(false);
        setDialogMessage(getLabel('alreadyReserved'));
        setOpen(false);
        setActualStation('');
      } else {
        if (
          getItemDate(reservationResult.data.reservation?.date).valueOf() ===
          today.valueOf()
        ) {
          handleClose();
          updateDialogBoxSucces(true);
          setDialogMessage(successMessage);
          setShowErrorDailog(true);
        } else {
          confirmReservation(successMessage, true);
        }
      }
    } else if (reservationResult.error) {
      confirmReservation(ErrorMessage, false);
    }
  }, [reservationResult.data, reservationResult.error, confirmReservation]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleStationInfoClick = () => {
    if (actualReservation) {
      if (actualReservation.status === ReservationStatus.checked) {
        setOpenCheckOutReservationDialog(true);
      } else {
        setOpenCancelReservationDialog(true);
      }
    } else {
      setIsReserving(false);
      setOpen(true);
    }
  };

  const handleDeleteReservation = () => {
    deleteReservation();
    setOpenCancelReservationDialog(false);
  };

  const handleCheckIn = () => {
    checkInReservation();
    setOpenCheckInReservationDialog(false);
  };

  const handleCloseCheckIn = () => {
    setOpenCheckInReservationDialog(false);
  };

  const handleCheckOut = () => {
    checkOutReservation();
    setOpenCheckOutReservationDialog(false);
  };

  const handleCloseCheckOut = () => {
    setOpenCheckOutReservationDialog(false);
  };

  useEffect(() => {
    let newStations: ITable[] = [];
    newStations = stations.filter((station) => {
      return station.id.length === 1;
    });
    setFilteredStations(newStations);
  }, [stations]);

  useEffect(() => {
    if (deleteReservationResult.data) {
      if (deleteReservationResult.data.result) {
        updateActualReservation(undefined);
        setActualStation('');
        setActualStationId('');
        setStationTableId('');
        updateDialogBoxSucces(true);
        setDialogMessage(getLabel('cancelReservationMessageSuccess'));
        setShowErrorDailog(true);
      } else {
        updateDialogBoxSucces(false);
        setDialogMessage(getLabel('cancelReservationMessageFailed'));
        setShowErrorDailog(true);
      }
    }
  }, [deleteReservationResult.data]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (checkInReservationResult.data) {
      if (checkInReservationResult.data.result) {
        updateDialogBoxSucces(true);
        setDialogMessage(getLabel('checkInMessageSuccess'));
        setShowErrorDailog(true);
      } else {
        updateDialogBoxSucces(false);
        setDialogMessage(getLabel('checkInMessageFailed'));
        setShowErrorDailog(true);
      }
    }
  }, [checkInReservationResult.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (checkOutReservationResult.data) {
      if (checkOutReservationResult.data.result) {
        if (actualReservation) {
          disableWorkstationReservation();
        }
      } else {
        updateDialogBoxSucces(false);
        setDialogMessage(getLabel('checkOutMessageFailed'));
        setShowErrorDailog(true);
      }
    }
  }, [checkOutReservationResult]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (disableWorkstationReservationResult.data) {
      if (disableWorkstationReservationResult.data.result) {
        updateDialogBoxSucces(true);
        setDialogMessage(getLabel('checkOutMessageSuccess'));
        setShowErrorDailog(true);
      } else {
        updateDialogBoxSucces(false);
        setDialogMessage(getLabel('checkOutMessageFailed'));
        setShowErrorDailog(true);
      }
    }
  }, [disableWorkstationReservationResult.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const getItemDate = (itemDate: Date) => {
    let strDate = dayjs.tz(itemDate, dayjs.tz.guess()).format('MM/DD/YYYY');
    return new Date(strDate);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        className={classes.dialogBox}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          classes={{ root: classes.dialogTitle }}
        >
          Detalle de reservación
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div>
            <div className={classes.reservationDetailsDiv}>
              <h2 className={classes.titleRecomendation}>
                Detalle de la reserva
              </h2>
              <DialogContentText classes={{ root: classes.dialogSubtitle }}>
                Estación:
              </DialogContentText>
              <DialogContentText classes={{ root: classes.dialogStationInfo }}>
                {actualStation}
              </DialogContentText>
              <DialogContentText classes={{ root: classes.dialogSubtitle }}>
                Fecha:
              </DialogContentText>
              <DialogContentText classes={{ root: classes.dialogStationInfo }}>
                {props.date.toLocaleDateString('es-CR', {
                  year: 'numeric',
                  day: 'numeric',
                  month: '2-digit',
                })}
              </DialogContentText>
              <DialogContentText classes={{ root: classes.dialogSubtitle }}>
                Piso:
              </DialogContentText>
              <DialogContentText classes={{ root: classes.dialogStationInfo }}>
                {floorId === 3 ? 'Tercer piso' : 'Segundo Piso'}
              </DialogContentText>
              <p id="parkingLabel" className={classes.parkingLabel}>
                ¿Requiere espacio de parqueo?
              </p>
              <RadioGroup
                className={classes.fields}
                aria-labelledby="parkingLabel"
                name="parking"
                value={parking}
                onChange={handleChangeParking}
              >
                <FormControlLabel
                  className={classes.parkingFormControl}
                  value="Si"
                  control={<Radio className={classes.parkingCheckbox} />}
                  label="Si"
                />
                <FormControlLabel
                  className={classes.parkingFormControl}
                  value="No"
                  control={<Radio className={classes.parkingCheckbox} />}
                  label="No"
                />
              </RadioGroup>
              <p className={classes.parkingPolicy}>
                Los espacios de parqueo son limitados, por favor lea nuestra{' '}
                <br />
                <a href={parkingPolicy} target="_blank">
                  Política de Parqueos
                </a>
                , si usted utiliza el parqueo del sótano, por favor seleccione
                la opción "No"
              </p>
            </div>
          </div>
          {/* <DialogContentText classes={{ root: classes.dialogSubtitle }}>
            Características Tecnológicas:
          </DialogContentText> */}
          {/* <div className={`${classes.techFeaturesBox} dialog`}>
            <label className={`${classes.techFeature} dialog`}>Teclado</label>
            <label className={`${classes.techFeature} dialog`}>Monitor</label>
            <label className={`${classes.techFeature} dialog`}>Mouse</label>
            <label className={`${classes.techFeature} dialog`}>Arturito</label>
            <label className={`${classes.techFeature} dialog`}>Parlantes</label>
          </div> */
          /* This part is commented because the technological features of each station
              are not set dynamically, this will be done in the phase 2 of the project*/}
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogButtonsContainer }}>
          <div className={classes.buttonsGap}>
            <Button
              className={`${classes.dialogButtons} ${classes.cancelButton}`}
              onClick={handleClose}
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              className={`${classes.dialogButtons} ${classes.confirmButton}`}
              onClick={saveReservation}
              color="primary"
              disabled={isReserving}
            >
              Confirmar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCancelReservationDialog}
        onClose={handleCloseCancelReservation}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        className={classes.cancelReservationDialogBox}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          classes={{ root: classes.dialogTitle }}
        >
          Cancelar reservación
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText classes={{ root: classes.dialogSubtitle }}>
            ¿Estás seguro de que deseas cancelar la reservación?
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogButtonsContainerCancel }}>
          <Button
            className={`${classes.dialogButtons} ${classes.cancelButton}`}
            onClick={handleCloseCancelReservation}
            color="primary"
          >
            No
          </Button>
          <Button
            className={`${classes.dialogButtons} ${classes.confirmButton}`}
            onClick={handleDeleteReservation}
            color="primary"
          >
            Sí
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCheckInReservationDialog}
        onClose={handleCloseCheckIn}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        className={classes.cancelReservationDialogBox}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          classes={{ root: classes.dialogTitle }}
        >
          Check-in
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText classes={{ root: classes.dialogSubtitle }}>
            ¿Estás seguro de que deseas realizar el check-in para la
            reservación?
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogButtonsContainerCancel }}>
          <Button
            className={`${classes.dialogButtons} ${classes.cancelButton}`}
            onClick={handleCloseCheckIn}
            color="primary"
          >
            No
          </Button>
          <Button
            className={`${classes.dialogButtons} ${classes.confirmButton}`}
            onClick={handleCheckIn}
            color="primary"
          >
            Sí
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCheckOutReservationDialog}
        onClose={handleCloseCheckOut}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        className={classes.cancelReservationDialogBox}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          classes={{ root: classes.dialogTitle }}
        >
          Check-out
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText classes={{ root: classes.dialogSubtitle }}>
            ¿Estás seguro de que deseas realizar el check-out para la
            reservación?
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogButtonsContainerCancel }}>
          <Button
            className={`${classes.dialogButtons} ${classes.cancelButton}`}
            onClick={handleCloseCheckOut}
            color="primary"
          >
            No
          </Button>
          <Button
            className={`${classes.dialogButtons} ${classes.confirmButton}`}
            onClick={handleCheckOut}
            color="primary"
          >
            Sí
          </Button>
        </DialogActions>
      </Dialog>
      {reservationResult.loading ? (
        <div>
          <Skeleton variant="text" />
          <Skeleton variant="circle" width={40} height={40} />
          <Skeleton variant="rect" width={210} height={118} />
        </div>
      ) : (
        <div
          className={`${classes.mapDiv} ${props.isAdmin ? 'isAdmin' : null}`}
        >
          <div
            className={`${
              mapState === 1 ? `${classes.mapPrint}` : `${classes.mapPrintNext}`
            } ${
              props.isAdmin
                ? floorId === 3
                  ? 'thirdFloor'
                  : 'isAdmin'
                : floorId === 3
                ? 'thirdFloor'
                : null
            }`}
          >
            {floorId === 2 ? (
              <img
                src={mapSecondFloor}
                className={classes.map}
                alt="building map"
              ></img>
            ) : (
              <img
                src={mapThirdFloor}
                className={classes.map}
                alt="building map"
              ></img>
            )}
            {filteredStations.map((item, index) => {
              return (
                <div
                  key={`row-${item.id}`}
                  className={`${
                    floorId === 3
                      ? classes.longDeskThirdFloor
                      : classes.longDesk
                  } desk${index + 1}`}
                >
                  <p className={classes.deskTag}>{item.id}</p>
                  {item.workstations.map((station, index) => {
                    const stationId = station.tableId + station.id;
                    return (
                      <button
                        key={station.id}
                        className={classes.stationButton}
                        onClick={
                          isFullCapacity
                            ? undefined
                            : props.isAdmin
                            ? undefined
                            : station.reservationStatus !==
                                WorkStationStatus.disabled &&
                              station.reservationStatus !==
                                WorkStationStatus.occupied &&
                              station.reservationStatus !==
                                WorkStationStatus.actual &&
                              actualReservation === undefined
                            ? (e) => {
                                handleStationClick(
                                  station.id,
                                  station.tableId,
                                  station.floorId
                                );
                              }
                            : undefined
                        }
                      >
                        {station.reservationStatus ===
                        WorkStationStatus.disabled ? (
                          <div style={{ position: 'relative' }}>
                            <StationUnavailable
                              id={stationId}
                              className={`${classes.stationUnavailableOnmap} ${
                                (index + 1) % 2 === 1 ? 'left' : 'right'
                              }`}
                            />
                          </div>
                        ) : station.reservationStatus ===
                          WorkStationStatus.occupied ? (
                          <div style={{ position: 'relative' }}>
                            <Station
                              id={stationId}
                              className={`${classes.stationOnmap} ${
                                (index + 1) % 2 === 1 ? 'left' : 'right'
                              } occupied`}
                            ></Station>
                            <p
                              style={{
                                right: (index + 1) % 2 === 1 ? '7px' : '-7px',
                              }}
                              className={`${classes.stationOnmap} ${
                                (index + 1) % 2 === 1 ? 'right' : 'left'
                              } ${classes.stationText}`}
                            >
                              {floorId + stationId}
                            </p>
                          </div>
                        ) : station.reservationStatus ===
                          WorkStationStatus.selected ? (
                          <div style={{ position: 'relative' }}>
                            <Station
                              id={stationId}
                              className={`${classes.stationOnmap} ${
                                (index + 1) % 2 === 1 ? 'left' : 'right'
                              } selected`}
                            ></Station>
                            <p
                              style={{
                                right: (index + 1) % 2 === 1 ? '7px' : '-7px',
                              }}
                              className={`${classes.stationOnmap} ${
                                (index + 1) % 2 === 1 ? 'right' : 'left'
                              } ${classes.stationText}`}
                            >
                              {floorId + stationId}
                            </p>
                          </div>
                        ) : station.reservationStatus ===
                          WorkStationStatus.actual ? (
                          <div style={{ position: 'relative' }}>
                            <Station
                              id={stationId}
                              className={`${classes.stationOnmap} ${
                                (index + 1) % 2 === 1 ? 'left' : 'right'
                              } ${props.isAdmin ? 'isAdmin' : 'actual'}`}
                            ></Station>
                            <p
                              style={{
                                right: (index + 1) % 2 === 1 ? '7px' : '-7px',
                              }}
                              className={`${classes.stationOnmap} ${
                                (index + 1) % 2 === 1 ? 'right' : 'left'
                              } ${classes.stationText}`}
                            >
                              {floorId + stationId}
                            </p>
                          </div>
                        ) : (
                          <div style={{ position: 'relative' }}>
                            <Station
                              id={stationId}
                              className={`${classes.stationOnmap} ${
                                (index + 1) % 2 === 1 ? 'left' : 'right'
                              }`}
                            ></Station>
                            <p
                              style={{
                                right: (index + 1) % 2 === 1 ? '7px' : '-7px',
                              }}
                              className={`${classes.stationOnmap} ${
                                (index + 1) % 2 === 1 ? 'right' : 'left'
                              } ${classes.stationText}`}
                            >
                              {floorId + stationId}
                            </p>
                          </div>
                        )}
                      </button>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <button
            className={`${classes.mapArrow} ${
              props.isAdmin ? 'isAdmin' : null
            }`}
            onClick={pushLeft}
          >
            <img
              src={mapBtnLeft}
              className={`${classes.mapArrowIcon} ${
                props.isAdmin ? 'isAdmin' : null
              }`}
              alt="map left arrow"
            ></img>
          </button>
          <button
            className={`${`${classes.mapArrow} right`} ${
              props.isAdmin ? 'isAdmin' : null
            }`}
            onClick={pushRight}
          >
            <img
              src={mapBtnRight}
              className={`${classes.mapArrowIcon} ${
                props.isAdmin ? 'isAdmin' : null
              }`}
              alt="map right arrow"
            ></img>
          </button>
        </div>
      )}
      {props.isAdmin ? null : (
        <div className={`${classes.stationInfoDiv} ${filter ? 'active' : ''}`}>
          <p className={classes.stationInfoFont}>
            Estación{actualReservation ? ' reservada' : ''}:
          </p>
          <p className={classes.stationCodeInfoFont}>{actualStation}</p>
          {/* <p className={classes.stationInfoSecondaryFont}>
              Características tecnológicas:
            </p>
            {actualStation !== '' ? (
              <div className={classes.techFeaturesBox}>
                <label className={classes.techFeature}>Teclado</label>
                <label className={classes.techFeature}>Monitor</label>
                <label className={classes.techFeature}>Mouse</label>
                <label className={classes.techFeature}>Arturito</label>
                <label className={classes.techFeature}>Parlantes</label>
              </div>
            ) : null} This part is commented because the technological features of each station
              are not set dynamically, this will be done in the phase 2 of the project*/}
          {actualReservation ? (
            actualReservation.status === ReservationStatus.enable &&
            getItemDate(actualReservation.date).valueOf() ===
              today.valueOf() ? (
              <button
                className={`${classes.stationInfoButton}`}
                onClick={() => {
                  setOpenCheckInReservationDialog(true);
                }}
              >
                Check-in
              </button>
            ) : null
          ) : null}
          <button
            className={`${classes.stationInfoButton} ${
              actualStation !== '' ? '' : 'unavailable'
            }`}
            onClick={
              actualStation !== ''
                ? (e) => {
                    handleStationInfoClick();
                  }
                : undefined
            }
          >
            {actualReservation
              ? actualReservation.status === ReservationStatus.checked
                ? 'Check-out'
                : 'Cancelar'
              : 'Reservar'}
          </button>
        </div>
      )}
    </>
  );
};

export default StationSelection;
