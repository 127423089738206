import React from 'react';
import { useStyles } from './Page.styles';
import CheckIn from '../components/CheckIn/CheckIn';
import { IUser } from '../interfaces/IUser';

interface IProps {
  user: IUser;
}

const AdditionalWorkspace = (props: IProps) => {
  const classes = useStyles();
  return (
    <>
      <div className="full">
        <div className={classes.mainTitleDivSlim}>
          <h1 className={classes.titleFont}>Check In</h1>
        </div>
        <CheckIn isAdmin={false} user={props.user} />
      </div>
    </>
  );
};

export default AdditionalWorkspace;
