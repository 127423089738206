import React, { useEffect, useState } from 'react';
import { useStyles } from './CheckIn.styles';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import DialogBox from '../../components/DialogBox/DialogBox';
import dayjs from 'dayjs';
import VisitForm from '../VisitForm/VisitForm';
import { getLabel } from '../../helpers/labels';
import { IWorkstation, IWorkstationlist } from '../../interfaces/IWorkstation';
import { IUser } from '../../interfaces/IUser';
import { IReservation, ReservationStatus } from '../../interfaces/IReservation';
import { IReservationData } from '../../interfaces/IReservationData';
import parkingPolicy from '../../assets/docs/PoliticaParqueosWT-CostaRica.pdf';

interface IProps {
  isAdmin: boolean;
  user: IUser;
}

const CheckIn = (props: IProps) => {
  const classes = useStyles();
  const actualDate = new Date();
  const [actualWorkstation, setActualWorkstation] = useState<
    IWorkstation | undefined
  >(undefined);
  const [actualReservation, setActualReservation] = useState<
    IReservation | undefined
  >(undefined);
  const [checked, setChecked] = useState(false);

  //Covid Dialog
  const [openDialog, setOpen] = useState(false);
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [hasTraveledCountry, setHastraveledCountry] = useState(false);
  const [traveledTo, setTraveledTo] = useState('');
  const [tripReturnDate, setTripReturnDate] = useState('');
  const [contactWithASuspect, setContactWithASuspect] = useState(false);
  const [anySymptom, setAnySymptom] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [vaccinationSchemeComplete, setVaccinationSchemeComplete] =
    useState(true);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [DBIsSuccess, setDBIsSucces] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const successMessage = getLabel('checkInMessageSuccess');
  const [parking, setParking] = useState('No');
  const [needsParking, setNeedsParking] = useState(false);

  const workstationQuery = gql`
    query ($areaId: Float!) {
      workstations: getWorkstationsByArea(areaId: $areaId) {
        id
        floorId
        tableId
        status
        areaId
      }
    }
  `;

  const areaQuery = gql`
    query ($pAreaId: Float!) {
      areas: getAreaById(id: $pAreaId) {
        name
        id
      }
    }
  `;

  const reservationsQuery = gql`
    query ($dateParam: String!, $pAreaId: Float!) {
      reservations: getReservationByDateAndArea(
        dateParam: $dateParam
        pAreaId: $pAreaId
      ) {
        id
        userEmail
        date
        status
        workstationreservation {
          reservationId
          workstationId
          tableId
          areaId
        }
      }
    }
  `;

  const saveReservationsQuery = gql`
    mutation (
      $workstationId: String!
      $tableId: String!
      $floorId: Int!
      $areaId: Int!
      $userEmail: String!
      $date: DateTime!
      $fullName: String!
      $phoneNumber: String!
      $traveledTo: String!
      $tripReturnDate: String!
      $status: Boolean!
      $needsParking: Boolean!
    ) {
      reservation: createWorkstationReservation(
        workstationReservation: {
          workstationId: $workstationId
          tableId: $tableId
          floorId: $floorId
          areaId: $areaId
          status: $status
        }
        reservation: {
          userEmail: $userEmail
          date: $date
          fullName: $fullName
          phoneNumber: $phoneNumber
          traveledTo: $traveledTo
          tripReturnDate: $tripReturnDate
          status: 2
          needsParking: $needsParking
        }
      ) {
        reservationId
      }
    }
  `;

  const [getreservationsQuery, reservationsQ] = useLazyQuery<IReservationData>(
    reservationsQuery,
    {
      fetchPolicy: 'network-only',
      variables: {
        pAreaId: props.user.areaId,
        dateParam: dayjs(actualDate).format('YYYY/MM/DD'),
      },
    }
  );

  const [getDepartment, departmentName] = useLazyQuery(areaQuery, {
    variables: { pAreaId: props.user.areaId },
  });

  const [getAllWorkStation, allWorkStation] = useLazyQuery<IWorkstationlist>(
    workstationQuery,
    {
      variables: { areaId: props.user.areaId },
    }
  );

  const [addReservation, reservationResult] = useMutation(
    saveReservationsQuery,
    {
      awaitRefetchQueries: true,
      variables: {
        workstationId: actualWorkstation?.id,
        tableId: actualWorkstation?.tableId,
        floorId: actualWorkstation?.floorId,
        areaId: actualWorkstation?.areaId,
        userEmail: props.user.email,
        date: dayjs(actualDate).format('YYYY/MM/DD'),
        fullName: fullName,
        phoneNumber: phoneNumber,
        traveledTo: traveledTo,
        tripReturnDate: tripReturnDate,
        status: true,
        needsParking: needsParking,
      },
      refetchQueries: [
        {
          query: reservationsQuery,
          variables: {
            pAreaId: props.user.areaId,
            dateParam: dayjs(actualDate).format('YYYY/MM/DD'),
          },
        },
        {
          query: workstationQuery,
          variables: { areaId: props.user.areaId },
        },
      ],
    }
  );

  const visitFormComplete = () => {
    if (
      vaccinationSchemeComplete &&
      agreement &&
      !anySymptom &&
      !contactWithASuspect &&
      !hasTraveledCountry
    ) {
      return true;
    }
    return false;
  };

  const handleClose = () => {
    setOpen(false);
    setHastraveledCountry(false);
    setTraveledTo('');
    setTripReturnDate('');
    setContactWithASuspect(false);
    setAnySymptom(false);
    setAgreement(false);
    setVaccinationSchemeComplete(true);
  };

  const saveReservation = () => {
    if (actualWorkstation) {
      addReservation();
    }
  };

  const handleClick = (workstation: IWorkstation) => {
    if (props.user) {
      setActualWorkstation(workstation);
      setOpen(true);
    }
  };

  const confirmReservation = (message: string, state: boolean) => {
    setDialogMessage(message);
    setDBIsSucces(state);
    setShowErrorMessage(state);
    handleClose();
  };

  const isBooking = (workstation: IWorkstation) => {
    if (reservationsQ) {
      const reservation = reservationsQ.data?.reservations?.find(
        (element) =>
          element.workstationreservation.workstationId === workstation?.id &&
          element.workstationreservation.tableId === workstation?.tableId
      );
      return reservation?.status === 2;
    }
  };

  const isChecked = () => {
    setChecked(false);
    if (reservationsQ) {
      const reservation = reservationsQ.data?.reservations?.find(
        (element) => element.userEmail === props.user.email
      );
      if (reservation?.status === ReservationStatus.checked) {
        setChecked(true);
      }
    }
  };

  const handleChangeParking = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParking((event.target as HTMLInputElement).value);
    const value =
      (event.target as HTMLInputElement).value === 'Si' ? true : false;
    setNeedsParking(value);
  };

  useEffect(() => {
    if (props.user) {
      setFullName(props.user.name);
      setPhoneNumber(props.user.phone);
      getDepartment();
    }
  }, [props.user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.user) {
      getreservationsQuery();
    }
  }, [departmentName, props.user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.user) {
      isChecked();
      getAllWorkStation();
    }
  }, [reservationsQ, props.user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setOpen(false);
    if (reservationResult.data) {
      if (reservationResult.data.reservation.reservationId === -1) {
        setShowErrorMessage(true);
        setDBIsSucces(false);
        setDialogMessage(getLabel('alreadyReserved'));
        handleClose();
        setActualReservation(undefined);
      } else {
        setActualReservation(reservationResult.data.reservation);
      }
    } else if (reservationResult.error) {
    }
  }, [reservationResult.data, reservationResult.error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (actualReservation?.reservationId !== undefined) {
      setActualReservation(undefined);
      getreservationsQuery();
      confirmReservation(successMessage, true);
    }
  }, [actualReservation]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        className={classes.dialogBox}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          classes={{ root: classes.dialogTitle }}
        >
          Detalle del Check In
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <div>
            <div className={classes.reservationDetailsDiv}>
              <DialogContentText classes={{ root: classes.dialogSubtitle }}>
                Estación:
              </DialogContentText>
              <DialogContentText classes={{ root: classes.dialogStationInfo }}>
                {`${actualWorkstation?.tableId} ${actualWorkstation?.id}`}
              </DialogContentText>
              <DialogContentText classes={{ root: classes.dialogSubtitle }}>
                Fecha:
              </DialogContentText>
              <DialogContentText classes={{ root: classes.dialogStationInfo }}>
                {actualDate.toLocaleDateString('es-CR', {
                  year: 'numeric',
                  day: 'numeric',
                  month: '2-digit',
                })}
              </DialogContentText>
              <p id="parkingLabel" className={classes.parkingLabel}>
                ¿Requiere espacio de parqueo?
              </p>
              <RadioGroup
                className={classes.fields}
                aria-labelledby="parkingLabel"
                name="parking"
                value={parking}
                onChange={handleChangeParking}
              >
                <FormControlLabel
                  className={classes.parkingFormControl}
                  value="Si"
                  control={<Radio className={classes.parkingCheckbox} />}
                  label="Si"
                />
                <FormControlLabel
                  className={classes.parkingFormControl}
                  value="No"
                  control={<Radio className={classes.parkingCheckbox} />}
                  label="No"
                />
              </RadioGroup>
              <p className={classes.parkingPolicy}>
                Los espacios de parqueo son limitados, por favor lea nuestra{' '}
                <br />
                <a href={parkingPolicy} target="_blank">
                  Política de Parqueos
                </a>
                , si usted utiliza el parqueo del sótano, por favor seleccione
                la opción "No"
              </p>
            </div>
          </div>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogButtonsContainer }}>
          <div className={classes.buttonsGap}>
            <Button
              className={`${classes.dialogButtons} ${classes.cancelButton}`}
              onClick={handleClose}
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              className={`${classes.dialogButtons} ${classes.confirmButton}`}
              onClick={saveReservation}
              color="primary"
            >
              Confirmar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <DialogBox
        message={dialogMessage}
        isSuccess={DBIsSuccess}
        show={showErrorMessage}
        hide={setShowErrorMessage}
      ></DialogBox>
      <div className={classes.container}>
        <h1 className={classes.subtitleFont}>
          {departmentName.data?.areas[0].name}
        </h1>
        {allWorkStation.data?.workstations.map((workstation) => {
          const isbook = isBooking(workstation);
          return (
            <div
              key={workstation.tableId + workstation.id}
              className={classes.checkInDiv}
            >
              <div>
                <h2 className={classes.jobFont}>
                  {workstation.tableId + workstation.id}
                </h2>
              </div>
              <div className={classes.checkInButtonDiv}>
                <Button
                  id={'bton' + workstation.tableId}
                  className={`${classes.checkInButton} ${classes.confirmButton}
                      ${isbook ? 'isbook' : ''}
                      ${checked ? 'ischeck' : ''}`}
                  onClick={() => handleClick(workstation)}
                  disabled={isbook || checked}
                >
                  {isbook ? 'Booked' : 'Check In'}
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CheckIn;
