import React, { useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { ThemeProvider } from '@material-ui/core/styles/';
import { CssBaseline } from '@material-ui/core/';
import { theme } from './App.styles';
import LoginPage from './pages/LoginPage';
import HeaderComponent from './components/Header/Header';
import NavBar from './components/Navbar/Navbar';
import MainPage from './pages/MainPage';
import MapPage from './pages/MapPage';
import CheckInShortcut from './pages/CheckInShortcut';
import IsBlockedPage from './pages/isBlockedPage';
import MyReservationsPage from './pages/MyReservationsPage';
import AdminMainPage from './adminPages/MainPage';
import WorkstationsPage from './adminPages/WorkstationsPage';
import AdminReservationsPage from './adminPages/ReservationsPage';
import AdditionalWorkspace from './pages/AdditionalWorkspace';
import NotFound from './pages/NotFound';
import { IUser } from './interfaces/IUser';
import { gql, useQuery } from '@apollo/client';
import { IFloorXUserData } from './interfaces/IFloorsXUserData';
import MeetingReservationPage from './pages/MeetingReservationPage';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CALLBACK_PATH = process.env.REACT_APP_CALLBACK_PATH;
const ISSUER = 'https://wpp.okta.com';
const SCOPES = process.env.REACT_APP_SCOPES;
const HOST = process.env.REACT_APP_HOST_URL;
const REDIRECT_URI = `${HOST}${CALLBACK_PATH}`;

if (!SCOPES || !CLIENT_ID || !CALLBACK_PATH || !ISSUER || !HOST) {
  throw new Error('All environmental variables must be set');
}

const config = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES.split(/\s+/),
};

const oktaAuth = new OktaAuth(config);

const defaultUser: IUser = {
  name: '',
  email: '',
  role: '',
  phone: '',
  areaId: 0,
  blocked: 0,
};

export const UserContext = React.createContext(defaultUser);

function App() {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };
  const [sideMenu, setSideMenu] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedFloor, setSelectedFloor] = useState(2);
  const [user, setUser] = useState<IUser>({
    name: '',
    email: '',
    role: '',
    phone: '',
    areaId: 0,
    blocked: 0,
  });
  const floorAccess = gql`
    query ($pEmail: String!) {
      floorsXUser: getUserFloors(userEmail: $pEmail) {
        floorId
      }
    }
  `;
  const answ = useQuery<IFloorXUserData>(floorAccess, {
    variables: {
      pEmail: user.email,
    },
    pollInterval: 1000,
  });
  var floors = answ.data?.floorsXUser;
  var availableFloors = [];
  for (let i = 0; floors && i < floors?.length; i++) {
    availableFloors.push(floors[i].floorId);
  }

  const navBarClick = () => {
    setSideMenu(!sideMenu);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Switch>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route path={CALLBACK_PATH} component={LoginCallback} />
          <SecureRoute path="/">
            <HeaderComponent isActive={sideMenu} handleClick={navBarClick} />
            <NavBar
              active={sideMenu}
              updateIsAdmin={setIsAdmin}
              updateIsBlocked={setIsBlocked}
              setSideMenu={navBarClick}
              updateUser={setUser}
            />
            <Switch>
              <SecureRoute exact path="/">
                <Switch>
                  {isBlocked && <IsBlockedPage></IsBlockedPage>}
                  <MainPage
                    updateSelectedDate={setSelectedDate}
                    updateSelectedFloor={setSelectedFloor}
                    availableFloors={availableFloors}
                  />
                </Switch>
              </SecureRoute>
              <SecureRoute exact path="/reservation">
                <Switch>
                  {isBlocked && <IsBlockedPage></IsBlockedPage>}
                  <MapPage
                    selectedDate={selectedDate}
                    selectedFloor={selectedFloor}
                    availableFloors={availableFloors}
                  />
                </Switch>
              </SecureRoute>
              <SecureRoute exact path="/meetingReservation">
                <Switch>
                  {isBlocked && <IsBlockedPage></IsBlockedPage>}
                  <MeetingReservationPage
                    selectedDate={selectedDate}
                    selectedFloor={selectedFloor}
                    availableFloors={availableFloors}
                  />
                </Switch>
              </SecureRoute>
              <SecureRoute path="/checkin/:reservationID">
                <UserContext.Provider value={user}>
                  <CheckInShortcut />
                </UserContext.Provider>
              </SecureRoute>
              <SecureRoute path="/checkin-workstation/:workstationId">
                <div>TODO</div>
              </SecureRoute>
              <SecureRoute exact path="/myreservations">
                <MyReservationsPage />
              </SecureRoute>
              {user?.areaId !== 0 && user?.areaId !== 1 && (
                // TODO: use Context here
                <SecureRoute exact path="/additionalWorkspace">
                  <AdditionalWorkspace user={user} />
                </SecureRoute>
              )}
              {isAdmin && (
                <Switch>
                  <SecureRoute exact path="/admin">
                    <AdminMainPage
                      handleDate={setSelectedDate}
                      currentDate={selectedDate}
                    />
                  </SecureRoute>
                  <SecureRoute exact path="/admin/reservations">
                    <AdminReservationsPage />
                  </SecureRoute>
                  <SecureRoute exact path="/admin/workstations">
                    <WorkstationsPage />
                  </SecureRoute>
                  <Route path="*">
                    <NotFound fromMain={true} />
                  </Route>
                </Switch>
              )}
              <Route path="*">
                <NotFound fromMain={true} />
              </Route>
            </Switch>
          </SecureRoute>
          <Route path="*">
            <NotFound fromMain={false} />
          </Route>
        </Switch>
      </ThemeProvider>
    </Security>
  );
}

export default App;
