import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1.25vw',
    filter: 'drop-shadow(0px 0.31vw 0.31vw rgba(33, 35, 34, 0.2))',
    border: '1px solid #d6d6d6',
    boxSizing: 'border-box',
    background: '#ffffff',
    width: '91.11vw',
    borderRadius: '3.33vw',
    paddingBottom: '6.400vw',

    [theme.breakpoints.up('medium')]: {
      width: '90vw',
      borderRadius: '0.87vw',
      paddingBottom: '3.125vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '86.45vw',
      paddingBottom: '2.500vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '89.141vw',
      paddingBottom: '1.875vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '1.45vw',
      width: '79.236vw',
      paddingBottom: '1.667vw',
    },
  },

  containerTitle: {
    display: 'flex',
    fontFamily: 'WTGothic',
    margin: 0,
    fontWeight: 300,
    letterSpacing: '0.01em',
    marginLeft: '4.44vw',
    marginTop: '4.44vw',
    width: '81.94vw',
    fontSize: '4.800vw',

    [theme.breakpoints.up('medium')]: {
      marginLeft: '2.6vw',
      marginTop: '2.08vw',
      width: '37.89vw',
      fontSize: '2.344vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '2.5vw',
      marginTop: '2.5vw',
      width: '39.58vw',
      fontSize: '1.875vw',
    },
    [theme.breakpoints.up('large')]: {
      marginTop: '1.75vw',
      marginLeft: '1.87vw',
      fontSize: '1.406vw',
      width: '90%',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '1.66vw',
      fontSize: '1.250vw',
    },
  },

  subtitlesDiv: {
    display: 'flex',
    width: 'max-content',
    marginLeft: '-0.5vw',
    height: '4.5vw',
    marginTop: '6.400vw',

    [theme.breakpoints.up('medium')]: {
      marginLeft: '6.250vw',
      height: '1.823vw',
      marginTop: '3.125vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '5vw',
      height: '1.458vw',
      marginTop: '2.500vw',
    },
    [theme.breakpoints.up('large')]: {
      marginLeft: '3.750vw',
      height: '1.094vw',
      marginTop: '1.875vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginLeft: '3.333vw',
      height: '0.972vw',
      marginTop: '1.667vw',
    },
  },

  subtitleFont: {
    fontFamily: 'WTGothic',
    fontWeight: 300,
    letterSpacing: '0.01em',
    color: theme.palette.grey2,
    fontSize: '3.200vw',
    textAlign: 'center',

    '&.first': {
      width: '29vw',

      [theme.breakpoints.up('medium')]: {
        width: '18.490vw',
      },
      [theme.breakpoints.up('tablet')]: {
        width: '14.792vw',
      },
      [theme.breakpoints.up('large')]: {
        width: '11.094vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        width: '9.861vw',
      },
    },
    '&.second': {
      width: '28vw',

      [theme.breakpoints.up('medium')]: {
        width: '15.365vw',
      },
      [theme.breakpoints.up('tablet')]: {
        width: '12.292vw',
      },
      [theme.breakpoints.up('large')]: {
        width: '9.219vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        width: '8.194vw',
      },
    },
    '&.third': {
      width: '28.5vw',

      [theme.breakpoints.up('medium')]: {
        width: '18.620vw',
      },
      [theme.breakpoints.up('tablet')]: {
        width: '14.896vw',
      },
      [theme.breakpoints.up('large')]: {
        width: '11.172vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        width: '9.931vw',
      },
    },

    [theme.breakpoints.up('medium')]: {
      fontSize: '1.563vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.250vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '0.938vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '0.833vw',
    },
  },

  reservationInfoDiv: {
    display: 'flex',
    boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
    border: '1px solid #d6d6d6',
    boxSizing: 'border-box',
    alignItems: 'center',
    width: '88vw',
    height: '17.867vw',
    borderRadius: '3.200vw',
    marginLeft: '1.5vw',
    marginTop: '2.5vw',

    [theme.breakpoints.up('medium')]: {
      width: '83vw',
      height: '8.724vw',
      borderRadius: '1.563vw',
      marginLeft: '3.125vw',
      marginTop: '2.083vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '82vw',
      height: '6.979vw',
      borderRadius: '1.250vw',
      marginLeft: '2.500vw',
      marginTop: '1.667vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '85.391vw',
      height: '5.234vw',
      borderRadius: '0.938vw',
      marginLeft: '1.875vw',
      marginTop: '1.250vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '75.903vw',
      height: '4.653vw',
      borderRadius: '0.833vw',
      marginLeft: '1.667vw',
      marginTop: '1.111vw',
    },
  },

  cornerDiv: {
    height: '100%',
    width: '0',
    backgroundColor: theme.palette.white,
    borderRadius: '3.200vw 0vw 0vw 3.200vw',

    [theme.breakpoints.up('medium')]: {
      borderRadius: '1.563vw 0vw 0vw 1.563vw',
    },
    [theme.breakpoints.up('tablet')]: {
      borderRadius: '1.250vw 0vw 0vw 1.250vw',
    },
    [theme.breakpoints.up('large')]: {
      borderRadius: '0.938vw 0vw 0vw 0.938vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      borderRadius: '0.833vw 0vw 0vw 0.833vw',
    },
  },

  reservationInfoFont: {
    fontFamily: 'WTGothic',
    fontWeight: 300,
    letterSpacing: '0.01em',
    fontSize: '4.267vw',
    marginRight: '6.400vw',
    textAlign: 'center',

    '&.first': {
      color: theme.palette.grey2,
      marginLeft: '3.400vw',

      [theme.breakpoints.up('medium')]: {
        marginLeft: '1.660vw',
      },
      [theme.breakpoints.up('tablet')]: {
        marginLeft: '1.328vw',
      },
      [theme.breakpoints.up('large')]: {
        marginLeft: '0.996vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        marginLeft: '0.885vw',
      },
    },

    [theme.breakpoints.up('medium')]: {
      fontSize: '2.083vw',
      marginRight: '3.125vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.667vw',
      marginRight: '2.500vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.250vw',
      marginRight: '1.875vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.111vw',
      marginRight: '1.667vw',
    },
  },

  monthContainer: {
    display: 'flex',
    width: 'max-content',
    alignItems: 'center',
    marginLeft: '15vw',
    marginTop: '5vw',
    columnGap: '5.499vw',

    [theme.breakpoints.up('medium')]: {
      marginLeft: '5.810vw',
      marginTop: '4.6vw',
      columnGap: '2.685vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '4.648vw',
      marginTop: '3.5vw',
      columnGap: '2.148vw',
    },
    [theme.breakpoints.up('large')]: {
      marginLeft: '3.486vw',
      marginTop: '2.722vw',
      columnGap: '1.611vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginLeft: '3.099vw',
      marginTop: '2.4vw',
      columnGap: '1.432vw',
    },
  },

  arrowContainer: {
    display: 'flex',
    backgroundColor: theme.palette.white,
    border: 0,
    top: 0,
    outline: 'none',

    '&:hover': {
      cursor: 'pointer',
    },
  },

  arrowSrc: {
    height: '3.600vw',
    width: '1.800vw',

    [theme.breakpoints.up('medium')]: {
      height: '1.758vw',
      width: '0.879vw',
    },
    [theme.breakpoints.up('tablet')]: {
      height: '1.406vw',
      width: '0.703vw',
    },
    [theme.breakpoints.up('large')]: {
      height: '1.055vw',
      width: '0.527vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      height: '0.938vw',
      width: '0.469vw',
    },
  },

  monthTitleFormat: {
    fontFamily: 'WTGothic',
    fontWeight: 300,
    letterSpacing: '0.01em',
    fontSize: '4.800vw',
    textAlign: 'center',
    width: '45vw',

    [theme.breakpoints.up('medium')]: {
      fontSize: '2.344vw',
      width: '23vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875vw',
      width: '18vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.406vw',
      width: '14vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.250vw',
      width: '12vw',
    },
  },

  separator: {
    border: '1px solid #D6D6D6',
    width: '81vw',
    marginLeft: '4.5vw',
    marginTop: '3.733vw',

    [theme.breakpoints.up('medium')]: {
      width: '78vw',
      marginLeft: '3.125vw',
      marginTop: '2.065vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '82vw',
      marginLeft: '2.500vw',
      marginTop: '1.458vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '85.391vw',
      marginLeft: '1.875vw',
      marginTop: '1.094vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '75.903vw',
      marginLeft: '1.667vw',
      marginTop: '0.972vw',
    },
  },
}));
