import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backdropFilter: "blur(2px)",
    backgroundColor: theme.palette.whiteRGB,
    width: '100%',
    height: '100%',
  },

  dialogBox: {
    '& .MuiPaper-root': {
      width: '87.467vw',
      marginLeft: '4vw',

      [theme.breakpoints.up('medium')]: {
        width: '83.333vw',
        marginLeft: '4vw',
      },
      [theme.breakpoints.up('tablet')]: {
        width: '56.250vw',
        marginLeft: '3vw',
      },
      [theme.breakpoints.up('large')]: {
        width: '39.375vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        width: '35vw',
      },
    },
  },

  dialogTitle: {
    '& .MuiTypography-h6': {
      fontFamily: 'WTGothic',
      fontSize: '1.1rem',
      fontWeight: 400,
    }
  },

  dialogSubtitle: {
    fontFamily: 'WTGothic',
    fontSize: '1.1rem',
    fontWeight: 300,
  },

  dialogButtons: {
    fontFamily: 'WTGothic',
    fontSize: '1rem',
    width: '13.25rem',
    height: '3rem',
    borderRadius: '12px',
    padding: '2vw',
    textTransform: 'capitalize',

    '&.unavailable': {
      opacity: 0.4,
    },
  },

  confirmButton: {
    backgroundColor: theme.palette.lightOrange,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.lightOrange,
      color: theme.palette.white,
    }
  },

  dialogButtonsContainer: {
    justifyContent: 'flex-end',
    padding: '1rem',
  },

  textfield: {
    fontFamily: 'WTGothic',
    fontSize: '1.1rem',
    fontWeight: 300,
    marginTop: '0.1rem',
  },

}));