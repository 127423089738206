import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

const LoginPage = () => {
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    oktaAuth.signInWithRedirect({ originalUri: '/' });
  }, [oktaAuth]);

  return (
    <>
      <div className="App">
        <header className="App-header">
          Redireccionando a la página de inicio de sesión con okta
        </header>
      </div>
    </>
  );
};

export default LoginPage;
