import { createTheme } from '@material-ui/core/styles/';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    medium: true;
    tablet: true;
    large: true;
    extraLarge: true;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    grey1: React.CSSProperties['color'];
    orange1: React.CSSProperties['color'];
    blue1: React.CSSProperties['color'];
    purple1: React.CSSProperties['color'];
    white: React.CSSProperties['color'];
    cyanRGB: React.CSSProperties['color'];
    redRGB: React.CSSProperties['color'];
    black: React.CSSProperties['color'];
    customizeBlueRGB: React.CSSProperties['color'];
    pink: React.CSSProperties['color'];
    wheat: React.CSSProperties['color'];
    lightGrey: React.CSSProperties['color'];
    tomato: React.CSSProperties['color'];
    turquoise: React.CSSProperties['color'];
    darkGreyRGB: React.CSSProperties['color'];
    oceanBlue: React.CSSProperties['color'];
    lightOrange: React.CSSProperties['color'];
    whiteRGB: React.CSSProperties['color'];
    grey2: React.CSSProperties['color'];
    peach: React.CSSProperties['color'];
    green: React.CSSProperties['color'];
  }
  interface PaletteOptions {
    grey1: React.CSSProperties['color'];
    orange1: React.CSSProperties['color'];
    blue1: React.CSSProperties['color'];
    purple1: React.CSSProperties['color'];
    white: React.CSSProperties['color'];
    cyanRGB: React.CSSProperties['color'];
    redRGB: React.CSSProperties['color'];
    black: React.CSSProperties['color'];
    customizeBlueRGB: React.CSSProperties['color'];
    pink: React.CSSProperties['color'];
    wheat: React.CSSProperties['color'];
    lightGrey: React.CSSProperties['color'];
    tomato: React.CSSProperties['color'];
    turquoise: React.CSSProperties['color'];
    darkGreyRGB: React.CSSProperties['color'];
    oceanBlue: React.CSSProperties['color'];
    lightOrange: React.CSSProperties['color'];
    whiteRGB: React.CSSProperties['color'];
    grey2: React.CSSProperties['color'];
    peach: React.CSSProperties['color'];
    green: React.CSSProperties['color'];
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      medium: 768,
      tablet: 960,
      large: 1280,
      extraLarge: 1440,
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
  palette: {
    grey1: '#828282',
    orange1: '#ec4e20',
    blue1: '#0a47ed',
    purple1: '#8884d8',
    white: '#ffffff',
    cyanRGB: 'rgba(0, 209, 217, 0.94)',
    redRGB: 'rgba(242, 27, 27, 0.94)',
    darkGreyRGB: 'rgba(33, 35, 34, 0.2)',
    black: '#000000',
    customizeBlueRGB: 'rgba(10, 71, 237, 0.6)',
    pink: '#E661B2',
    wheat: 'wheat',
    lightGrey: '#D6D6D6',
    tomato: '#EC4E20',
    turquoise: '#00D1D9',
    oceanBlue: '#0a47ed',
    lightOrange: '#ff760c',
    whiteRGB: 'rgba(255,255,255,0.2)',
    grey2: '#525252',
    peach: 'rgba(236, 78, 32, 0.6)',
    green: '#8bc34a',
  },
});

theme.overrides = {
  MuiCssBaseline: {
    '@global': {
      body: {
        backgroundColor: 'white',
      },
      ul: {
        padding: 0,
        margin: 0,
        border: 0,
      },
      div: {
        padding: 0,
        margin: 0,
        border: 0,
      },
      nav: {
        padding: 0,
        margin: 0,
        border: 0,
      },
      '@media (prefers-reduced-motion: no-preference)': {
        '.App-logo': {
          animation: 'App-logo-spin infinite 20s linear',
        },
      },
      '.App-link': {
        color: '#61dafb',
      },
      '.full': {
        position: 'relative',
        height: '100%',
        width: '100%',
        paddingTop: '10%',
        [theme.breakpoints.up('medium')]: {
          paddingTop: 'unset',
        },
        [theme.breakpoints.up('tablet')]: {},
        [theme.breakpoints.up('large')]: {},
        [theme.breakpoints.up('extraLarge')]: {},
      },
      '#root': {
        position: 'relative',
        height: '100%',
        width: '100%',
      },
      '.App': {
        textAlign: 'center',
      },
      '.link-cta': {
        width: '80%',
        backgroundColor: '#900',
        padding: '14px',
        margin: '20px',
      },
      '.title-text': {
        fontFamily: 'WTGothic, Arial',
        fontWeight: 700,
      },
      '@keyframes App-logo-spin': {
        from: {
          transform: 'rotate(0deg)',
        },
        to: {
          transform: 'rotate(360deg)',
        },
      },
    },
  },
};
