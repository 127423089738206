import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { useStyles } from './WorkStationPageSkeleton.styles';

const WorkStationPageSkeleton = () => {
    const classes = useStyles();
    return(
        <>
            <div >
                <Skeleton className={classes.mainTitleDiv} variant="text"  />
                <div className={classes.containerDiv}>
                    
                   <Skeleton className={classes.floorOption} variant="rect"/>
                   <Skeleton className={classes.stationInfoContainer} variant="rect"/>
                   <Skeleton className={classes.stationInfoContainer} variant="rect"/>
                   <Skeleton className={classes.stationInfoContainer} variant="rect"/>
                   <Skeleton className={classes.stationInfoContainer} variant="rect"/>
                   <Skeleton className={classes.stationInfoContainer} variant="rect"/>
                   <Skeleton className={classes.stationInfoContainer} variant="rect"/>
                </div>
            </div>
        </>
    );
};

export default WorkStationPageSkeleton;