import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    icon : {
        width: '36px',
        height: '36px',
        fill:theme.palette.white,
        color: theme.palette.white,
        padding: 0,
        margin: 0,
    },

    message: {
        fontFamily: 'WTGothic',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '3.2vw',
        color: theme.palette.white,
        maxWidth: '85vw',
        wordWrap: 'break-word',
        paddingLeft: '2vw',

        [theme.breakpoints.up('medium')]: {
            fontSize: '2.344vw',
        },
        [theme.breakpoints.up('tablet')]: {
            fontSize: '1.875vw',
        },
        [theme.breakpoints.up('large')]: {
            fontSize: '1.3vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            fontSize: '1.250vw',
        },
    },

    div_message: {
        display: 'flex',
        alignItems: 'center',
    },

    dialog_box : {
        width: '95vw',
        margin: '0 auto',
        marginTop: '31vw',

        [theme.breakpoints.up('medium')]: {
            marginTop: '18vw',
        },
        [theme.breakpoints.up('tablet')]: {
            marginTop: '15vw',
        },
        [theme.breakpoints.up('large')]: {
            marginTop: '12vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '81vw',
            marginLeft: '17vw',
            marginTop: '10.5vw',
        },
    },


    successDialog : {
        color:theme.palette.white,
        background:theme.palette.cyanRGB,
        backdropFilter: 'blur(1.5px)'
    },
    failDialog: {
        color:theme.palette.white,
        background:theme.palette.redRGB,
        backdropFilter: 'blur(1.5px)'
    },
}));