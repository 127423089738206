import React from "react";
import { useStyles } from './CircularProgressCard.styles';
import CircularProgressComponent from "../CircularProgress/CircularProgress";

interface IProps {
    title: string;
    type: number;
    maxCapacity: number;
    percentageOcuppied: number;
}

const CircularProgressCardComponent = (props: IProps) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.progressBarContainer}>
                <div className={classes.subtitleFont}>{props.title}</div>
                <div className={classes.CircularContainerFrame}>
                    <CircularProgressComponent
                        type={props.type}
                        maxCapacity={props.maxCapacity}
                        percentageOcuppied={props.percentageOcuppied}
                        userType={'admin'}
                    />
                </div>
            </div>
        </>
    );
};

export default CircularProgressCardComponent;