import React, { useEffect, useState } from 'react';
import ReservationFilter from '../components/ReservationFilter/ReservationFilter';
import SidebarOccupationComponent from '../components/SidebarOccupation/SidebarOccupation';
import RegisterDialogBox from '../components/RegisterDialogBox/RegisterDialogBox';
import DialogBox from '../components/DialogBox/DialogBox';
import { useStyles } from './Page.styles';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { IMapPageData } from '../interfaces/IMapPage';
import { IFloor } from '../interfaces/IFloor';
import { IReservationData } from '../interfaces/IReservationData';
import { useHistory, useLocation } from 'react-router-dom';

export interface LocationParams {
  message: string;
  isSuccess: boolean;
  pathname: string;
  state: object;
}

interface IProps {
  updateSelectedDate: (date: Date) => void;
  updateSelectedFloor: (floor: number) => void;
  availableFloors: number[];
}

const MainPage = (props: IProps) => {
  const classes = useStyles();
  const location = useLocation<LocationParams>();
  const history = useHistory();
  const { updateSelectedDate } = props;
  const { updateSelectedFloor } = props;
  const [floorId, setFloorId] = useState(props.availableFloors[0]);
  const [workstationsCapacity, setWorkstationsCapacity] = useState(0);
  const [selectedDate, setselected] = useState(new Date());
  const [selectedFloor, setSelectedFloor] = useState<IFloor>();
  const [state, setState] = useState<LocationParams>();
  const [showDialogBox, setShowDialogBox] = useState(false);

  useEffect(() => {
    setState({ ...location.state });
    location.state ? setShowDialogBox(true) : setShowDialogBox(false);
    history.replace(location.pathname, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const floorsQuery = gql`
    query {
      floors: getAllFloors {
        id
        floorCapacity
        acceptedCapacity
        tables {
          id
          floorId
          workstations {
            id
            status
            floorId
            tableId
          }
        }
      }
    }
  `;

  const reservationsQuery = gql`
    query ($dateParam: String!, $pFloorId: Float!, $pAreaId: Float!) {
      reservations: getReservationByDateAndFloorAndArea(
        dateParam: $dateParam
        pFloorId: $pFloorId
        pAreaId: $pAreaId
      ) {
        id
        userEmail
        date
        workstationreservation {
          workstationId
        }
      }
    }
  `;

  const floorsData = useQuery<IMapPageData>(floorsQuery, {
    pollInterval: 3000,
  });
  const [getReservationsData, reservationsData] =
    useLazyQuery<IReservationData>(reservationsQuery, {
      fetchPolicy: 'network-only',
      variables: { dateParam: selectedDate, pFloorId: floorId, pAreaId: 1 },
    });

  useEffect(() => {
    getReservationsData();
  }, [selectedDate, getReservationsData]);

  useEffect(() => {
    updateSelectedDate(selectedDate);
  }, [selectedDate, updateSelectedDate]);

  useEffect(() => {
    let selectedFloorId = selectedFloor ? selectedFloor.id : 2;
    updateSelectedFloor(selectedFloorId);
  }, [selectedFloor, updateSelectedFloor]);

  useEffect(() => {
    floorsData.data &&
      floorsData.data.floors &&
      setSelectedFloor(
        floorsData.data.floors.find((floor) => floor.id === floorId)
      );
  }, [floorsData.data, floorId]);

  useEffect(() => {
    if (selectedFloor) {
      const totalWorkstations = selectedFloor.tables.reduce((total, table) => {
        return total + table.workstations.length;
      }, 0);

      setWorkstationsCapacity(
        Math.trunc(totalWorkstations * (selectedFloor?.acceptedCapacity / 100))
      );
    }
  }, [selectedFloor]);

  return (
    <>
      <RegisterDialogBox />
      <div className="full">
        <div className={classes.mainTitleDivSlim}>
          <h1 className={classes.titleFont}>Nueva Reservación</h1>
        </div>
        <DialogBox
          message={state ? state.message : ''}
          isSuccess={state ? state.isSuccess : true}
          show={showDialogBox}
          hide={setShowDialogBox}
        ></DialogBox>
        <SidebarOccupationComponent
          maxCapacity={50}
          percentageOcuppied={35}
          workMaxCapacity={workstationsCapacity}
          workPercentageOcuppied={
            reservationsData.data !== undefined
              ? reservationsData.data.reservations.length
              : -1
          }
          meetingMaxCapacity={4}
          meetingPercentageOccupied={0}
          updateSelectedFloor={setFloorId}
          updateSelectedDate={setselected}
          availableFloors={props.availableFloors}
        />
        <ReservationFilter selectedDate={selectedDate} />
      </div>
    </>
  );
};

export default MainPage;
