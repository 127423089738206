import React, { useEffect, useState } from 'react';
import { useStyles } from './Page.styles';
import { gql, useQuery, useMutation } from '@apollo/client';
import WorkstationsListComponent from '../components/WorkstationsList/WorkstationsList';
import { IFloor } from '../interfaces/IFloor';
import { IMapPageData } from '../interfaces/IMapPage';
import { IWorkstation, WorkStationStatus } from '../interfaces/IWorkstation';
import { IReservationData } from '../interfaces/IReservationData';
import { IReservation } from '../interfaces/IReservation';
import WorkStationPageSkeleton from '../components/WorkStationPageSkeleton/WorkStationPageSkeleton';

const WorkstationsPage = () => {
  const classes = useStyles();
  const [floorId, setFloorId] = useState(2);
  const [selectedFloor, setSelectedFloor] = useState<IFloor>();
  const [workstations, setWorkstations] = useState<IWorkstation[]>([]);
  const [reservations, setReservations] = useState<IReservation[]>([]);

  const floorsQuery = gql`
    query {
      floors: getAllFloors {
        id
        floorCapacity
        acceptedCapacity
        floorName
        tables {
          id
          floorId
          workstations {
            id
            status
            floorId
            tableId
          }
        }
      }
    }
  `;

  const nextReservationsQuery = gql`
    query {
      reservations: getNextReservations {
        date
        workstationreservation {
          tableId
          workstationId
          floorId
        }
      }
    }
  `;

  const updateWorkstationQuery = gql`
    mutation (
      $id: String!
      $tableId: String!
      $status: Boolean!
      $floorId: Int!
    ) {
      updateWorkstation(
        workstation: {
          id: $id
          tableId: $tableId
          status: $status
          floorId: $floorId
        }
      ) {
        id
        tableId
        floorId
      }
    }
  `;

  const floorsData = useQuery<IMapPageData>(floorsQuery, {
    pollInterval: 5000,
  });
  const { loading, data } = useQuery<IReservationData>(nextReservationsQuery, {
    pollInterval: 10000,
  });
  const [updateWorkstation] = useMutation(updateWorkstationQuery, {
    refetchQueries: [
      {
        query: floorsQuery,
      },
    ],
  });

  const callUpdateWorkstationsFunction = (
    workstationId: string,
    tableId: string,
    floorId: number,
    status: boolean
  ) => {
    const updatedWorkstations = workstations.map((station) => {
      let newStatus = station.status;
      if (
        station.id === workstationId &&
        station.tableId === tableId &&
        station.floorId === floorId
      ) {
        newStatus = status;
      }
      const newValues: IWorkstation = {
        id: station.id,
        floorId: station.floorId,
        status: newStatus,
        tableId: station.tableId,
        reservationStatus: WorkStationStatus.available,
      };
      return newValues;
    });
    setWorkstations(updatedWorkstations);
    updateWorkstation({
      variables: {
        id: workstationId,
        tableId: tableId,
        status: status,
        floorId: floorId,
      },
    });
  };

  useEffect(() => {
    floorsData.data &&
      floorsData.data.floors &&
      setSelectedFloor(
        floorsData.data.floors.find((floor) => floor.id === floorId)
      );
  }, [floorsData.data, floorId]);

  useEffect(() => {
    if (data) {
      setReservations(data.reservations);
    }
  }, [data]);

  useEffect(() => {
    if (selectedFloor) {
      let totalWorkstations: IWorkstation[] = [];
      selectedFloor.tables.forEach((table) => {
        table.workstations.forEach((ws) => {
          totalWorkstations.push(ws);
        });
      });
      setWorkstations(totalWorkstations);
    }
  }, [selectedFloor]);

  if (loading) {
    return (
      <div>
        <WorkStationPageSkeleton />
      </div>
    );
  }

  return (
    <>
      <div className="full">
        <div className={classes.mainTitleDivSlim}>
          <h1 className={`${classes.titleFont} workstations`}>
            Estaciones de trabajo
          </h1>
        </div>
        <div className={classes.divFloorSelection}>
          <div className={classes.floorSelection}>
            <div
              className={`${classes.floorOption} ${
                floorId === 2 ? 'active' : ''
              }`}
              onClick={() => {
                setFloorId(2);
              }}
            >
              Segundo piso
            </div>
            <div
              className={`${classes.floorOption} right ${
                floorId === 3 ? 'active' : ''
              }`}
              onClick={() => {
                setFloorId(3);
              }}
            >
              Tercer piso
            </div>
          </div>
        </div>
        <WorkstationsListComponent
          workstations={workstations}
          reservations={reservations}
          updateWorkstation={callUpdateWorkstationsFunction}
          selectedFloor={selectedFloor}
        />
      </div>
    </>
  );
};

export default WorkstationsPage;
