import React from "react";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { ReactComponent as Arrow} from '../../assets/images/chevron-bottom.svg';
import './FilterSelect.css';
import { useStyles } from './FilterSelect.styles';


export interface ISelectOption{
    value?:string;
    text:string;
}
interface IProps {
    value?: string;
    options?: ISelectOption[];
    disabled?: boolean;
    handleSelect: (value:any) => void;
  }

const FilterSelect = (props: IProps) => {
    const classes = useStyles();
    // Get data
    const { value, options } = props;
    const handleChange = (e: React.SyntheticEvent<EventTarget>) => {
        props.handleSelect((e.target as HTMLSelectElement).value);
    }

    return (
        <>
            <div className={classes.dateInput2}>
                <FormControl>
                    <Select 
                        className={`${classes.inputText2} floor`}
                        value={value || ""} 
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'hora' }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                        disabled={props.disabled}
                    >
                        {<MenuItem className={classes.placeHolder2} value="">
                            Seleccione una opción
                        </MenuItem>}
                        {options?.length && options.map((option: ISelectOption,index: number) => (
                            <MenuItem 
                                key={index} 
                                className={`${classes.floorItem2} first`}
                                value={option.value}
                            >{option.text}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Arrow className={`${classes.inputIcon2} floor-icon2`}/>
            </div>
        </>
    )
}
// const militarToStandard = (hours: number) =>{ //Revisar esto
//     var timeValue;
//     if (hours > 0 && hours <= 12) {
//     timeValue= "" + hours;
//     } else if (hours > 12) {
//     timeValue= "" + (hours - 12);
//     }
//     timeValue += (hours >= 12) ? " p.m." : " a.m.";  // get AM/PM
//     return timeValue;
// }

export default FilterSelect;