interface ILabel {
  labelId: string;
  labelText: string;
}

const labels: ILabel[] = [
  {
    labelId: 'alreadyReserved',
    labelText:
      'Lo sentimos. La estación que intentas reservar, ya fue reservada por otra persona. Intenta con otra estación u otra fecha distinta.',
  },
  {
    labelId: 'fullCapacity',
    labelText:
      'Lo sentimos, la capacidad aceptada para este día ya ha sido completada. Intenta con otro día.',
  },
  {
    labelId: 'reservationSuccessMessage',
    labelText:
      'Reservación realizada satisfactoriamente. Puedes encontrar la información de tu nueva reservación en la página de "Mis reservaciones", en la sección de "Próximas reservaciones"',
  },
  {
    labelId: 'reservationErrorMessage',
    labelText:
      'Lo sentimos, hubo un error y no pudimos guardar tu reservación, intenta de nuevo.',
  },
  {
    labelId: 'lightOccupation',
    labelText: 'Ocupación estable',
  },
  {
    labelId: 'mediumOccupation',
    labelText: 'Acercándose a la ocupación máxima',
  },
  {
    labelId: 'maxOccupation',
    labelText: 'Ocupación máxima alcanzada',
  },
  {
    labelId: 'floorOccupationChangedSuccess',
    labelText: 'El aforo permitido en el piso ha sido cambiado con éxito.',
  },
  {
    labelId: 'floorOccupationChangedError',
    labelText:
      'Lo sentimos. No se pudo cambiar aforo permitido en el piso. Intenta de nuevo.',
  },
  {
    labelId: 'cancelReservationMessageFailed',
    labelText:
      'Lo sentimos, hubo un error y no pudimos cancelar tu reservación, intenta de nuevo.',
  },
  {
    labelId: 'cancelReservationMessageSuccess',
    labelText: 'Reservación cancelada con éxito.',
  },
  {
    labelId: 'createUserMessageSuccess',
    labelText: 'Registro completado con éxito.',
  },
  {
    labelId: 'createUserMessageFailed',
    labelText: 'El registro no pudo ser completado, intenta de nuevo.',
  },
  {
    labelId: 'checkInMessageFailed',
    labelText:
      'Lo sentimos, hubo un error y no pudimos realizar el check-in, intenta de nuevo.',
  },
  {
    labelId: 'checkInMessageSuccess',
    labelText: 'Check-in realizado con éxito.',
  },
  {
    labelId: 'checkOutMessageFailed',
    labelText:
      'Lo sentimos, hubo un error y no pudimos realizar el check-in, intenta de nuevo.',
  },
  {
    labelId: 'checkOutMessageSuccess',
    labelText: 'Check-out realizado con éxito.',
  },
];

export const getLabel = (id: string): string => {
  const label = labels.find((label) => label.labelId === id);

  if (label) {
    return label.labelText;
  }

  return 'Missing Label';
};
