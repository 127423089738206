import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mainTitleDivSlim: {
    display: 'block',
    boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
    height: '17.77vw',
    width: '100vw',
    marginLeft: 0,
    marginTop: '32px',

    [theme.breakpoints.up('medium')]: {
      marginTop: '80px',
      height: '8.85vw',
      width: '100vw',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '80px',
      width: '100vw',
      height: '7.08vw',
    },
    [theme.breakpoints.up('large')]: {
      marginTop: '6.25vw',
      width: '100vw',
      height: '5.23vw',
      marginLeft: 'unset',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '5.55vw',
      width: '83.68vw',
      height: '4.65vw',
      marginLeft: '16.30vw',
    },
  },

  titleFont: {
    fontFamily: 'WTGothic',
    fontWeight: 400,
    color: theme.palette.black,
    letterSpacing: '0.01em',
    width: '76.8vw',
    fontSize: '6.93vw',
    marginTop: '4.26vw',
    marginLeft: '4.26vw',
    marginBottom: '1.04vw',
    float: 'left',

    '&.workstations': {
      fontSize: '6.1vw',
      [theme.breakpoints.up('medium')]: {
        fontSize: '3.385vw',
      },
      [theme.breakpoints.up('tablet')]: {
        fontSize: '2.708vw',
      },
      [theme.breakpoints.up('large')]: {
        fontSize: '2.031vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        fontSize: '1.806vw',
      },
    },

    '&.date': {
      fontSize: '6.933vw',

      [theme.breakpoints.up('medium')]: {
        fontSize: '3.385vw',
      },
      [theme.breakpoints.up('tablet')]: {
        fontSize: '2.708vw',
      },
      [theme.breakpoints.up('large')]: {
        fontSize: '2.031vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        marginLeft: '18.45vw',
        fontSize: '1.806vw',
      },
    },

    [theme.breakpoints.up('medium')]: {
      width: '70vw',
      fontSize: '3.90vw',
      marginTop: '2.08vw',
      marginLeft: '2.15vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '3.12vw',
      marginTop: '1.66vw',
      float: 'unset',
      width: 'unset',
    },
    [theme.breakpoints.up('large')]: {
      marginTop: '1.40vw',
      marginLeft: '10.078vw',
      fontSize: '2.34vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '2.08vw',
      marginTop: '1.11vw',
      marginLeft: '2.15vw',
    },
  },

  floorDateDiv: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '4.26vw',
    paddingBottom: '1.35vh',
    paddingTop: '3vh',

    [theme.breakpoints.up('medium')]: {
      marginLeft: '2.15vw',
      paddingBottom: '1.35vh',
      paddingTop: '2vh',
    },
    [theme.breakpoints.up('tablet')]: {
      width: 'unset',
      marginLeft: '2.15vw',
      paddingBottom: '1vh',
      paddingTop: '1vh',
    },
    [theme.breakpoints.up('large')]: {
      marginLeft: '10.078vw',
      paddingTop: '0vh',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginLeft: '17.87vw',
    },
  },

  dateFont: {
    fontFamily: 'WTGothic',
    fontWeight: 400,
    margin: 0,
    fontSize: '4.26vw',
    height: '5.33vw',
    lineHeight: '4.26vw',

    [theme.breakpoints.up('medium')]: {
      fontSize: '2.08vw',
      height: '2.60vw',
      lineHeight: '2.60vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.66vw',
      height: '1.56vw',
      lineHeight: '1.56vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.25vw',
      height: '1.56vw',
      lineHeight: '1.56vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.11vw',
      height: '1.38vw',
      lineHeight: '1.38vw',
    },
  },

  dateDiv: {
    width: '41.06vw',
    float: 'left',
    marginBottom: '9.45vw',

    [theme.breakpoints.up('medium')]: {
      width: '39.19vw',
      marginTop: '2.08vw',
      float: 'left',
      marginBottom: '3.1vw',
    },

    [theme.breakpoints.up('tablet')]: {
      width: '17.5vw',
      marginTop: '1.66vw',
      marginBottom: '0.78vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '13.12vw',
      marginTop: '1.25vw',
      marginBottom: '0',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '11.66vw',
      marginTop: '1.11vw',
    },
  },

  dateInput: {
    marginTop: '3.2vw',
    position: 'relative',
    width: '10vw',
    height: '3.75vw',

    [theme.breakpoints.up('medium')]: {
      marginTop: '1.6vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginTop: '1.35vw',
    },
    [theme.breakpoints.up('large')]: {
      marginTop: '0.95vw',
      marginBottom: '0',
      height: '3vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '0.9vw',
    },
  },

  divFloorSelection: {
    margin: '5% 0 3% 4.7%',
    [theme.breakpoints.up('medium')]: {
      margin: '3% 0 3% 2%',
    },
    [theme.breakpoints.up('tablet')]: {},
    [theme.breakpoints.up('large')]: {
      margin: '2% 0 1% 18.5%',
    },
    [theme.breakpoints.up('extraLarge')]: {},
  },

  floorSelection: {
    display: 'flex',
    width: '90%',
  },

  floorOption: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.oceanBlue,
    fontFamily: 'WTGothic',
    fontSize: '3.200vw',
    fontStyle: 'normal',
    border: '1px solid #0A47ED',
    boxSizing: 'border-box',
    borderRadius: '7.595vw 0vw 0vw 7.595vw',
    alignItems: 'center',
    textAlign: 'center',
    width: '39.371vw',
    height: '8.533vw',
    '&.right': {
      borderRadius: '0vw 7.595vw 7.595vw 0vw',
    },

    '&.active': {
      backgroundColor: theme.palette.oceanBlue,
      color: theme.palette.white,
    },

    '&:hover': {
      cursor: 'pointer',
    },

    [theme.breakpoints.up('medium')]: {
      fontSize: '1.563vw',
      width: '29.680vw',
      height: '5.167vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.250vw',
      width: '20.730vw',
      height: '3.933vw',
      marginTop: '1.2vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '0.938vw',
      width: '14.175vw',
      height: '2.5vw',
    },
    [theme.breakpoints.up('extraLarge')]: {},
  },

  infoDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '91.11vw',
    marginLeft: '2.7vw',
    paddingLeft: '1vh',

    [theme.breakpoints.up('medium')]: {
      flexDirection: 'row',
      width: '88.46vw',
      marginLeft: '2.15vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '89.458vw',
      marginLeft: '2.15vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '79.766vw',
      marginLeft: '10.078vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '79.236vw',
      marginLeft: '17.87vw',
    },
  },

  mapDivContainer: {
    marginLeft: 0,
    marginTop: '3.53vw',

    [theme.breakpoints.up('medium')]: {
      marginLeft: '2.50vw',
      marginTop: '4.16vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '2vw',
      marginTop: '3.53vw',
    },
    [theme.breakpoints.up('large')]: {
      marginLeft: '2vw',
      marginTop: '1.9vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginLeft: '2vw',
      marginTop: '1.9vw',
    },
  },
}));
