import React, { useCallback } from 'react';
import { useStyles } from './Page.styles';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BugIcon } from '../assets/images/bug-solid.svg';

interface IProps {
  fromMain: boolean;
}

const NotFound = (props: IProps) => {
  const classes = useStyles();
  const history = useHistory();
  const backHome = useCallback(() => history.push('/'), [history]);
  return (
    <div
      className={`${classes.notFoundContainer} ${props.fromMain ? 'fromMain' : ''
        }`}
    >
      <h1 className={classes.notFoundH1}>
        4 <BugIcon className={classes.notFoundIcon} /> 4
      </h1>
      <h2 className={classes.notFoundH2}>Oops! Page Not Found</h2>
      <div>
        <button onClick={backHome} className={classes.notFoundButton}>
          Back Home
        </button>
      </div>
    </div>
  );
};

export default NotFound;
