import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import { SnackbarContent } from '@material-ui/core';
import { ReactComponent as SuccessIcon} from '../../assets/images/success-icon.svg';
import { ReactComponent as FailIcon} from '../../assets/images/error-icon.svg';
import { useStyles } from './DialogBox.styles';

interface IProps {
  show: boolean;
  isSuccess: boolean;
  message: string;
  hide: (option: boolean) => void;
}


const DialogBox = (props: IProps) => {
    const {show, hide} = props

    const classes = useStyles();

    const handleClose = () => {
        hide(false);
    }

    return(
        <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center' }}
        className={classes.dialog_box}
        open={show}
        onClose={handleClose}
        key="dialog-box"
      >
        <SnackbarContent 
          className={props.isSuccess? classes.successDialog : classes.failDialog}
          aria-describedby="dialog-box-message"
          message={
            <span id="dialog-box-message">
              <div className={classes.div_message}>
               {props.isSuccess? <SuccessIcon className={classes.icon}></SuccessIcon> : <FailIcon className={classes.icon}></FailIcon>}
                <label className={classes.message}>{props.message}</label>
                </div>
            </span>
          }
        />
    </Snackbar> 
    );
};

export default DialogBox;