import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
    marginBottom: '5vw',
    filter: 'drop-shadow(0px 0.31vw 0.31vw rgba(33, 35, 34, 0.2))',
    border: '1px solid #d6d6d6',
    boxSizing: 'border-box',
    background: '#ffffff',
    width: '91.11vw',
    marginLeft: '4.26vw',
    borderRadius: '3.33vw',
    paddingBottom: '6.400vw',
    padding: '3vw',

    [theme.breakpoints.up('medium')]: {
      width: '95vw',
      marginLeft: '2.15vw',
      borderRadius: '0.87vw',
      paddingBottom: '3.125vw',
      padding: '3.125vw 4.167vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '95vw',
      marginLeft: '2.30vw',
      paddingBottom: '2.500vw',
      padding: '2.500vw 3.333vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '79.766vw',
      marginLeft: '10.078vw',
      paddingBottom: '1.875vw',
      padding: '1.875vw 2.500vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: 0,
      width: '79.236vw',
      marginLeft: '17.87vw',
      paddingBottom: '1.667vw',
      padding: '1.667vw 2.222vw',
    },
  },

  subtitleFont: {
    fontFamily: 'WTGothic',
    letterSpacing: '0.01em',
    fontSize: '4.800vw',

    [theme.breakpoints.up('medium')]: {
      fontSize: '2.344vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.406vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.250vw',
    },
  },

  infoFont: {
    fontFamily: 'WTGothic',
    marginTop: '3vw',
    letterSpacing: '0.01em',
    fontSize: '4.267vw',
    width: '83vw',
    borderRadius: '1.600vw',

    '&.secondary': {
      marginTop: '0vw',
      marginLeft: '2vw',

      [theme.breakpoints.up('medium')]: {
        marginTop: '1.1vw',
      },
      [theme.breakpoints.up('tablet')]: {
        marginTop: '0.8vw',
      },
      [theme.breakpoints.up('large')]: {
        marginTop: '0.6vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        marginTop: '0.5vw',
      },
    },

    [theme.breakpoints.up('medium')]: {
      marginTop: '1.2vw',
      fontSize: '2.083vw',
      borderRadius: '0.781vw',
      width: '83vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginTop: '1.3vw',
      fontSize: '1.667vw',
      borderRadius: '0.625vw',
      width: 'max-content',
    },
    [theme.breakpoints.up('large')]: {
      marginTop: '1vw',
      fontSize: '1.250vw',
      borderRadius: '0.469vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '0.7vw',
      fontSize: '1.111vw',
      borderRadius: '0.417vw',
    },
  },

  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '3.8vw',
    columnGap: '3vw',
    rowGap: '4vw',
    flexWrap: 'wrap',

    [theme.breakpoints.up('medium')]: {
      paddingTop: '2.5vw',
      flexWrap: 'nowrap',
      columnGap: '1vw',
      rowGap: '0vw',
    },
    [theme.breakpoints.up('tablet')]: {
      paddingTop: '1.8vw',
    },
    [theme.breakpoints.up('large')]: {
      paddingTop: '1.3vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      paddingTop: '1.5vw',
    },
  },

  inputDiv: {
    fontFamily: 'WTGothic',
    letterSpacing: '0.01em',
    width: '59.733vw',
    height: '10.133vw',
    borderRadius: '3.200vw',
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderWidth: '1px',
    },

    fontSize: '4.800vw',

    [theme.breakpoints.up('medium')]: {
      width: '29.167vw',
      borderRadius: '1.563vw',
      height: '4.948vw',
      fontSize: '2.344vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '23.333vw',
      height: '3.958vw',
      borderRadius: '1.250vw',
      fontSize: '1.875vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '17.500vw',
      height: '2.969vw',
      borderRadius: '0.938vw',
      fontSize: '1.406vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '15.556vw',
      height: '2.639vw',
      borderRadius: '0.833vw',
      fontSize: '1.250vw',
    },
  },

  addRemoveButton: {
    width: '6.400vw',
    height: '6.400vw',
    marginTop: '1.5vw',

    [theme.breakpoints.up('medium')]: {
      width: '3.125vw',
      height: '3.125vw',
      marginTop: '1.1vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '2.500vw',
      height: '2.500vw',
      marginTop: '0.8vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '1.875vw',
      height: '1.875vw',
      marginTop: '0.6vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '1.667vw',
      height: '1.667vw',
      marginTop: '0.5vw',
    },
  },

  inputButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '1vw',
    marginTop: '6.400vw',

    [theme.breakpoints.up('medium')]: {
      marginTop: '3.125vw',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    [theme.breakpoints.up('tablet')]: {
      marginTop: '2.500vw',
    },
    [theme.breakpoints.up('large')]: {
      marginTop: '1.875vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '1.667vw',
    },
  },

  inputButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'WTGothic',
    fontSize: '4.800vw',
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: '3.467vw 4.267vw',
    borderRadius: '3.33vw',
    borderWidth: '0vw',
    backgroundColor: theme.palette.white,

    '&.save': {
      backgroundColor: theme.palette.lightOrange,
      color: theme.palette.white,
      boxShadow: '0px 4px 4px rgba(33, 35, 34, 0.2)',
    },

    '&.unavailable': {
      opacity: '0.4',
    },

    [theme.breakpoints.up('medium')]: {
      fontSize: '2.344vw',
      padding: '1.693vw 2.083vw',
      borderRadius: '1.563vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875vw',
      padding: '1.354vw 1.667vw',
      borderRadius: '1.250vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.406vw',
      padding: '1.016vw 1.250vw',
      borderRadius: '0.938vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.250vw',
      padding: '0.903vw 1.111vw',
      borderRadius: '0.833vw',
    },
  },
}));
