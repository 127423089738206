import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	mapInfoDiv: {
        
        justifyContent: 'center',
        boxSizing: 'border-box',
        display: 'block',
        width:  '91.46vw',
        height: 'auto',
        marginTop:'4.26vw',
        border: '0.26vw solid #D6D6D6',
        borderRadius: '3.2vw',
        float: 'left',
        paddingBottom: '3.26vw',

        [theme.breakpoints.up('medium')]: {
            display: 'flex',
            flexDirection: 'row',
            width:  '83.07vw',
            height: '7.29vw',
            marginTop: '2.08vw',
            border: '0.13vw solid #D6D6D6',
            borderRadius: '1.56vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width:  '86.45vw',
            height: '5.83vw',
            marginTop: '1.66vw',
            border: '0.06vw solid #D6D6D6',
            borderRadius: '0.83vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '69.06vw',
            height: '4.37vw',
            marginTop: '1.25vw',
            border: '0.07vw solid #D6D6D6',
            borderRadius: '0.93vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '69.79vw',
            height: '3.88vw',
            marginTop: '1.11vw',
            border: '0.06vw solid #D6D6D6',
            borderRadius: '0.83vw',
        },

        '&.isAdmin' : {
            width:  '91.46vw',
            marginTop: 0,

            [theme.breakpoints.up('medium')]: {
                width:  '63vw',
            },
            [theme.breakpoints.up('tablet')]: {
                width:  '64.5vw',
            },
            [theme.breakpoints.up('large')]: {
                width:  '49.07vw',
            },
            [theme.breakpoints.up('extraLarge')]: {
                width:  '57.5vw',
            },
        }
    },

    MapInfo: {

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '29.86vw',
        height:'6.4vw',
        marginTop:'3.2vw',
        marginLeft: '10.53vw',
        float: 'left',

        '&.first-child': { 
            width: '29.86vw',
            height:'6.4vw',
            marginTop:'3.2vw',
            marginLeft: '10.53vw',
            float: 'left',
        },

        [theme.breakpoints.up('medium')]: {

            '&.first-child': {
                marginLeft: 0,
            },
            height: '3.12vw',
            marginTop: '2.08vw',
            marginLeft: '2.08vw',
            width: 'unset',
        },
        [theme.breakpoints.up('tablet')]: {
            height:' 2.5vw',
            marginTop: '1.66vw',
            marginLeft: '1.66vw',
            
        },
        [theme.breakpoints.up('large')]: {
            height: '1.87vw',
            marginTop: '1.25vw',
            marginLeft: '1.25vw',

        },
        [theme.breakpoints.up('extraLarge')]: {
            height: '1.66vw',
            marginTop: '1.11vw',
            marginLeft: '1.11vw',
        },
    },

    mapInfoIcon: {
        
        width: '6.4vw',
        height: '6.4vw',

        [theme.breakpoints.up('medium')]: {
            width: '3.12vw',
            height: '3.12vw',
        },
        [theme.breakpoints.up('tablet')]: {
            width: '2.5vw',
            height: '2.5vw',
        },
        [theme.breakpoints.up('large')]: {
            width: '1.87vw',
            height: '1.87vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            width: '1.66vw',
            height: '1.66vw',
        },
    },

	mapInfoFont: {
        fontFamily: 'WTGothic',
        fontWeight: 300,
        fontSize: '3.2vw',
        height: '6.4vw',
        lineHeight: '6.4vw',
        marginLeft: '1.06vw',

        [theme.breakpoints.up('medium')]: {
            fontSize: '1.56vw',
            height: '3.12vw',
            lineHeight: '3.12vw',
            marginLeft: '0.52vw',
        },
        [theme.breakpoints.up('tablet')]: {
            fontSize:'1.45vw',
            height:'2.5vw',
            lineHeight:'2.5vw',
            marginLeft: '0.41vw',
        },
        [theme.breakpoints.up('large')]: {
            height: '1.87vw',
            lineHeight: '1.87vw',
            marginLeft: '0.31vw',
            fontSize: '0.93vw',
        },
        [theme.breakpoints.up('extraLarge')]: {
            fontSize: '0.93vw',
            height: '1.66vw',
            lineHeight: '1.66vw',
            marginLeft: '0.27vw',
        },

    },

    available: {
        fill:theme.palette.lightGrey,
        color: theme.palette.lightGrey,
        stroke: theme.palette.lightGrey,
    },

    occupied: {
        fill:theme.palette.tomato,
        color:theme.palette.tomato,
        stroke:theme.palette.tomato,
    },

    selected: {
        fill:theme.palette.turquoise,
        color: theme.palette.turquoise,
        stroke: theme.palette.turquoise,
    },

    reserved: {
        fill: theme.palette.oceanBlue,
        color: theme.palette.oceanBlue,
        stroke: theme.palette.oceanBlue,
    },


}));