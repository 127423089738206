import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import DialogBox from '../DialogBox/DialogBox';
import { getLabel } from '../../helpers/labels';
import { useOktaAuth } from '@okta/okta-react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useStyles } from './RegisterDialogBox.styles';

const RegisterDialogBox = () => {
  const classes = useStyles();
  const { authState, oktaAuth } = useOktaAuth();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [openDialog, setOpen] = useState(false);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [isSuccessCreateUser, setIsSuccessCreateUser] = useState(true);
  const [createUserMssg, setCreateUserMssg] = useState('');

  const userQuery = gql`
    query ($email: String!) {
      users: getUserByEmail(email: $email) {
        email
        name
        role
        phone
        areaId
        blocked
      }
    }
  `;

  const createUserQuery = gql`
    mutation (
      $email: String!
      $name: String!
      $role: String!
      $phone: String!
      $areaId: Int!
    ) {
      result: createUser(
        user: {
          email: $email
          name: $name
          role: $role
          phone: $phone
          areaId: $areaId
        }
      ) {
        email
        name
        phone
        areaId
      }
    }
  `;

  const createFloorXUserQuery = gql`
    mutation (
      $floorId: Float!
      $userEmail: String!
    ) {
      result: createFloorXUser(floorXUser:{
          floorId: $floorId
          userEmail: $userEmail
      }){
        floorId
        userEmail
      }
    }
  `;

  const [getUserData, userData] = useLazyQuery(userQuery, {
    variables: { email: email },
  });

  const [createUser, createUserResult] = useMutation(createUserQuery, {
    variables: {
      email: email,
      name: username,
      role: 'User',
      phone: phone,
      areaId: 1,
    },
  });

  const [createFloorXUser, createFloorXUserResult] = useMutation(createFloorXUserQuery, {
    variables: {
      floorId: 2,
      userEmail: email,
    },
  });
  useEffect(() => {
    if (email !== '') {
      getUserData();
    }
  }, [email, getUserData,createFloorXUser]);

  useEffect(() => {
    if (userData.data) {
      if (userData.data.users.length === 0) {
        setOpen(true);
      }
    }
  }, [userData.data]);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      oktaAuth.getUser().then(
        (result: any) => {
          if (result.name && result.email) {
            setUsername(result.name);
            setEmail(result.email);
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }, [authState, oktaAuth]);

  const handleUserNameChange = (e: any) => {
    setUsername(e.target.value);
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e: any) => {
    setPhone(e.target.value.replace(/[^0-9]/g, ''));
  };

  const saveData = () => {
    createUser();
  };

  useEffect(() => {
    if (createUserResult.error) {
      setCreateUserMssg(getLabel('createUserMessageFailed'));
      setIsSuccessCreateUser(false);
      setOpenDialogBox(true);
    } else if (createUserResult.data) {
      setOpen(false);
      setCreateUserMssg(getLabel('createUserMessageSuccess'));
      setIsSuccessCreateUser(true);
      setOpenDialogBox(true);
      createFloorXUser();
    }
  }, [createUserResult.data, createUserResult.error]);
  
  useEffect(() => {
    if (createUserResult.error) {
      setCreateUserMssg("Error al asignar un piso");
      setIsSuccessCreateUser(false);
      setOpenDialogBox(true);
    } 
  }, [createFloorXUserResult.error]);

  return (
    <>
      <DialogBox
        message={createUserMssg}
        isSuccess={isSuccessCreateUser}
        show={openDialogBox}
        hide={setOpenDialogBox}
      ></DialogBox>
      <Dialog
        open={openDialog}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        className={classes.dialogBox}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          classes={{ root: classes.dialogTitle }}
        >
          Información de Registro
        </DialogTitle>
        <DialogContent>
          <DialogContentText classes={{ root: classes.dialogSubtitle }}>
            Nombre Completo:
          </DialogContentText>
          <DialogContentText>
            <TextField
              classes={{ root: classes.textfield }}
              id="outlined-basic"
              fullWidth
              variant="outlined"
              value={username}
              onChange={handleUserNameChange}
            />
          </DialogContentText>
          <DialogContentText classes={{ root: classes.dialogSubtitle }}>
            Correo:
          </DialogContentText>
          <DialogContentText>
            <TextField
              classes={{ root: classes.textfield }}
              id="outlined-basic"
              fullWidth
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
            />
          </DialogContentText>
          <DialogContentText classes={{ root: classes.dialogSubtitle }}>
            Número de teléfono:
          </DialogContentText>
          <DialogContentText>
            <TextField
              classes={{ root: classes.textfield }}
              id="outlined-basic"
              fullWidth
              variant="outlined"
              value={phone}
              onChange={handlePhoneChange}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogButtonsContainer }}>
          <Button
            className={`${classes.dialogButtons} ${classes.confirmButton} ${
              username && email && phone ? '' : 'unavailable'
            }`}
            color="primary"
            onClick={username && email && phone ? saveData : undefined}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RegisterDialogBox;
