import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  fields: {
    marginBottom: '1rem',
    flexDirection: 'row',
  },

  parkingLabel: {
    fontSize: '1rem',
    fontFamily: 'WTGothic',
    fontWeight: 300,
    marginBottom: '0rem'
  },

  parkingFormControl: {
    '& .MuiFormControlLabel-label': {
      fontSize: '1rem',
      fontFamily: 'WTGothic',
      fontWeight: 300
    }
  },

  parkingCheckbox: {
    '&.Mui-checked': {
      color: '#ff760c'
    }
  },

  parkingPolicy: {
    fontFamily: 'WTGothic',
    fontWeight: 300,
    '& a': {
      color: theme.palette.black
    }
  },
  
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '5vw',
    filter: 'drop-shadow(0px 0.31vw 0.31vw rgba(33, 35, 34, 0.2))',
    border: '1px solid #d6d6d6',
    boxSizing: 'border-box',
    background: '#ffffff',
    width: '91.11vw',
    marginLeft: '4.44vw',
    borderRadius: '3.33vw',
    paddingBottom: '6.400vw',

    [theme.breakpoints.up('medium')]: {
      width: '95.66vw',
      marginLeft: '1rem',
      borderRadius: '0.87vw',
      paddingBottom: '3.125vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '96vw',
      paddingBottom: '2.500vw',
      marginLeft: '1.3rem',
    },
    [theme.breakpoints.up('large')]: {
      width: '82vw',
      marginLeft: '9.325rem',
      paddingBottom: '1.875vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '1.45vw',
      width: '79.236vw',
      marginLeft: '18.5vw',
      paddingBottom: '1.667vw',
    },
  },

  subtitleFont: {
    display: 'flex',
    fontFamily: 'WTGothic',
    margin: 0,
    fontWeight: 300,
    letterSpacing: '0.01em',
    marginLeft: '3vw',
    marginTop: '4.44vw',
    width: '81.94vw',
    fontSize: '4.800vw',

    [theme.breakpoints.up('medium')]: {
      marginRight: '56vw',
      marginTop: '2.08vw',
      width: '37.89vw',
      fontSize: '2.344vw',
      marginLeft: '3.5vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '4vw',
      marginTop: '2.5vw',
      width: '39.58vw',
      fontSize: '1.875vw',
    },
    [theme.breakpoints.up('large')]: {
      marginTop: '1.75vw',
      marginLeft: '59.3vw',
      fontSize: '1.406vw',
      width: '100%',
    },
    [theme.breakpoints.up('extraLarge')]: {},
  },

  checkInDiv: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
    border: '1px solid #d6d6d6',
    boxSizing: 'border-box',
    width: '80vw',
    height: '20vw',
    borderRadius: '3.200vw',
    marginTop: '3.5vw',
    padding: '0 0 2vw 0',
    paddingRight: '0.5rem',

    [theme.breakpoints.up('medium')]: {
      width: '90vw',
      borderRadius: '1.563vw',
      marginTop: '2.083vw',
      height: '10vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '92vw',
      borderRadius: '1.250vw',
      marginTop: '1.667vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '78vw',
      borderRadius: '0.938vw',
      marginTop: '1.250vw',
      height: '7vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '75.903vw',
      borderRadius: '0.833vw',
      marginTop: '1.111vw',
    },
  },

  jobFont: {
    display: 'flex',
    fontFamily: 'WTGothic',
    margin: 0,
    fontWeight: 300,
    letterSpacing: '0.01em',
    marginLeft: '2.44vw',
    fontSize: '4.3vw',
    marginTop: '2vw',

    [theme.breakpoints.up('medium')]: {
      marginLeft: '2.6vw',
      fontSize: '1.8vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '2.5vw',
      fontSize: '1.8vw',
    },
    [theme.breakpoints.up('large')]: {
      marginLeft: '1.87vw',
      fontSize: '1.1vw',
    },
    [theme.breakpoints.up('extraLarge')]: {},
  },

  checkInButton: {
    width: '37vw',
    height: '9.5vw',
    backgroundColor: theme.palette.lightOrange,
    boxShadow: '0px 4px 4px rgba(33, 35, 34, 0.2)',
    fontFamily: 'WTGothic',
    fontSeight: 300,
    color: theme.palette.white,
    outline: 'none',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    fontSize: '3.73vw',
    borderRadius: '3.33vw',
    textTransform: 'none',

    [theme.breakpoints.up('medium')]: {
      height: '4.65vw',
      width: '21vw',
      fontSize: '1.82vw',
      borderRadius: '1.25vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '17vw',
      borderRadius: '1.25vw',
      height: '3.75vw',
      display: 'block',
      position: 'relative',
      fontSize: '1.45vw',
      float: 'left',
    },
    [theme.breakpoints.up('large')]: {
      width: '15vw',
      height: '2.8vw',
      fontSize: '15px',
      borderRadius: '0.87vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '11vw',
      height: '2.5vw',
      fontSize: '1vw',
    },

    '&.ischeck': {
      color: '#d9d9d9',
    },

    '&.isbook': {
      backgroundColor: theme.palette.white,
      color: theme.palette.lightOrange,
      border: '0.5px solid #D6D6D6',
      borderColor: theme.palette.lightOrange,
    },
  },

  confirmButton: {
    backgroundColor: theme.palette.lightOrange,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.lightOrange,
      color: theme.palette.white,
    },
    '& .MuiButton-root.Mui-disabled': {
      color: `${theme.palette.white}!important`,
    },
  },

  checkInButtonDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '2vw',
  },

  dialogBox: {
    '& .MuiPaper-root': {
      width: '90.467vw',
      marginLeft: '1.6rem',
      height: '80vh',

      [theme.breakpoints.up('medium')]: {
        width: '74vw',
        marginLeft: '0rem',
        height: 'unset',
      },
      [theme.breakpoints.up('tablet')]: {
        width: '60.250vw',
      },
      [theme.breakpoints.up('large')]: {
        width: '44.375vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        width: '40vw',
      },
    },
  },

  cancelReservationDialogBox: {
    '& .MuiPaper-root': {
      width: '87.467vw',
      marginLeft: '4vw',

      [theme.breakpoints.up('medium')]: {
        width: '83.333vw',
        marginLeft: '4vw',
      },
      [theme.breakpoints.up('tablet')]: {
        width: '56.250vw',
        marginLeft: '3vw',
      },
      [theme.breakpoints.up('large')]: {
        width: '39.375vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        width: '35vw',
      },
    },
  },

  dialogContent: {
    margin: '0 auto',
  },

  backDrop: {
    backdropFilter: 'blur(2px)',
    backgroundColor: theme.palette.whiteRGB,
    width: '100vw',
    height: '100%',
    marginTop: '32vw',

    [theme.breakpoints.up('medium')]: {
      height: '90%',
      marginTop: '23vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginTop: '15vw',
    },
    [theme.breakpoints.up('large')]: {
      height: '90%',
      marginTop: '6.5vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '11vw',
      width: '85.5vw',
      marginLeft: '16.3vw',
    },
  },

  dialogTitle: {
    '& .MuiTypography-h6': {
      fontFamily: 'WTGothic',
      fontSize: '4.800vw',
      fontWeight: 400,

      [theme.breakpoints.up('medium')]: {
        fontSize: '2.344vw',
      },
      [theme.breakpoints.up('tablet')]: {
        fontSize: '1.875vw',
      },
      [theme.breakpoints.up('large')]: {
        fontSize: '1.406vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        fontSize: '1.250vw',
      },
    },
  },

  titleRecomendation: {
    fontFamily: 'WTGothic',
    fontSize: '4.800vw',
    fontWeight: 300,
    margin: '0px',
    marginBottom: '15px',

    [theme.breakpoints.up('medium')]: {
      fontSize: '2.344vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.406vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.250vw',
    },
  },
  containerRecomendation: {
    margin: '0px 30px',
    textAlign: 'justify',
  },

  reservationDetailsDiv: {
    width: '77vw',

    [theme.breakpoints.up('medium')]: {
      width: '68vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '55vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '40vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '36vw',
    },
  },

  dialogSubtitle: {
    fontFamily: 'WTGothic',
    fontSize: '4.800vw',
    fontWeight: 300,

    [theme.breakpoints.up('medium')]: {
      fontSize: '2.344vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.406vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.250vw',
    },
  },

  dialogStationInfo: {
    fontFamily: 'WTGothic',
    fontSize: '4.800vw',
    fontWeight: 300,
    color: theme.palette.black,
    marginTop: '-1vw',

    [theme.breakpoints.up('medium')]: {
      fontSize: '2.344vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.406vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.250vw',
    },
  },

  dialogButtons: {
    fontFamily: 'WTGothic',
    fontSize: '4.800vw',
    height: '12.800vw',
    width: '37.333vw',
    borderRadius: '12px',
    padding: '2vw',
    textTransform: 'capitalize',

    [theme.breakpoints.up('medium')]: {
      height: '6.250vw',
      width: '27.604vw',
      fontSize: '2.344vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875vw',
      height: '5vw',
      width: '22.083vw',
    },
    [theme.breakpoints.up('large')]: {
      height: '3.750vw',
      width: '16.563vw',
      fontSize: '1.406vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.250vw',
      height: '3.333vw',
      width: '14.722vw',
    },
  },

  cancelButton: {
    backgroundColor: theme.palette.white,
    color: theme.palette.grey2,
    border: '1px solid #D6D6D6',
    boxSizing: 'border-box',
    borderRadius: '12px',
  },

  dialogButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: '2vw',
  },

  dialogButtonsContainerCancel: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '2vw',
  },

  labelText: {
    textAlign: 'left',
    color: 'red',
    fontSize: '0.8rem',
  },

  buttonsGap: {
    display: 'flex',
    columnGap: '0.5rem',
  },
}));
