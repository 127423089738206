import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1.25vw',
    filter: 'drop-shadow(0px 0.31vw 0.31vw rgba(33, 35, 34, 0.2))',
    border: '1px solid #d6d6d6',
    boxSizing: 'border-box',
    background: '#ffffff',
    width: '91.11vw',
    marginLeft: '4.44vw',
    borderRadius: '3.33vw',
    paddingBottom: '6.400vw',

    [theme.breakpoints.up('medium')]: {
      width: '83.46vw',
      marginLeft: '1rem',
      borderRadius: '0.87vw',
      paddingBottom: '3.125vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '86.45vw',
      paddingBottom: '2.500vw',
      marginLeft: '1.3rem',
    },
    [theme.breakpoints.up('large')]: {
      width: '82vw',
      marginLeft: '9.325rem',
      paddingBottom: '1.875vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '1.45vw',
      width: '79.236vw',
      marginLeft: '18.5vw',
      paddingBottom: '1.667vw',
    },
  },

  containerTitle: {
    display: 'flex',
    fontFamily: 'WTGothic',
    margin: 0,
    fontWeight: 300,
    letterSpacing: '0.01em',
    marginLeft: '4.44vw',
    marginTop: '4.44vw',
    width: '81.94vw',
    fontSize: '4.800vw',

    [theme.breakpoints.up('medium')]: {
      marginLeft: '2.6vw',
      marginTop: '2.08vw',
      width: '37.89vw',
      fontSize: '2.344vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '2.5vw',
      marginTop: '2.5vw',
      width: '39.58vw',
      fontSize: '1.875vw',
    },
    [theme.breakpoints.up('large')]: {
      marginTop: '1.75vw',
      marginLeft: '1.87vw',
      fontSize: '1.406vw',
      width: '100%',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '1.66vw',
      fontSize: '1.250vw',
    },
  },

  subtitlesDiv: {
    display: 'flex',
    alignItems: 'center',
    width: '88vw',
    marginLeft: '1.5vw',
    marginTop: '2.5vw',

    [theme.breakpoints.up('medium')]: {
      width: '76.823vw',
      marginLeft: '3.125vw',
      marginTop: '2.083vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '82vw',
      marginLeft: '2.500vw',
      marginTop: '1.667vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '85.391vw',
      marginLeft: '1.875vw',
      marginTop: '1.250vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '75.903vw',
      marginLeft: '1.667vw',
      marginTop: '1.111vw',
    },
  },

  subtitleFont: {
    fontFamily: 'WTGothic',
    fontWeight: 300,
    letterSpacing: '0.01em',
    fontSize: '0.75rem',
    marginLeft: '3.7vw',
    width: '9.5rem',

    '&.first': {
      width: '9rem',
      color: theme.palette.grey2,
      marginLeft: '4.2vw',

      [theme.breakpoints.up('medium')]: {
        marginLeft: '2.6vw',
        width: '11.5rem',
      },
      [theme.breakpoints.up('tablet')]: {
        marginLeft: '3.1vw',
        width: '9rem',
      },
      [theme.breakpoints.up('large')]: {
        marginLeft: '2.3vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        marginLeft: '2.08vw',
      },
    },

    '&.floor': {
      [theme.breakpoints.up('medium')]: {
        marginLeft: '0vw',
        width: '14vw',
      },
      [theme.breakpoints.up('tablet')]: {
        marginLeft: '2.500vw',
        width: '8.1rem',
      },
      [theme.breakpoints.up('large')]: {
        marginLeft: '1.875vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        marginLeft: '1.667vw',
      },
    },

    '&.third': {
      width: 'auto',
    },

    [theme.breakpoints.up('medium')]: {
      marginLeft: '0vw',
      width: '19.5vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '2.500vw',
      width: '8.1rem',
    },
    [theme.breakpoints.up('large')]: {
      marginLeft: '1.875vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginLeft: '1.667vw',
    },
  },

  reservationInfoDiv: {
    display: 'flex',
    boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
    border: '1px solid #d6d6d6',
    boxSizing: 'border-box',
    alignItems: 'center',
    width: '88vw',
    borderRadius: '3.200vw',
    marginLeft: '1.5vw',
    marginTop: '2.5vw',
    padding: '1.5rem 0rem',
    paddingRight: '1.5rem',
    overflowY: 'scroll', //test **
    // flexWrap: 'wrap',
    // rowGap: '1rem',

    [theme.breakpoints.up('medium')]: {
      width: '76.823vw',
      borderRadius: '1.563vw',
      marginLeft: '3.125vw',
      marginTop: '2.083vw',
    },
    [theme.breakpoints.up('tablet')]: {
      // flexWrap: 'nowrap',
      width: '82vw',
      height: '6.979vw',
      borderRadius: '1.250vw',
      marginLeft: '2.500vw',
      marginTop: '1.667vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '78vw',
      borderRadius: '0.938vw',
      marginLeft: '1.875vw',
      marginTop: '1.250vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '75.903vw',
      borderRadius: '0.833vw',
      marginLeft: '1.667vw',
      marginTop: '1.111vw',
    },
  },

  cornerDiv: {
    height: '100%',
    width: '0',
    backgroundColor: theme.palette.white,
    borderRadius: '3.200vw 0vw 0vw 3.200vw',

    [theme.breakpoints.up('medium')]: {
      borderRadius: '1.563vw 0vw 0vw 1.563vw',
    },
    [theme.breakpoints.up('tablet')]: {
      borderRadius: '1.250vw 0vw 0vw 1.250vw',
    },
    [theme.breakpoints.up('large')]: {
      borderRadius: '0.938vw 0vw 0vw 0.938vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      borderRadius: '0.833vw 0vw 0vw 0.833vw',
    },
  },

  monthContainer: {
    display: 'flex',
    width: '80vw',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '2.3rem',
    marginTop: '5vw',
    columnGap: '5.499vw',
    flexWrap: 'wrap',

    [theme.breakpoints.up('medium')]: {
      marginLeft: '5.810vw',
      marginTop: '4.6vw',
      columnGap: '2.685vw',
      width: '75vw',
      justifyContent: 'flex-start',
      flexWrap: 'nowrap',
    },
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '4.648vw',
      marginTop: '3.5vw',
      columnGap: '2.148vw',
      width: '79vw',
    },
    [theme.breakpoints.up('large')]: {
      marginLeft: '3.486vw',
      marginTop: '2.722vw',
      columnGap: '1.611vw',
      width: '75vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginLeft: '3.099vw',
      marginTop: '2.4vw',
      columnGap: '1.432vw',
      width: '74vw',
    },
  },

  containerDate: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '33vw',

    [theme.breakpoints.up('medium')]: {
      height: '17vw',
    },
    [theme.breakpoints.up('tablet')]: {
      flexDirection: 'row',
      height: 'auto',
      width: '182vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '105vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '75vw',
    },
  },

  reportButton: {
    width: '41vw',
    height: '9.5vw',
    border: 0,
    backgroundColor: theme.palette.lightOrange,
    boxShadow: '0px 4px 4px rgba(33, 35, 34, 0.2)',
    fontFamily: 'WTGothic',
    fontSeight: 300,
    color: theme.palette.white,
    outline: 'none',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    fontSize: '3.73vw',
    borderRadius: '3.33vw',

    [theme.breakpoints.up('medium')]: {
      height: '4.65vw',
      width: '39vw',
      fontSize: '1.82vw',
      borderRadius: '1.25vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '17vw',
      borderRadius: '1.25vw',
      height: '3.75vw',
      display: 'block',
      position: 'relative',
      fontSize: '1.45vw',
      float: 'left',
    },
    [theme.breakpoints.up('large')]: {
      width: '15vw',
      height: '2.8vw',
      fontSize: '15px',
      borderRadius: '0.87vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '11vw',
      height: '2.5vw',
      fontSize: '1vw',
    },
  },

  arrowContainer: {
    display: 'flex',
    backgroundColor: theme.palette.white,
    border: 0,
    top: 0,
    outline: 'none',

    '&:hover': {
      cursor: 'pointer',
    },
  },

  arrowSrc: {
    height: '3.600vw',
    width: '1.800vw',

    [theme.breakpoints.up('medium')]: {
      height: '1.758vw',
      width: '0.879vw',
    },
    [theme.breakpoints.up('tablet')]: {
      height: '1.406vw',
      width: '0.703vw',
    },
    [theme.breakpoints.up('large')]: {
      height: '1.055vw',
      width: '0.527vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      height: '0.938vw',
      width: '0.469vw',
    },
  },

  monthTitleFormat: {
    fontFamily: 'WTGothic',
    fontWeight: 300,
    letterSpacing: '0.01em',
    fontSize: '4.800vw',
    textAlign: 'center',
    width: '45vw',

    [theme.breakpoints.up('medium')]: {
      fontSize: '2.344vw',
      width: '23vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875vw',
      width: '18vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.406vw',
      width: '14vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.250vw',
      width: '12vw',
    },
  },

  separator: {
    border: '1px solid #D6D6D6',
    width: '81vw',
    marginLeft: '4.5vw',
    marginTop: '3.733vw',

    [theme.breakpoints.up('medium')]: {
      width: '78vw',
      marginLeft: '3.125vw',
      marginTop: '2.065vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '82vw',
      marginLeft: '2.500vw',
      marginTop: '1.458vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '78vw',
      marginLeft: '1.875vw',
      marginTop: '1.094vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '75.903vw',
      marginLeft: '1.667vw',
      marginTop: '0.972vw',
    },
  },

  dateInput: {
    marginTop: '3.2vw',
    position: 'relative',
    width: '10vw',
    height: '3.75vw',

    [theme.breakpoints.up('medium')]: {
      marginTop: '1.6vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginTop: '1.35vw',
    },
    [theme.breakpoints.up('large')]: {
      marginTop: '0.95vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '0.9vw',
    },
  },

  reservationCountFont: {
    display: 'flex',
    fontFamily: 'WTGothic',
    fontWeight: 300,
    letterSpacing: '0.01em',
    fontSize: '1.125rem',
    width: '100%',
    justifyContent: 'center',
    marginTop: '2rem',
    [theme.breakpoints.up('medium')]: {
      justifyContent: 'flex-end',
      marginTop: '0rem',
    },
  },

  // reservationInfoFont: {
  //     fontFamily: "WTGothic",
  //     fontWeight: 300,
  //     letterSpacing: '0.01em',
  //     fontSize: '1rem',
  //     textAlign: 'center',
  //     flexGrow: 1,
  //     '&.first': {
  //         color: theme.palette.grey2,
  //     },

  //     '&.third': {
  //         width: '15rem',
  //         [theme.breakpoints.up('tablet')]: {
  //             width: 'auto',
  //         },
  //     },
  // },

  reservationInfoFont: {
    fontFamily: 'WTGothic',
    fontWeight: 300,
    letterSpacing: '0.01em',
    fontSize: '1rem',
    marginLeft: '6.400vw',
    textAlign: 'center',
    width: '9.375rem',

    '&.first': {
      width: '9rem',
      color: theme.palette.grey2,
      marginLeft: '3.400vw',

      [theme.breakpoints.up('medium')]: {
        marginLeft: '1.660vw',
      },
      [theme.breakpoints.up('tablet')]: {
        marginLeft: '1.328vw',
      },
      [theme.breakpoints.up('large')]: {
        marginLeft: '0.996vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        marginLeft: '0.885vw',
      },
    },

    '&.date': {
      textAlign: 'left',
      width: '8.2rem',
    },

    '&.parking': {
      width: '3rem',
    },

    '&.fourth': {
      width: 'auto',
    },

    [theme.breakpoints.up('medium')]: {
      marginLeft: '3.125vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '2.500vw',
    },
    [theme.breakpoints.up('large')]: {
      marginLeft: '1.875vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginLeft: '1.667vw',
    },
  },

  reservationButtonsWrapper: {
    display: 'flex',
    color: theme.palette.oceanBlue,
    fontFamily: 'WTGothic',
    fontWeight: 300,
    letterSpacing: '0.01em',
    fontSize: '1rem',
    flexGrow: 10,
    justifyContent: 'center',
    [theme.breakpoints.up('tablet')]: {
      justifyContent: 'flex-end',
    },
  },

  checkinButton: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },

  cancelButton: {
    cursor: 'pointer',
    textDecoration: 'underline',
    marginLeft: '20px',
  },

  backDrop: {
    backdropFilter: 'blur(2px)',
    backgroundColor: theme.palette.whiteRGB,
    width: '100vw',
    height: '80%',
    marginTop: '38vw',

    [theme.breakpoints.up('medium')]: {
      height: '90%',
      marginTop: '23vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginTop: '15vw',
    },
    [theme.breakpoints.up('large')]: {
      height: '90%',
      marginTop: '6.5vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '11vw',
      width: '85.5vw',
      marginLeft: '16.3vw',
    },
  },

  cancelReservationDialogBox: {
    '& .MuiPaper-root': {
      width: '87.467vw',
      height: '35%',
      marginLeft: '4vw',

      [theme.breakpoints.up('medium')]: {
        width: '83.333vw',
        marginLeft: '4vw',
        height: '25%',
      },
      [theme.breakpoints.up('tablet')]: {
        width: '56.250vw',
        marginLeft: '3vw',
      },
      [theme.breakpoints.up('large')]: {
        width: '39.375vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        width: '35vw',
        minHeight: '16vw',
      },
    },
  },

  dialogContent: {
    maxHeight: '83vw',
  },

  dialogTitle: {
    '& .MuiTypography-h6': {
      fontFamily: 'WTGothic',
      fontSize: '4.800vw',
      fontWeight: 400,

      [theme.breakpoints.up('medium')]: {
        fontSize: '2.344vw',
      },
      [theme.breakpoints.up('tablet')]: {
        fontSize: '1.875vw',
      },
      [theme.breakpoints.up('large')]: {
        fontSize: '1.406vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        fontSize: '1.250vw',
      },
    },
  },

  dialogSubtitle: {
    fontFamily: 'WTGothic',
    fontSize: '4.800vw',
    fontWeight: 300,

    [theme.breakpoints.up('medium')]: {
      fontSize: '2.344vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.406vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.250vw',
    },
  },

  dialogButtons: {
    fontFamily: 'WTGothic',
    fontSize: '4.800vw',
    height: '12.800vw',
    width: '37.333vw',
    borderRadius: '12px',
    padding: '2vw',
    textTransform: 'capitalize',

    [theme.breakpoints.up('medium')]: {
      height: '6.250vw',
      width: '27.604vw',
      fontSize: '2.344vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875vw',
      height: '5vw',
      width: '22.083vw',
    },
    [theme.breakpoints.up('large')]: {
      height: '3.750vw',
      width: '16.563vw',
      fontSize: '1.406vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.250vw',
      height: '3.333vw',
      width: '14.722vw',
    },
  },

  confirmButton: {
    backgroundColor: theme.palette.lightOrange,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.lightOrange,
      color: theme.palette.white,
    },
  },

  cancelarButton: {
    backgroundColor: theme.palette.white,
    color: theme.palette.grey2,
    border: '1px solid #D6D6D6',
    boxSizing: 'border-box',
    borderRadius: '12px',
  },

  dialogButtonsContainer: {
    justifyContent: 'center',
    paddingBottom: '2vw',
  },

  break: {
    flexBasis: '100%',
    height: 0,

    [theme.breakpoints.up('tablet')]: {
      display: 'none',
    },
  },
}));
