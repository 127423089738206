import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mainTitleDivSlim: {
    display: 'block',
    boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
    height: '17.77vw',
    width: '100%',
    marginLeft: 0,
    marginTop: '40px',

    [theme.breakpoints.up('medium')]: {
      marginTop: '80px',
      height: '8.85vw',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '80px',
      height: '7.08vw',
    },
    [theme.breakpoints.up('large')]: {
      marginTop: '6.25vw',
      height: '5.23vw',
      paddingLeft: '16.30vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '5.55vw',
      height: '4.65vw',
    },
  },

  mainTitleDiv: {
    display: 'block',
    boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
    height: '23.73vw',
    width: '100vw',
    marginLeft: 0,
    marginTop: '30px',

    [theme.breakpoints.up('medium')]: {
      marginTop: '80px',
      height: '12.10vw',
      width: '100vw',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '80px',
      width: '100vw',
      height: '7.08vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '100vw',
      height: '5.23vw',
      marginTop: '6.25vw',
      marginLeft: 'unset',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '5.55vw',
      width: '83.68vw',
      height: '4.65vw',
      marginLeft: '16.30vw',
    },
  },

  myReservationsTitleFont: {
    fontFamily: 'WTGothic',
    fontWeight: 400,
    color: theme.palette.black,
    letterSpacing: '0.01em',
    width: '76.8vw',
    fontSize: '6.93vw',
    marginTop: '4.26vw',
    marginLeft: '4.26vw',
    marginBottom: '1.04vw',
    float: 'left',

    [theme.breakpoints.up('medium')]: {
      width: '70vw',
      fontSize: '3.90vw',
      marginTop: '2.08vw',
      marginLeft: '8.5vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '3.12vw',
      marginTop: '1.66vw',
      float: 'unset',
      width: 'unset',
    },
    [theme.breakpoints.up('large')]: {
      marginTop: '1.40vw',
      marginLeft: '10.07vw',
      fontSize: '2.34vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '2.08vw',
      marginTop: '1.11vw',
      marginLeft: '2.15vw',
    },
  },

  myReservationCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('large')]: { marginLeft: '16.5vw' },
    overflow: 'hidden',
  },

  titleFont: {
    fontFamily: 'WTGothic',
    fontWeight: 400,
    color: theme.palette.black,
    letterSpacing: '0.01em',
    width: '76.8vw',
    fontSize: '6.93vw',
    marginTop: '4.26vw',
    marginLeft: '4.26vw',
    marginBottom: '1.04vw',
    float: 'left',

    [theme.breakpoints.up('medium')]: {
      width: '70vw',
      fontSize: '3.90vw',
      marginTop: '2.08vw',
      marginLeft: '2.15vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '3.12vw',
      marginTop: '1.66vw',
      float: 'unset',
      width: 'unset',
    },
    [theme.breakpoints.up('large')]: {
      marginTop: '1.40vw',
      marginLeft: '10.07vw',
      fontSize: '2.34vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '2.08vw',
      marginTop: '1.11vw',
      marginLeft: '2.15vw',
    },
  },

  backButton: {
    backgroundColor: theme.palette.white,
    border: 0,
    outline: 'none',
    padding: '2.13vw',
    marginTop: '4.26vw',
    marginLeft: '4.26vw',
    float: 'left',

    [theme.breakpoints.up('medium')]: {
      padding: '1.04vw',
      marginTop: '2.08vw',
      marginLeft: '8.33vw',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '0.83vw',
      marginTop: '1.04vw',
      marginLeft: '6.77vw',
      float: 'unset',
    },
    [theme.breakpoints.up('large')]: {
      padding: '0.92vw',
      marginTop: '0.78vw',
      marginLeft: '10.07vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      padding: '0.55vw',
      marginTop: '0.69vw',
      marginLeft: '1.66vw',
    },
  },

  sidedTitle: {
    marginLeft: '0vw',
  },

  backIcon: {
    width: '6.4vw',
    height: '6.4vw',

    [theme.breakpoints.up('medium')]: {
      width: '3.12vw',
      height: '3.12vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '2.5vw',
      height: '2.5vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '1.87vw',
      height: '1.87vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '1.66vw',
      height: '1.66vw',
    },
  },

  navigationContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: 'fit-content',
    marginTop: 0,
    marginLeft: '4.26vw',
    height: '4.53vw',
    float: 'left',

    [theme.breakpoints.up('medium')]: {
      marginTop: 0,
      marginLeft: '13.54vw',
      height: '2.21vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginTop: '3.64vw',
      marginLeft: '1.66vw',
      height: '1.77vw',
    },
    [theme.breakpoints.up('large')]: {
      marginTop: '2.65vw',
      marginLeft: '1.25vw',
      height: '1.32vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '2.22vw',
      marginLeft: '1.66vw',
      height: '1.18vw',
    },
  },

  navigationFont: {
    fontFamily: 'WTGothic',
    margin: 0,
    fontWeight: 400,
    color: theme.palette.grey1,
    fontSize: '3.09vw',

    '&.actual': {
      fontWeight: 700,
      color: theme.palette.oceanBlue,
    },

    [theme.breakpoints.up('medium')]: {
      fontSize: '1.82vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.45vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.09vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '0.97vw',
    },
  },

  navigationIcon: {
    height: '3.73vw',
    width: '1.86vw',
    marginLeft: '0.38vw',
    marginTop: '0vw',
    marginRight: '0.38vw',

    [theme.breakpoints.up('medium')]: {
      height: '1.82vw',
      width: '0.91vw',
      marginLeft: '1.08vw',
      marginTop: '0.26vw',
      marginRight: '1.08vw',
    },
    [theme.breakpoints.up('tablet')]: {
      height: '1.45vw',
      width: '0.72vw',
      marginLeft: '0.83vw',
      marginTop: '0.20vw',
      marginRight: '0.83vw',
    },
    [theme.breakpoints.up('large')]: {
      height: '1.32vw',
      width: '0.54vw',
      marginLeft: '0.62vw',
      marginRight: '0.62vw',
      marginTop: 'unset',
    },
    [theme.breakpoints.up('extraLarge')]: {
      height: '1.18vw',
      width: '0.5vw',
      marginLeft: '0.55vw',
      marginRight: '0.55vw',
    },
  },

  notFoundContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',

    '&.fromMain': {
      marginLeft: '14vw',
    },
  },

  blockedContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    marginLeft: '0vw',
    textAlign: 'center',

    [theme.breakpoints.up('medium')]: {
      marginLeft: '0vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '0vw',
    },
    [theme.breakpoints.up('large')]: {
      marginLeft: '16vw',
    },
  },

  notFoundH1: {
    fontFamily: 'WTGothic',
    fontSeight: 700,
    fontSize: '25vw',
    margin: 0,
    color: theme.palette.oceanBlue,

    [theme.breakpoints.up('medium')]: {
      fontSize: '18vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '13vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '10vw',
    },
  },

  blockedH1: {
    fontFamily: 'WTGothic',
    fontSeight: 700,
    fontSize: '7vw',
    margin: 0,
    marginLeft: '6vw',
    marginRight: '6vw',
    color: theme.palette.oceanBlue,

    [theme.breakpoints.up('medium')]: {
      fontSize: '6vw',
      margin: 0,
      marginLeft: '0vw',
      marginRight: '0vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '4vw',
      margin: 0,
      marginLeft: '0vw',
      marginRight: '0vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '3vw',
      margin: 0,
      marginLeft: '0vw',
      marginRight: '0vw',
    },
  },

  notFoundH2: {
    fontSize: '4vw',

    [theme.breakpoints.up('medium')]: {
      fontSize: '3vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.8vw',
    },
    [theme.breakpoints.up('large')]: {},
  },

  blockedH2: {
    fontSize: '4vw',
    marginLeft: '6vw',
    marginRight: '6vw',

    [theme.breakpoints.up('medium')]: {
      fontSize: '3vw',
      marginLeft: '0vw',
      marginRight: '0vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.8vw',
      marginLeft: '0vw',
      marginRight: '0vw',
    },
    [theme.breakpoints.up('large')]: {
      marginLeft: '0vw',
      marginRight: '0vw',
    },
  },

  notFoundIcon: {
    fill: theme.palette.oceanBlue,
    height: '18vw',

    [theme.breakpoints.up('medium')]: {
      height: '13vw',
    },
    [theme.breakpoints.up('tablet')]: {
      height: '10vw',
    },
    [theme.breakpoints.up('large')]: {
      height: '7vw',
    },
  },

  notFoundButton: {
    width: '38vw',
    height: '9.5vw',
    border: 0,
    backgroundColor: theme.palette.lightOrange,
    boxShadow: '0px 4px 4px rgba(33, 35, 34, 0.2)',
    fontFamily: 'WTGothic',
    fontSeight: 300,
    color: theme.palette.white,
    outline: 'none',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    fontSize: '3.73vw',
    borderRadius: '3.33vw',

    [theme.breakpoints.up('medium')]: {
      height: '4.65vw',
      width: '21vw',
      fontSize: '1.82vw',
      borderRadius: '1.25vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '17vw',
      borderRadius: '1.25vw',
      height: '3.75vw',
      display: 'block',
      position: 'relative',
      fontSize: '1.45vw',
      float: 'left',
    },
    [theme.breakpoints.up('large')]: {
      width: '15vw',
      height: '2.8vw',
      fontSize: '15px',
      borderRadius: '0.87vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '11vw',
      height: '2.5vw',
      fontSize: '1vw',
    },
  },
}));
