import React, { useState, useEffect } from 'react';
import { useStyles } from './ActualReservation.styles';
import { gql, useMutation } from '@apollo/client';
import { IReservation, ReservationStatus } from '../../interfaces/IReservation';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { getLabel } from '../../helpers/labels';
import DialogBox from '../../components/DialogBox/DialogBox';
dayjs.extend(utc);
dayjs.extend(timezone);

const timeZone = dayjs.tz.guess();

interface IProps {
  data: IReservation | undefined;
  email: string;
  date: Date;

  dialogMessage: (message: string) => void;

  showMessage: (valor: boolean) => void;

  success: (valor: boolean) => void;
}

const ActualReservation = (props: IProps) => {
  const classes = useStyles();
  const [openCancelReservationDialog, setOpenCancelReservationDialog] =
    useState(false);
  const [actualReservationId, setActualReservationId] = useState(0);
  const [actualReservation, setActualReservation] = useState<IReservation>();
  const [openCheckInReservationDialog, setOpenCheckInReservationDialog] =
    useState(false);
  const [openCheckOutReservationDialog, setOpenCheckOutReservationDialog] =
    useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [DBIsSuccess] = useState(false);
  const [dialogMessage] = useState('');

  const deleteReservationQuery = gql`
    mutation ($reservationID: Float!) {
      result: deleteWorkstationReservation(reservationID: $reservationID)
    }
  `;

  const checkInReservationQuery = gql`
    mutation ($id: Float!) {
      result: checkInReservation(id: $id)
    }
  `;

  const checkOutReservationQuery = gql`
    mutation ($id: Float!) {
      result: checkOutReservation(id: $id)
    }
  `;

  const disableWorkstationReservationQuery = gql`
    mutation ($id: Float!) {
      result: disableWorkstationReservation(id: $id)
    }
  `;

  const [checkInReservation, checkInReservationResult] = useMutation(
    checkInReservationQuery,
    {
      variables: {
        id: actualReservation?.id,
      },
    }
  );

  const [checkOutReservation, checkOutReservationResult] = useMutation(
    checkOutReservationQuery,
    {
      variables: {
        id: actualReservation?.id,
      },
    }
  );

  const [deleteReservation, deleteReservationResult] = useMutation(
    deleteReservationQuery,
    {
      variables: { reservationID: actualReservationId },
    }
  );

  const [disableWorkstationReservation, disableWorkstationReservationResult] =
    useMutation(disableWorkstationReservationQuery, {
      variables: {
        id: actualReservation?.id,
      },
    });

  const handleCloseCheckIn = () => {
    setOpenCheckInReservationDialog(false);
  };

  const handleCheckIn = () => {
    checkInReservation();
    setOpenCheckInReservationDialog(false);
  };

  const handleCloseCheckOut = () => {
    setOpenCheckOutReservationDialog(false);
  };

  const handleCheckOut = () => {
    checkOutReservation();
    setOpenCheckOutReservationDialog(false);
  };

  const handleCloseCancelReservation = () => {
    setOpenCancelReservationDialog(false);
  };

  const handleDeleteReservation = () => {
    deleteReservation();
    setOpenCancelReservationDialog(false);
    setActualReservation(undefined);
  };

  useEffect(() => {
    if (checkInReservationResult.called) {
      props.success(true);
      props.dialogMessage(getLabel('checkInMessageSuccess'));
      setIsChecked(true);
      props.showMessage(true);
    }
  }, [checkInReservationResult.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (checkOutReservationResult.data) {
      if (checkOutReservationResult.data.result) {
        disableWorkstationReservation();
      } else {
        props.success(false);
        props.dialogMessage(getLabel('checkOutMessageFailed'));
        props.showMessage(true);
      }
    }
  }, [checkOutReservationResult.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (disableWorkstationReservationResult.data) {
      if (disableWorkstationReservationResult.data.result) {
        props.success(true);
        props.dialogMessage(getLabel('checkOutMessageSuccess'));
        props.showMessage(true);
        setActualReservation(undefined);
      } else {
        props.success(false);
        props.dialogMessage(getLabel('checkOutMessageFailed'));
        props.showMessage(true);
      }
    }
  }, [disableWorkstationReservationResult.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.data) {
      setActualReservation(props.data);
      if (props.data.status === ReservationStatus.checked) {
        setIsChecked(true);
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (deleteReservationResult.data) {
      setActualReservationId(0);
      props.showMessage(true);
      if (deleteReservationResult.data.result) {
        props.success(true);
        props.dialogMessage(getLabel('cancelReservationMessageSuccess'));
      } else {
        props.success(false);
        props.dialogMessage(getLabel('cancelReservationMessageFailed'));
      }
    }
  }, [deleteReservationResult.data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.container}>
      <DialogBox
        message={dialogMessage}
        isSuccess={DBIsSuccess}
        show={showErrorMessage}
        hide={setShowErrorMessage}
      ></DialogBox>
      <Dialog
        open={openCheckInReservationDialog}
        onClose={handleCloseCheckIn}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        className={classes.cancelReservationDialogBox}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          classes={{ root: classes.dialogTitle }}
        >
          Check-in
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText classes={{ root: classes.dialogSubtitle }}>
            ¿Estás seguro de que deseas realizar el check-in para la
            reservación?
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogButtonsContainerCancel }}>
          <Button
            className={`${classes.dialogButtons} ${classes.cancelButton}`}
            onClick={handleCloseCheckIn}
            color="primary"
          >
            No
          </Button>
          <Button
            className={`${classes.dialogButtons} ${classes.confirmButton}`}
            onClick={handleCheckIn}
            color="primary"
          >
            Sí
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCheckOutReservationDialog}
        onClose={handleCloseCheckOut}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        className={classes.cancelReservationDialogBox}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          classes={{ root: classes.dialogTitle }}
        >
          Check-out
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText classes={{ root: classes.dialogSubtitle }}>
            ¿Estás seguro de que deseas realizar el check-out para la
            reservación?
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogButtonsContainerCancel }}>
          <Button
            className={`${classes.dialogButtons} ${classes.cancelButton}`}
            onClick={handleCloseCheckOut}
            color="primary"
          >
            No
          </Button>
          <Button
            className={`${classes.dialogButtons} ${classes.confirmButton}`}
            onClick={handleCheckOut}
            color="primary"
          >
            Sí
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCancelReservationDialog}
        onClose={handleCloseCancelReservation}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        className={classes.cancelReservationDialogBox}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          classes={{ root: classes.dialogTitle }}
        >
          Cancelar reservación
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText classes={{ root: classes.dialogSubtitle }}>
            ¿Estás seguro de que deseas cancelar la reservación?
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogButtonsContainer }}>
          <Button
            className={`${classes.dialogButtons} ${classes.cancelarButton}`}
            onClick={handleCloseCancelReservation}
            color="primary"
          >
            No
          </Button>
          <Button
            className={`${classes.dialogButtons} ${classes.confirmButton}`}
            onClick={handleDeleteReservation}
            color="primary"
          >
            Sí
          </Button>
        </DialogActions>
      </Dialog>
      <h1 className={classes.containerTitle}>Reservación de hoy</h1>
      <div className={classes.subtitlesDiv}>
        <div className={`${classes.subtitleFont} first`}>Estación</div>
        <div className={`${classes.subtitleFont} second`}>Fecha</div>
        <div className={`${classes.subtitleFont} third`}>Piso</div>
      </div>
      {actualReservation ? (
        <div key={'actualReservation1'} className={classes.reservationInfoDiv}>
          <div className={classes.firstDiv}>
            <div className={classes.cornerDiv}></div>
          </div>
          <div className={classes.secondDiv}>
            <div className={`${classes.reservationInfoFont} first`}>
              Estacion{' '}
              {(actualReservation?.workstationreservation.tableId || '') +
                actualReservation?.workstationreservation.workstationId}
            </div>
            <div className={`${classes.reservationInfoFont}`}>
              {dayjs.tz(actualReservation?.date, timeZone).format('DD/MM/YYYY')}
            </div>
            <div className={`${classes.reservationInfoFont}`}>
              {actualReservation?.workstationreservation.floorId === 2
                ? 'Segundo Piso'
                : 'Tercer Piso'}
            </div>
            <div className={classes.break}></div>
            {isChecked ? (
              <div className={classes.checkButtonDiv}>
                <button
                  className={`${classes.checkButton}`}
                  onClick={() => {
                    setOpenCheckOutReservationDialog(true);
                  }}
                >
                  Check-out
                </button>
              </div>
            ) : (
              <>
                <div className={classes.checkButtonDiv}>
                  <button
                    className={`${classes.checkButton}`}
                    onClick={() => {
                      setOpenCheckInReservationDialog(true);
                    }}
                  >
                    Check-in
                  </button>
                </div>
                <div
                  className={`${classes.cancelButton}`}
                  onClick={() => {
                    setOpenCancelReservationDialog(true);
                    setActualReservationId(actualReservation?.id || 0);
                  }}
                >
                  Cancelar
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ActualReservation;
