import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { useStyles } from './MapPageSkeleton.styles';

const MapPageSkeleton = () => {
    const classes = useStyles();
    return(
        <>
            <div >
                <Skeleton className={classes.mainTitleDiv} variant="text"  />

                <div className={classes.containerDiv}>
                    <Skeleton variant="text" className={classes.MapHeader}/>
                    <Skeleton variant="rect" className={classes.mapInfoDiv}/>
                    <Skeleton variant="rect" className={classes.MapHeaderButton}/>
                    <Skeleton variant="rect" className={classes.mapFilterDiv}/>
                    <Skeleton variant="rect" className={classes.mapDiv}/>
                    <Skeleton variant="rect" className={classes.stationInfoDiv}/>
                </div>
                
            </div>
        </>
    );
};

export default MapPageSkeleton;