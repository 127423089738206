import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import './Calendar.css';
import { ReactComponent as Calendar } from '../../assets/images/bold_calendar.svg';

registerLocale('es', es);
const CalendarComponent = (props) => {
  //Filters and blocks the day if it is saturday or sunday
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  return (
    <>
      <DatePicker
        className={`input-text date-cal ${props.small ? 'small' : ''}`}
        calendarClassName={`${props.isAdmin ? '' : 'notAdmin'}`}
        onFocus={(e) => e.target.blur()}
        selected={props.date}
        onChange={(date) => props.handleClick(date)}
        popperPlacement="bottom"
        locale="es"
        popperModifiers={{
          flip: {
            behavior: ['bottom'],
          },
          preventOverflow: {
            enabled: true,
          },
          hide: {
            enabled: false,
          },
        }}
        minDate={props.today ? props.today : null}
        filterDate={isWeekday}
      />
      <Calendar
        className={`input-icon cal-icon ${props.small ? 'small' : ''}`}
      />
    </>
  );
};

export default CalendarComponent;
