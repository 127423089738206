import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  fields: {
    marginBottom: '1rem',
    flexDirection: 'row',
  },

  parkingLabel: {
    fontSize: '1rem',
    fontFamily: 'WTGothic',
    fontWeight: 300,
    marginBottom: '0rem'
  },

  parkingFormControl: {
    '& .MuiFormControlLabel-label': {
      fontSize: '1rem',
      fontFamily: 'WTGothic',
      fontWeight: 300
    }
  },

  parkingCheckbox: {
    '&.Mui-checked': {
      color: '#ff760c'
    }
  },

  parkingPolicy: {
    fontFamily: 'WTGothic',
    fontWeight: 300,
    '& a': {
      color: theme.palette.black
    }
  },

  dialogBox: {
    '& .MuiPaper-root': {
      width: '90.467vw',
      marginLeft: '1.6rem',
      height: '80vh',

      [theme.breakpoints.up('medium')]: {
        width: '74vw',
        marginLeft: '0rem',
        height: 'unset',
      },
      [theme.breakpoints.up('tablet')]: {
        width: '60.250vw',
      },
      [theme.breakpoints.up('large')]: {
        width: '44.375vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        width: '40vw',
      },
    },
  },

  cancelReservationDialogBox: {
    '& .MuiPaper-root': {
      width: '87.467vw',
      marginLeft: '4vw',

      [theme.breakpoints.up('medium')]: {
        width: '83.333vw',
        marginLeft: '4vw',
      },
      [theme.breakpoints.up('tablet')]: {
        width: '56.250vw',
        marginLeft: '3vw',
      },
      [theme.breakpoints.up('large')]: {
        width: '39.375vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        width: '35vw',
      },
    },
  },

  dialogContent: {
    margin: '0 auto',
  },

  backDrop: {
    backdropFilter: 'blur(2px)',
    backgroundColor: theme.palette.whiteRGB,
    width: '100vw',
    height: '100%',
    marginTop: '32vw',

    [theme.breakpoints.up('medium')]: {
      height: '90%',
      marginTop: '23vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginTop: '15vw',
    },
    [theme.breakpoints.up('large')]: {
      height: '90%',
      marginTop: '6.5vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginTop: '11vw',
      width: '85.5vw',
      marginLeft: '16.3vw',
    },
  },

  dialogTitle: {
    '& .MuiTypography-h6': {
      fontFamily: 'WTGothic',
      fontSize: '4.800vw',
      fontWeight: 400,

      [theme.breakpoints.up('medium')]: {
        fontSize: '2.344vw',
      },
      [theme.breakpoints.up('tablet')]: {
        fontSize: '1.875vw',
      },
      [theme.breakpoints.up('large')]: {
        fontSize: '1.406vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        fontSize: '1.250vw',
      },
    },
  },

  optionsTabContainer: {
    width: '45.467vw',
    //marginLeft: '4vw',

    [theme.breakpoints.up('medium')]: {
      width: '40.333vw',
      //marginLeft: '4vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '12.250vw',
      // marginLeft: '3vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '15.375vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '10vw',
    },
  },

  titleRecomendation: {
    fontFamily: 'WTGothic',
    fontSize: '4.800vw',
    fontWeight: 300,
    margin: '0px',
    marginBottom: '15px',

    [theme.breakpoints.up('medium')]: {
      fontSize: '2.344vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.406vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.250vw',
    },
  },
  containerRecomendation: {
    margin: '0px 30px',
    textAlign: 'justify',
  },

  dialogSubtitle: {
    fontFamily: 'WTGothic',
    fontSize: '4.800vw',
    fontWeight: 300,

    [theme.breakpoints.up('medium')]: {
      fontSize: '2.344vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.406vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.250vw',
    },
  },

  dialogStationInfo: {
    fontFamily: 'WTGothic',
    fontSize: '4.800vw',
    fontWeight: 300,
    color: theme.palette.black,
    marginTop: '-1vw',

    [theme.breakpoints.up('medium')]: {
      fontSize: '2.344vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.406vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.250vw',
    },
  },

  dialogButtons: {
    fontFamily: 'WTGothic',
    fontSize: '4.800vw',
    height: '12.800vw',
    width: '37.333vw',
    borderRadius: '12px',
    padding: '2vw',
    textTransform: 'capitalize',

    [theme.breakpoints.up('medium')]: {
      height: '6.250vw',
      width: '27.604vw',
      fontSize: '2.344vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875vw',
      height: '5vw',
      width: '22.083vw',
    },
    [theme.breakpoints.up('large')]: {
      height: '3.750vw',
      width: '16.563vw',
      fontSize: '1.406vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '1.250vw',
      height: '3.333vw',
      width: '14.722vw',
    },
  },

  confirmButton: {
    backgroundColor: theme.palette.lightOrange,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.lightOrange,
      color: theme.palette.white,
    },
    '&.disabled': {
      opacity: 0.4,
    },
  },

  cancelButton: {
    backgroundColor: theme.palette.white,
    color: theme.palette.grey2,
    border: '1px solid #D6D6D6',
    boxSizing: 'border-box',
    borderRadius: '12px',
  },

  dialogButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: '2vw',
  },

  dialogButtonsContainerCancel: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '2vw',
  },

  labelText: {
    textAlign: 'left',
    color: 'red',
    fontSize: '0.8rem',
  },

  buttonsGap: {
    display: 'flex',
    columnGap: '0.5rem',
  },

  mapDiv: {
    position: 'relative',
    display: 'inline-block',
    height: '360px',
    border: "1px solid '#D6D6D6'",
    boxSizing: 'border-box',
    width: '91.46vw',
    marginTop: '4.26vw',
    marginLeft: '4.26vw',
    marginBottom: '3.2vw',
    boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
    borderRadius: '3.2vw',
    overflow: 'hidden',

    [theme.breakpoints.up('medium')]: {
      width: '83.30vw',
      marginTop: '2.08vw',
      marginLeft: '8.33vw',
      marginBottom: '1.56vw',
      boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
      borderRadius: '1.56vw',
      overflow: 'unset',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '86.45vw',
      marginTop: '1.66vw',
      marginLeft: '6.77vw',
      marginBottom: '1.56vw',
      boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
      borderRadius: '1.25vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '59.37vw',
      marginTop: '1.25vw',
      marginLeft: '10.15vw',
      marginBottom: '1.56vw',
      boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
      borderRadius: '0.93vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '60.55vw',
      marginTop: '1.11vw',
      marginLeft: '18.61vw',
      marginBottom: '1.56vw',
      boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
      borderRadius: '0.83vw',
    },

    '&.isAdmin': {
      marginTop: '3.53vw',
      marginLeft: 0,
      height: '315px',

      [theme.breakpoints.up('medium')]: {
        width: '63vw',
        overflow: 'hidden',
        marginTop: '1.53vw',
      },
      [theme.breakpoints.up('tablet')]: {
        marginTop: '2vw',
        marginLeft: 0,
        width: '64.5vw',
        height: '330px',
      },
      [theme.breakpoints.up('large')]: {},
      [theme.breakpoints.up('extraLarge')]: {
        marginTop: '2vw',
        marginLeft: 0,
        width: '57.5vw',
        height: '323px',
      },
    },
  },

  mapArrow: {
    display: 'unset',
    position: 'absolute',
    padding: '12px',
    top: '220px',
    left: '0px',
    backgroundColor: 'transparent',
    border: 0,
    outline: 'none',

    '&.right': {
      left: 'unset',
      right: '0px',
    },

    [theme.breakpoints.up('medium')]: {
      display: 'none',
    },
    [theme.breakpoints.up('tablet')]: {},
    [theme.breakpoints.up('large')]: {},
    [theme.breakpoints.up('extraLarge')]: {},

    '&.isAdmin': {
      '&.right': {
        left: 'unset',
        right: '0px',
      },

      [theme.breakpoints.up('medium')]: {
        display: 'unset',
      },
      [theme.breakpoints.up('tablet')]: {
        display: 'unset',
      },
      [theme.breakpoints.up('large')]: {
        display: 'none',
      },
    },
  },

  mapArrowIcon: {
    display: 'unset',
    width: '31px',
    height: '31px',
    [theme.breakpoints.up('medium')]: {
      display: 'none',
    },
    [theme.breakpoints.up('tablet')]: {},
    [theme.breakpoints.up('large')]: {},
    [theme.breakpoints.up('extraLarge')]: {},

    '&.isAdmin': {
      [theme.breakpoints.up('medium')]: {
        display: 'unset',
        marginTop: '6vw',
      },
      [theme.breakpoints.up('tablet')]: {
        display: 'none',
      },
    },
  },

  mapPrint: {
    marginLeft: '13%',
    position: 'relative',
    marginTop: '17px',
    width: '608px',
    height: '251px',

    [theme.breakpoints.up('medium')]: {
      marginLeft: 'calc(50% - 304px)',
    },
    [theme.breakpoints.up('tablet')]: {},
    [theme.breakpoints.up('large')]: {},
    [theme.breakpoints.up('extraLarge')]: {},

    '&.isAdmin': {
      [theme.breakpoints.up('medium')]: {
        marginLeft: 'calc(50% - 220px)',
      },
      [theme.breakpoints.up('tablet')]: {
        marginLeft: 'calc(50% - 304px)',
      },
      [theme.breakpoints.up('large')]: {},
      [theme.breakpoints.up('extraLarge')]: {
        marginBottom: '200vw',
      },
    },

    '&.thirdFloor': {
      [theme.breakpoints.up('medium')]: {
        marginLeft: 'calc(50% - 166px)',
      },
      [theme.breakpoints.up('tablet')]: {
        marginLeft: 'calc(50% - 176px)',
      },
      [theme.breakpoints.up('large')]: {},
      [theme.breakpoints.up('extraLarge')]: {
        marginBottom: '200vw',
      },
    },
  },

  mapPrintNext: {
    position: 'relative',
    marginTop: '17px',
    width: '608px',
    height: '251px',
    marginLeft: 'unset',
    marginRight: '3%',
    float: 'right',

    [theme.breakpoints.up('medium')]: {
      marginLeft: 'calc(50% - 304px)',
      float: 'none',
      marginRight: 'unset',
    },
    [theme.breakpoints.up('tablet')]: {},
    [theme.breakpoints.up('large')]: {},
    [theme.breakpoints.up('extraLarge')]: {},

    '&.isAdmin': {
      [theme.breakpoints.up('medium')]: {
        marginLeft: 'calc(50% - 390px)',
        float: 'none',
        marginRight: 'unset',
      },
      [theme.breakpoints.up('tablet')]: {
        marginLeft: 'calc(50% - 304px)',
      },
      [theme.breakpoints.up('large')]: {},
      [theme.breakpoints.up('extraLarge')]: {
        marginBottom: '200vw',
      },
    },
  },

  map: {
    position: 'absolute',
    top: 0,
    left: 0,
  },

  stationButton: {
    width: '24px',
    height: '24px',
    border: 0,
    padding: 0,
    margin: 0,
    backgroundColor: 'transparent',
  },

  stationUnavailableOnmap: {
    width: '24px',
    height: '24px',
    fill: theme.palette.white,
    color: theme.palette.lightGrey,
    stroke: theme.palette.lightGrey,
    padding: 0,
    margin: 0,

    '&.left': {
      transform: 'rotate(-90deg)',
    },

    '&.right': {
      transform: 'rotate(90deg)',
    },

    '&.bottom': {
      transform: 'rotate(180deg)',
    },
  },

  stationOnmap: {
    width: '24px',
    height: '24px',
    fill: theme.palette.lightGrey,
    color: theme.palette.lightGrey,
    stroke: theme.palette.lightGrey,
    padding: 0,
    margin: 0,

    '&.selected': {
      fill: theme.palette.turquoise,
      color: theme.palette.turquoise,
      stroke: theme.palette.turquoise,
    },

    '&.occupied': {
      fill: theme.palette.tomato,
      color: theme.palette.tomato,
      stroke: theme.palette.tomato,
    },

    '&.actual': {
      fill: theme.palette.oceanBlue,
      color: theme.palette.oceanBlue,
      stroke: theme.palette.oceanBlue,
    },

    '&.left': {
      transform: 'rotate(-90deg)',
    },

    '&.right': {
      transform: 'rotate(90deg)',
    },

    '&.bottom': {
      transform: 'rotate(180deg)',
    },

    '&.isAdmin': {
      fill: theme.palette.tomato,
      color: theme.palette.tomato,
      stroke: theme.palette.tomato,
    },
  },

  stationText: {
    position: 'absolute',
    color: 'red',
    top: 0,
    fontSize: 5,
  },

  deskTag: {
    display: 'flex',
    margin: 0,
    justifyContent: 'center',
    width: '48px',
    height: '12px',
    fontFamily: 'WTGothic',
    fontSize: '9px',
    fontWeight: 400,
    lineHeight: '10px',
  },

  longDesk: {
    position: 'absolute',
    width: '48px',
    height: '180px',
    top: '56px',

    '&.desk1': {
      left: '43px',
    },

    '&.desk2': {
      left: '154px',
    },
    '&.desk3': {
      left: '217px',
    },
    '&.desk4': {
      left: '315px',
    },
    '&.desk5': {
      right: '181px',
    },
    '&.desk6': {
      right: '77px',
    },
    '&.desk7': {
      right: '14px',
    },
  },

  longDeskThirdFloor: {
    position: 'absolute',
    width: '48px',
    height: '180px',
    top: '68px',

    '&.desk1': {
      left: '21px',
    },
    '&.desk2': {
      left: '86px',
    },
    '&.desk3': {
      left: '186px',
    },
    '&.desk4': {
      left: '254px',
    },
  },

  stationInfoDiv: {
    right: '10.16vw',
    border: '1px solid #D6D6D6',
    boxSizing: 'border-box',
    position: 'fixed',
    top: 'unset',
    width: '100vw',
    boxShadow: 'none',
    borderRadius: 0,
    backgroundColor: theme.palette.white,
    paddingBottom: '0vw',
    height: '25.4vw',
    bottom: 0,
    left: 0,

    '&.active': {
      top: 'unset',

      [theme.breakpoints.up('medium')]: {},
      [theme.breakpoints.up('tablet')]: {},
      [theme.breakpoints.up('large')]: {
        top: '27.34vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        top: '23.72vw',
      },
    },

    [theme.breakpoints.up('medium')]: {
      height: '13.02vw',
    },
    [theme.breakpoints.up('tablet')]: {
      height: '10.532vw',
    },
    [theme.breakpoints.up('large')]: {
      position: 'absolute',
      top: '18.35vw',
      right: '10.16vw',
      width: '18.51vw',

      border: '1px solid #D6D6D6',
      boxSizing: 'border-box',
      boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
      borderRadius: '0.93vw',
      paddingBottom: '0.8vw',
      height: 'fit-content',
      bottom: 'unset',
      left: 'unset',
    },
    [theme.breakpoints.up('extraLarge')]: {
      top: '15.70vw',
      right: '2.15vw',
      width: '16.45vw',
      boxShadow: '0px 2px 4px rgba(33, 35, 34, 0.2)',
      borderRadius: '0.83vw',
    },
  },

  stationInfoSecondaryFont: {
    fontFamily: 'WTGothic',
    fontWeight: 300,
    height: '9.11vw',
    lineHeight: '1.32vw',
    display: 'none',
    fontSize: '1.82vw',
    width: '27.73vw',
    marginLeft: '2.08vw',
    marginTop: '2.08vw',
    float: 'left',

    [theme.breakpoints.up('medium')]: {
      fontSize: '1.82vw',
      width: '27.73vw',
      height: '9.11vw',
      marginLeft: '2.08vw',
      marginTop: '2.08vw',
      display: 'block',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.45vw',
      width: '22.18vw',
      height: '7.18vw',
      marginLeft: '1.66vw',
      marginTop: '1.66vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.09vw',
      marginLeft: '0.78vw',
      marginTop: 'unset',
      height: '1.32vw',
      lineHeight: '1.32vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '0.97vw',
      height: '1.18vw',
      lineHeight: '1.18vw',
      marginLeft: '0.69vw',
    },
  },

  stationInfoFont: {
    fontFamily: 'WTGothic',
    fontWeight: 300,
    lineHeight: '4vw',
    fontSize: '3.73vw',
    width: '20vw',
    height: '13.6vw',
    marginLeft: '4.26vw',
    marginTop: '4.26vw',
    float: 'left',

    [theme.breakpoints.up('medium')]: {
      fontSize: '2.34vw',
      width: '20vw',
      height: '9.11vw',
      marginLeft: '8.46vw',
      marginTop: '2.08vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '2.70vw',
      width: '16vw',
      height: '7.18vw',
      marginLeft: '16.66vw',
      marginTop: '1.66vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '1.09vw',
      marginLeft: '0.78vw',
      height: '1.32vw',
      width: 'unset',
      float: 'none',
    },
    [theme.breakpoints.up('extraLarge')]: {
      fontSize: '0.97vw',
      height: '1.18vw',
      lineHeight: '1.18vw',
      marginLeft: '0.69vw',
    },
  },

  stationCodeInfoFont: {
    fontFamily: 'WTGothic',
    margin: 0,
    padding: 0,
    fontSize: '6.933vw',
    fontWeight: 400,
    lineHeight: '6vw',
    marginTop: '13vw',
    marginLeft: '-20vw',
    float: 'left',

    [theme.breakpoints.up('medium')]: {
      fontSize: '2.5vw',
      lineHeight: '3vw',
      marginTop: '2.65vw',
      marginLeft: '-6vw',
    },
    [theme.breakpoints.up('tablet')]: {
      marginTop: '2.3vw',
      marginLeft: '0vw',
    },
    [theme.breakpoints.up('large')]: {
      marginLeft: '0.78vw',
      float: 'unset',
      marginTop: '0vw',
      paddingBottom: '0.8vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      marginLeft: '0.69vw',
    },
  },

  stationInfoButton: {
    fontFamily: 'WTGothic',
    background: '#FF760C',
    color: theme.palette.white,
    outline: 'none',
    border: 'none',
    width: '67.2vw',
    height: '12.8vw',
    fontSize: '4.8vw',
    lineHeight: '12.8vw',
    marginLeft: '4.26vw',
    borderRadius: ' 3.2vw',
    marginTop: '6.26vw',
    float: 'left',

    '&.unavailable': {
      opacity: 0.4,
    },

    [theme.breakpoints.up('medium')]: {
      width: '27.60vw',
      height: '6.25vw',
      fontSize: '2.34vw',
      lineHeight: '6.25vw',
      marginLeft: '8vw',
      borderRadius: '1.56vw',
      marginTop: '3.38vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '22.08vw',
      height: '5vw',
      fontSize: '1.875vw',
      lineHeight: '5vw',
      marginLeft: '7vw',
      borderRadius: ' 1.25vw',
      marginTop: '3vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '16.95vw',
      height: '3.75vw',
      fontSize: '1.40vw',
      lineHeight: '3.75vw',
      marginLeft: '0.78vw',
      borderRadius: '0.93vw',
      float: 'unset',
      marginTop: '1vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '15.06vw',
      height: '3.33vw',
      fontSize: '1.25vw',
      lineHeight: '3.33vw',
      marginLeft: '0.69vw',
      borderRadius: '0.83vw',
    },
  },

  techFeaturesBox: {
    margin: 0,
    display: 'none',
    flexFlow: 'wrap',
    maxWidth: '30vw',
    marginLeft: '-27.3vw',
    marginTop: '3.8vw',
    padding: '0.5vw',
    paddingBottom: '1vw',
    float: 'left',

    '&.dialog': {
      display: 'flex',
      maxWidth: '90%',
      paddingLeft: 0,
      marginLeft: 0,
      marginTop: 0,
      paddingBottom: '0vw',
      padding: '0vw',

      [theme.breakpoints.up('medium')]: {
        maxWidth: '60%',
      },
      [theme.breakpoints.up('tablet')]: {
        maxWidth: '70%',
      },
      [theme.breakpoints.up('large')]: {
        maxWidth: '55%',
      },
      [theme.breakpoints.up('extraLarge')]: {},
    },

    [theme.breakpoints.up('medium')]: {
      display: 'flex',
    },
    [theme.breakpoints.up('tablet')]: {
      maxWidth: '25vw',
      marginLeft: '-22vw',
      marginTop: '3vw',
    },
    [theme.breakpoints.up('large')]: {
      maxWidth: '100%',
      marginTop: '-0.8vw',
      float: 'unset',
      marginLeft: 'unset',
    },
    [theme.breakpoints.up('extraLarge')]: {},
  },

  techFeature: {
    color: theme.palette.oceanBlue,
    fontFamily: 'WTGothic',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.9vw',
    border: '1px solid #0A47ED',
    boxSizing: 'border-box',
    borderRadius: '7.595vw',
    alignItems: 'center',
    padding: '3px 12px',
    marginLeft: '0.2vw',
    marginTop: '0.2vw',

    '&.dialog': {
      fontSize: '3.200vw',

      [theme.breakpoints.up('medium')]: {
        fontSize: '1.563vw',
      },
      [theme.breakpoints.up('tablet')]: {
        fontSize: '1.250vw',
      },
      [theme.breakpoints.up('large')]: {
        fontSize: '0.938vw',
      },
      [theme.breakpoints.up('extraLarge')]: {
        fontSize: '0.833vw',
      },
    },

    '&:hover': {
      backgroundColor: theme.palette.oceanBlue,
      color: theme.palette.white,
    },

    [theme.breakpoints.up('medium')]: {
      fontSize: '1.563vw',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.250vw',
    },
    [theme.breakpoints.up('large')]: {
      fontSize: '0.938vw',
    },
    [theme.breakpoints.up('extraLarge')]: {},
  },

  reservationDetailsDiv: {
    width: '77vw',

    [theme.breakpoints.up('medium')]: {
      width: '68vw',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '55vw',
    },
    [theme.breakpoints.up('large')]: {
      width: '40vw',
    },
    [theme.breakpoints.up('extraLarge')]: {
      width: '36vw',
    },
  },
}));
