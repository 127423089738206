import React, { useState } from "react";
import { useStyles } from './SetOccupation.styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import addButton from '../../assets/images/add_icon.svg';
import removeButton from '../../assets/images/remove_icon.svg';

interface IProps {
    percentageAllowed:number;
    originalPercentageAllowed: number;
    changePercentageAllowed: (value: number) => void;
    updateFloor: () => void;
  }

const SetOccupationComponent = (props: IProps) => {
    const classes = useStyles();
    const [inputValueChanged, setInputValueChanged] = useState(false);

    const clearValue = (e: any) => {
        props.changePercentageAllowed(0);
      }

    const inputChangeHandler = (e: any) => {
        setInputValueChanged(true);
        let value = e.target.value.replace(/[^0-9]/g, '');
        value = parseInt(value);
        if(!isNaN(value)){
            if(value > 100 || value < 0){
                props.changePercentageAllowed(props.originalPercentageAllowed);
            }else{
                props.changePercentageAllowed(value);
            }
        }else{
            props.changePercentageAllowed(0);
        }
      }

    const add = () => {
        setInputValueChanged(true);
        if(props.percentageAllowed < 100){
            const value = props.percentageAllowed + 5;
            if(value > 100){
                props.changePercentageAllowed(100);
            }else{
                props.changePercentageAllowed(value);
            }  
        }
    }

    const subtract = () => {
        setInputValueChanged(true);
        if(props.percentageAllowed > 0){
            const value = props.percentageAllowed - 5;
            if(value < 0){
                props.changePercentageAllowed(0);
            }else{
                props.changePercentageAllowed(value);
            }  
        }
    }

    const cancelChange = () => {
        props.changePercentageAllowed(props.originalPercentageAllowed);
        setInputValueChanged(false);
    }

    const saveData = () => {
        setInputValueChanged(false);
        props.updateFloor();
    }

    return (
        <>
            <div className={classes.container}>
                <div className={classes.subtitleFont}>Límite máximo de ocupación</div>
                <div className={`${classes.infoFont}`}>
                    Establece el porcentaje del aforo diario de personas permitidas en las instalaciones
                </div>
                <div className={classes.inputContainer}>
                    <div>
                        <img onClick={subtract} className={classes.addRemoveButton} src={removeButton} alt="remove"></img>
                    </div>
                    <OutlinedInput placeholder={'%'} classes={{root: classes.inputDiv}} inputProps={{style: { textAlign: 'center' }}} value={props.percentageAllowed} onChange={inputChangeHandler} onClick={clearValue}/>
                    <div>
                        <img onClick={add} className={classes.addRemoveButton} src={addButton}  alt="add"></img>
                    </div>
                    <div className={`${classes.infoFont} secondary`}>
                        Porcentaje del aforo permitido
                    </div>
                </div>
                <div className={classes.inputButtonsContainer}>
                    <div className={`${classes.inputButton}`} onClick={cancelChange}> Cancelar </div>
                    <div className={`${classes.inputButton} ${inputValueChanged? '': 'unavailable'} save`} onClick={inputValueChanged? saveData: undefined}> Guardar </div>
                </div>
            </div>
        </>
    );
};

export default SetOccupationComponent;